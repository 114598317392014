import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import PyntDataGrid from '../Common/PyntDataGrid';

const columns: GridColDef[] = [
  {
    field: 'missingHeaders',
    headerName: 'Missing Headers',
    width: 280,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'serverHeaderInformationDisclosure',
    headerName: 'Server Header Information Disclosure',
    width: 280,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'headersInformationFisclosure',
    headerName: 'Headers Information Disclosure',
    width: 260,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'enumerableUserId',
    headerName: 'Enumerable User ID',
    width: 260,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'enumerableResourcesId',
    headerName: 'Enumerable Resources ID',
    width: 260,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
];

export default function ConfigurationInfoGrid() {
  const apiCatalog = [] as any;
  const isLoadingAPICatalog = false;

  return (
    <PyntDataGrid
      autoHeight
      sx={{ width: '100%' }}
      rows={[
        {
          id: '123',
          missingHeaders: 'Cache-control header missing',
          serverHeaderInformationDisclosure: 'Server:apache',
          headersInformationFisclosure: 'x-powered-by',
          enumerableUserId: 'userId',
          enumerableResourcesId: '-',
        },
      ]}
      loading={!apiCatalog && isLoadingAPICatalog}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      hideFooterPagination
      initialState={{
        sorting: { sortModel: [{ field: 'timestamp', sort: 'desc' }] },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
