import { Components, Theme } from '@mui/material';

export default function themedMuiFormLabel(
  theme: Theme,
): Pick<Components<Theme>, 'MuiFormLabel'> {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: '0.8em',
        },
      },
    },
  };
}
