import { Button, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { BRAND_DARK_BLUE } from '../../../constants/colors';

const CHIP: SxProps = {
  bgcolor: 'white',
  borderRadius: '6px',
  border: '1px solid #F5F8FD',
  height: 36,
  paddingInline: 1,
};

const SELECTED_CHIP: SxProps = {
  ...CHIP,
  bgcolor: '#EFF5FF',
};

const TEXT: SxProps = {
  fontSize: 14,
  fontWeight: 500,
  color: BRAND_DARK_BLUE,
  letterSpacing: '0.2px',
};

const BUTTON: SxProps = {
  p: 0,
};

interface Props {
  title: string;
  icon: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  comingSoon?: boolean;
  onClick?: () => void;
}

export default function IntegrationOptionChip({
  title,
  icon,
  selected = false,
  disabled,
  comingSoon,
  onClick,
}: Props) {
  const button = (
    <Button sx={BUTTON} onClick={onClick} color="secondary" disabled={disabled}>
      <Stack
        sx={selected ? SELECTED_CHIP : CHIP}
        alignItems={'center'}
        justifyContent={'center'}
        direction={'row'}
        spacing={1}
      >
        {icon}
        <Typography variant="body1" sx={TEXT} whiteSpace={'nowrap'}>
          {title}
        </Typography>
      </Stack>
    </Button>
  );

  return comingSoon ? (
    <Tooltip title="Coming Soon">
      <div>{button}</div>
    </Tooltip>
  ) : (
    button
  );
}
