import { Stack, SxProps, Typography } from '@mui/material';

const CHIP: SxProps = {
  bgcolor: '#F3E8E8',
  borderRadius: 0.5,
  height: 22,
  width: 55,
};

const TEXT: SxProps = {
  fontSize: 11,
  color: 'black',
  letterSpacing: '-0.3 px',
};

interface Props {
  method: string;
  grayed?: boolean;
}

export default function APIMethodChip({ method, grayed }: Props) {
  return (
    <Stack sx={CHIP} alignItems={'center'} justifyContent={'center'}>
      <Typography variant="body1" sx={TEXT}>
        {method.toUpperCase()}
      </Typography>
    </Stack>
  );
}
