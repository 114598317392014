import { SvgIconProps } from './SvgIconProps';

export const LogoutIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2405 22.2705H15.1105C10.6705 22.2705 8.5305 20.5205 8.1605 16.6005C8.1205 16.1905 8.4205 15.8205 8.8405 15.7805C9.2405 15.7405 9.6205 16.0505 9.6605 16.4605C9.9505 19.6005 11.4305 20.7705 15.1205 20.7705H15.2505C19.3205 20.7705 20.7605 19.3305 20.7605 15.2605V8.74047C20.7605 4.67047 19.3205 3.23047 15.2505 3.23047H15.1205C11.4105 3.23047 9.9305 4.42047 9.6605 7.62047C9.6105 8.03047 9.2605 8.34047 8.8405 8.30047C8.4205 8.27047 8.1205 7.90047 8.1505 7.49047C8.4905 3.51047 10.6405 1.73047 15.1105 1.73047H15.2405C20.1505 1.73047 22.2505 3.83047 22.2505 8.74047V15.2605C22.2505 20.1705 20.1505 22.2705 15.2405 22.2705Z"
        fill={color}
      />
      <path
        d="M14.9972 12.75H3.61719C3.20719 12.75 2.86719 12.41 2.86719 12C2.86719 11.59 3.20719 11.25 3.61719 11.25H14.9972C15.4072 11.25 15.7472 11.59 15.7472 12C15.7472 12.41 15.4072 12.75 14.9972 12.75Z"
        fill={color}
      />
      <path
        d="M5.8475 16.0998C5.6575 16.0998 5.4675 16.0298 5.3175 15.8798L1.9675 12.5298C1.6775 12.2398 1.6775 11.7598 1.9675 11.4698L5.3175 8.11984C5.6075 7.82984 6.0875 7.82984 6.3775 8.11984C6.6675 8.40984 6.6675 8.88984 6.3775 9.17984L3.5575 11.9998L6.3775 14.8198C6.6675 15.1098 6.6675 15.5898 6.3775 15.8798C6.2375 16.0298 6.0375 16.0998 5.8475 16.0998Z"
        fill={color}
      />
    </svg>
  );
};
