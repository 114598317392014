import { LoadingButton } from '@mui/lab';
import { SxProps } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DIALOG_CONTAINER: SxProps = { p: 8, textAlign: 'center' };
const DIALOG_TITLE: SxProps = { fontSize: 24, fontWeight: 500, pt: 0 };
const DIALOG_ACTIONS: SxProps = { justifyContent: 'center' };

export interface AlertDialogAction {
  title: string;
  loading?: boolean;
  color?: ButtonProps['color'];
  variant?: ButtonProps['variant'];
  onClick?: ButtonProps['onClick'];
}

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
  actions: AlertDialogAction[];
}

export default function AlertDialog({
  open,
  onClose,
  title,
  content,
  actions,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: DIALOG_CONTAINER }}
    >
      {title ? (
        <DialogTitle id="alert-dialog-title" sx={DIALOG_TITLE}>
          {title}
        </DialogTitle>
      ) : (
        <></>
      )}
      <DialogContent>
        {content ? (
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions sx={DIALOG_ACTIONS}>
        {actions.map((action, i) => (
          <LoadingButton
            key={action.title}
            color={action.color}
            variant={action.variant}
            onClick={action.onClick}
            loading={action.loading ?? false}
          >
            {action.title}
          </LoadingButton>
        ))}
      </DialogActions>
    </Dialog>
  );
}
