import { useContext, useEffect } from 'react';
import {
  Box,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { InfoRounded, WarningRounded } from '@mui/icons-material';

import { AuthContext } from '../../contexts/Auth';
import {
  OrganizationContext,
  OrganizationContextProps,
} from '../../contexts/Organization';
import CopyField from '../Common/CopyTextField';

const NOT_AVAILABLE_WARNING: SxProps = {
  textAlign: 'start',
  my: 1,
  px: 2,
  py: 2,
  bgcolor: 'white',
  borderRadius: 1,
  border: 'solid 1px',
  borderColor: 'warning.main',
  boxShadow: '0px 0px 4px 0px #0000001A',
  color: 'warning.dark',
};

const SSO_SET_SUCCESS: SxProps = {
  textAlign: 'start',
  my: 1,
  px: 2,
  py: 2,
  bgcolor: 'white',
  borderRadius: 1,
  border: 'solid 1px',
  borderColor: 'success.main',
  boxShadow: '0px 0px 4px 0px #0000001A',
  color: 'success.dark',
};

const COPY_FIELD_PROPS: SxProps = {
  'minWidth': 400,
  '.MuiFilledInput-root': {
    mt: 1,
    fontSize: 14,
  },
};

const CLAIMS_BOX_HEAD_PADDING: SxProps = {
  padding: 0.5,
  paddingRight: 1,
};

const CLAIMS_BOX_CELL_PADDING: SxProps = {
  padding: 0.5,
};


const NotAvailable = () => (
  <Stack sx={NOT_AVAILABLE_WARNING} direction={'row'} spacing={1}>
    <WarningRounded />
    <Stack>
      <Typography variant={'h5'}>Not available during Trial</Typography>
      <Typography variant={'body2'}>
        The Single Sign-On feature is only available on paid plans.
      </Typography>
    </Stack>
  </Stack>
);

const ClaimsBox = () => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell sx={CLAIMS_BOX_HEAD_PADDING}>Attribute Value</TableCell>
        <TableCell sx={CLAIMS_BOX_HEAD_PADDING}>Attribute Name</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell sx={CLAIMS_BOX_CELL_PADDING}>First Name</TableCell>
        <TableCell sx={CLAIMS_BOX_CELL_PADDING}>
          http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={CLAIMS_BOX_CELL_PADDING}>Last Name</TableCell>
        <TableCell sx={CLAIMS_BOX_CELL_PADDING}>
          http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={CLAIMS_BOX_CELL_PADDING}>Email</TableCell>
        <TableCell sx={CLAIMS_BOX_CELL_PADDING}>
          http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

const SSOSection = () => {
  const { ssoConfig, fetchSSOConfig } = useContext(
    OrganizationContext,
  ) as OrganizationContextProps;

  useEffect(() => {
    if (!ssoConfig) {
      fetchSSOConfig();
    }
  }, [ssoConfig]);

  if (!ssoConfig) {
    return null;
  }

  if (ssoConfig.identity_provider !== null) {
    const loginUrl = `${window.location.origin}/login/${ssoConfig.identity_provider}`;
    return (
      <Box sx={SSO_SET_SUCCESS}>
        <Stack direction={'row'} spacing={1}>
          <InfoRounded />
          <Stack>
            <Typography variant={'h5'}>SSO is set and enforced</Typography>
            <Typography variant={'body2'}>
              Login to Pynt by using the link below or using &quot;Login with
              SSO&quot; in our login page.
            </Typography>
            <CopyField title={loginUrl} sx={COPY_FIELD_PROPS} text={loginUrl} />
          </Stack>
        </Stack>
      </Box>
    );
  }

  const acsUrl = `${process.env.REACT_APP_OAUTH_BASE_URL}/saml2/idpresponse`;
  const audience = `urn:amazon:cognito:sp:${process.env.REACT_APP_COGNITO_USER_POOL}`;

  return (
    <Box sx={{ textAlign: 'left', fontSize: 14 }}>
      Use the following information to set up your identity provider:
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Integration type</TableCell>
              <TableCell>SAML 2.0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">
                Assertion Consumer Services (ACS) URL
              </TableCell>
              <TableCell>
                <CopyField sx={COPY_FIELD_PROPS} text={acsUrl} title={acsUrl} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Audience</TableCell>
              <TableCell>
                <CopyField
                  sx={COPY_FIELD_PROPS}
                  text={audience}
                  title={audience}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Attribute Statements</TableCell>
              <TableCell>
                <ClaimsBox />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Name ID Format</TableCell>
              <TableCell>EmailAddress</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Application username</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const SingleSignOn = () => {
  const { license } = useContext(AuthContext);

  return (
    <Stack alignItems={'start'}>
      <Typography variant={'h3'}>Single Sign-On (SSO)</Typography>
      {license === 'trial' ? <NotAvailable /> : <SSOSection />}
    </Stack>
  );
};

export default SingleSignOn;
