import { SvgIconProps } from './SvgIconProps';

export const ExportIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.493 1.66699H6.50964C3.4763 1.66699 1.66797 3.47533 1.66797 6.50866V13.4837C1.66797 16.5253 3.4763 18.3337 6.50964 18.3337H13.4846C16.518 18.3337 18.3263 16.5253 18.3263 13.492V6.50866C18.3346 3.47533 16.5263 1.66699 13.493 1.66699ZM14.3763 10.2753C14.3763 10.617 14.093 10.9003 13.7513 10.9003C13.4096 10.9003 13.1263 10.617 13.1263 10.2753V7.75866L6.69297 14.192C6.56797 14.317 6.40964 14.3753 6.2513 14.3753C6.09297 14.3753 5.93464 14.317 5.80964 14.192C5.56797 13.9503 5.56797 13.5503 5.80964 13.3087L12.243 6.87533H9.7263C9.38464 6.87533 9.1013 6.59199 9.1013 6.25033C9.1013 5.90866 9.38464 5.62533 9.7263 5.62533H13.7513C14.093 5.62533 14.3763 5.90866 14.3763 6.25033V10.2753Z"
        fill={color}
      />
    </svg>
  );
};
