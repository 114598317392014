import { SvgIconProps } from './SvgIconProps';

export const DocumentText = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 12V14C15 14.5515 14.5515 15 14 15H2C1.4485 15 1 14.5515 1 14V12C1 11.724 1.224 11.5 1.5 11.5C1.776 11.5 2 11.724 2 12V14H14V12C14 11.724 14.224 11.5 14.5 11.5C14.776 11.5 15 11.724 15 12Z"
        fill={color}
      />
      <path
        d="M4.14653 8.3535C3.95103 8.158 3.95103 7.842 4.14653 7.6465C4.34203 7.451 4.65803 7.451 4.85353 7.6465L7.50003 10.293V1.5C7.50003 1.224 7.72403 1 8.00003 1C8.27603 1 8.50003 1.224 8.50003 1.5V10.293L11.1465 7.6465C11.342 7.451 11.658 7.451 11.8535 7.6465C12.049 7.842 12.049 8.158 11.8535 8.3535L8.35353 11.8535C8.15553 12.052 7.83803 12.0455 7.64603 11.8535L4.14653 8.3535Z"
        fill={color}
      />
    </svg>
  );
};
