import { SvgIconProps } from './SvgIconProps';

export const TotalScansIcon = ({ size = 50 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="50" height="50" rx="8" fill="#DBDDE4" />
      <path
        d="M23.3945 26.8931H27.1812L27.6079 23.1064H23.8212L23.3945 26.8931Z"
        fill="white"
      />
      <path
        d="M31.088 11.6665H19.9146C15.0613 11.6665 12.168 14.5598 12.168 19.4132V30.5732C12.168 35.4398 15.0613 38.3332 19.9146 38.3332H31.0746C35.928 38.3332 38.8213 35.4398 38.8213 30.5865V19.4132C38.8346 14.5598 35.9413 11.6665 31.088 11.6665ZM34.5946 23.1065H29.568L29.1413 26.9065H33.6346C34.168 26.9065 34.608 27.3465 34.608 27.8798C34.608 28.4132 34.168 28.8532 33.6346 28.8532H28.928L28.3813 33.7332C28.328 34.2265 27.9013 34.5998 27.408 34.5998C27.368 34.5998 27.3413 34.5998 27.3013 34.5998C26.768 34.5465 26.3813 34.0532 26.4346 33.5198L26.9546 28.8532H23.168L22.6213 33.7332C22.568 34.2265 22.1413 34.5998 21.648 34.5998C21.608 34.5998 21.5813 34.5998 21.5413 34.5998C21.008 34.5465 20.6213 34.0532 20.6746 33.5198L21.1946 28.8532H16.408C15.8746 28.8532 15.4346 28.4132 15.4346 27.8798C15.4346 27.3465 15.8746 26.9065 16.408 26.9065H21.4346L21.8613 23.1065H17.368C16.8346 23.1065 16.3946 22.6665 16.3946 22.1332C16.3946 21.5998 16.8346 21.1598 17.368 21.1598H22.0746L22.6213 16.2798C22.6746 15.7465 23.168 15.3598 23.7013 15.4132C24.2346 15.4665 24.6213 15.9598 24.568 16.4932L24.048 21.1598H27.8346L28.3813 16.2798C28.448 15.7465 28.928 15.3598 29.4613 15.4132C29.9946 15.4665 30.3813 15.9598 30.328 16.4932L29.808 21.1598H34.6213C35.1546 21.1598 35.5946 21.5998 35.5946 22.1332C35.5946 22.6665 35.128 23.1065 34.5946 23.1065Z"
        fill="white"
      />
    </svg>
  );
};
