import { Card, Stack, SxProps, Typography } from '@mui/material';
import { TotalAPIsIcon } from '../../assets/svgs/icons/TotalAPIsIcon';
import { TotalScansIcon } from '../../assets/svgs/icons/TotalScansIcon';
import ViewAllButton from '../Common/ViewAllButton';

const CARD: SxProps = {
  p: 2,
};

const TITLE: SxProps = {
  textAlign: 'left',
  color: '#C0C0C0',
  fontSize: 16,
  fontWeight: 500,
};

const VALUE: SxProps = {
  color: 'black',
  textAlign: 'left',
  fontSize: 26,
  fontWeight: 700,
};

interface Props {
  title: string;
  value?: number | string;
  type: 'api' | 'scans';
  onViewMoreClick?: () => void;
}

const OverviewTotalCard = ({ title, value, type, onViewMoreClick }: Props) => {
  return (
    <Card elevation={0} sx={CARD}>
      <Stack
        direction={'row'}
        spacing={1.5}
        justifyContent={'space-between'}
        alignItems={'start'}
      >
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent={'start'}
          alignItems={'center'}
        >
          {type === 'api' && <TotalAPIsIcon size={56} />}
          {type === 'scans' && <TotalScansIcon size={56} />}
          <Stack direction={'column'} spacing={0}>
            <Typography sx={TITLE}>{title}</Typography>
            <Typography sx={VALUE}>{value ?? '-'}</Typography>
          </Stack>
        </Stack>
        <ViewAllButton onClick={onViewMoreClick} />
      </Stack>
    </Card>
  );
};

export default OverviewTotalCard;
