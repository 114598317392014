import { MenuItem, Select, Stack, SxProps } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/Auth';

const STACK: SxProps = {
  pt: 4,
  pb: 3,
  pl: 2,
  pr: 2,
};

const SELECTOR: SxProps = {
  'width': '100%',
  'color': '#838897',
  'bgcolor': '#39394B',
  '.MuiSelect-root': {
    bgcolor: '#39394B',
    border: 'none',
  },
  ':active': {
    bgcolor: '#39394B',
    border: 'none',
  },
  ':hover': {
    bgcolor: '#39394B',
    border: 'none',
  },
  ':focus': {
    bgcolor: '#39394B',
    border: 'none',
  },
  '&.Mui-focused': {
    bgcolor: '#39394B',
    border: 'none',
  },
};

export default function DashboardAvatar() {
  const { user, organization } = useContext(AuthContext);

  return (
    <Stack direction={'column'} spacing={1} alignItems={'center'} sx={STACK}>
      <Select
        value={user?.organization_id}
        size="small"
        inputProps={{ 'aria-label': 'Without label' }}
        sx={SELECTOR}
      >
        <MenuItem value={organization?.organization_id}>
          {organization?.name}
        </MenuItem>
      </Select>
    </Stack>
  );
}
