import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import {
  Application,
  UserContext,
  UserContextProps,
} from '../../contexts/User';

export default function SelectApplication({
  selectedApplicationId,
  setSelectedApplicationId,
}: {
  selectedApplicationId: string | undefined;
  setSelectedApplicationId: any;
}) {
  const { applications } = React.useContext(UserContext) as UserContextProps;

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedApplicationId(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 220 }}>
      <FormControl fullWidth>
        <Select
          value={selectedApplicationId}
          variant="filled"
          onChange={handleChange}
          size="small"
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: 4,
                borderRadius: 6,
                textAlign: 'start',
                backgroundColor: 'white',
                border: 'solid 1px #D3D3D3',
                boxShadow: 'none',
              },
            },
          }}
        >
          <MenuItem value={'*'}>All applications</MenuItem>
          {applications?.map((application: Application) => (
            <MenuItem key={application.app_id} value={application.app_id}>
              {application.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
