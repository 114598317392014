interface Props {
  width?: number;
  height?: number;
}

export const NoApplications = ({ height = 258, width = 348 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 348 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.097 186.168C8.58339 175.854 11.021 166.959 11.021 166.959C12.8851 159.742 16.6079 152.915 22.2002 147.268L113.869 54.7798C115.696 52.9366 115.696 49.9306 113.869 48.0874C112.042 46.2442 109.063 46.2442 107.236 48.0874L55.9766 99.8048C48.4831 107.365 36.3321 107.365 28.8386 99.8048C21.3451 92.2444 21.3451 79.9848 28.8386 72.4244L80.098 20.707C96.8907 3.76434 124.214 3.76434 141.007 20.707C157.8 37.6496 157.8 65.2176 141.007 82.1602L49.3434 174.648C47.5165 176.491 47.5165 179.497 49.3434 181.341L51.1544 183.168C52.9813 185.011 55.9606 185.011 57.7875 183.168L107.841 132.667C115.335 125.106 127.486 125.106 134.979 132.667C142.473 140.227 142.473 152.487 134.979 160.047L98.3139 197.04H98.2714L84.2882 211.148C67.3044 228.284 39.6726 228.284 22.6941 211.148C18.2277 206.642 14.9457 201.386 12.832 195.781C12.832 195.781 10.9945 192.196 10.1076 186.168H10.097Z"
        fill="#D0D3D7"
        fillOpacity="0.27"
      />
      <g filter="url(#filter0_d_4096_20601)">
        <rect
          x="53.9805"
          y="50"
          width="239.828"
          height="150.016"
          rx="11.8434"
          fill="white"
        />
      </g>
      <rect
        x="105.305"
        y="72.6992"
        width="88.8252"
        height="51.3212"
        rx="3.94779"
        fill="#FBFCFE"
      />
      <rect
        x="108.266"
        y="75.6592"
        width="82.9035"
        height="7.89558"
        rx="1.97389"
        fill="#F0F4F7"
        fillOpacity="0.5"
      />
      <rect
        x="112.211"
        y="90.4658"
        width="75.9949"
        height="7.89558"
        rx="1.97389"
        fill="white"
        fillOpacity="0.71"
      />
      <path
        d="M183.174 93.9215C183.254 93.8413 183.383 93.8413 183.464 93.9215L184.261 94.7193L185.059 93.9215C185.139 93.8413 185.269 93.8413 185.349 93.9215C185.429 94.0017 185.429 94.1312 185.349 94.2114L184.405 95.1552C184.325 95.2353 184.196 95.2353 184.115 95.1552L183.172 94.2114C183.093 94.1333 183.093 94.0017 183.174 93.9215Z"
        fill="#B8C5DB"
      />
      <rect
        x="181.301"
        y="97.376"
        width="5.92168"
        height="5.92168"
        rx="1.97389"
        transform="rotate(-90 181.301 97.376)"
        stroke="white"
      />
      <rect
        x="116.16"
        y="93.4258"
        width="44.4126"
        height="1.48042"
        rx="0.74021"
        fill="#D3DFF2"
        fillOpacity="0.21"
      />
      <rect
        x="112.211"
        y="108.228"
        width="75.9949"
        height="7.89558"
        rx="1.97389"
        fill="white"
        fillOpacity="0.71"
      />
      <path
        d="M183.174 111.684C183.254 111.603 183.383 111.603 183.464 111.684L184.261 112.481L185.059 111.684C185.139 111.603 185.269 111.603 185.349 111.684C185.429 111.764 185.429 111.893 185.349 111.974L184.405 112.917C184.325 112.998 184.196 112.998 184.115 112.917L183.172 111.974C183.093 111.895 183.093 111.764 183.174 111.684Z"
        fill="#B8C5DB"
      />
      <rect
        x="181.301"
        y="115.138"
        width="5.92168"
        height="5.92168"
        rx="1.97389"
        transform="rotate(-90 181.301 115.138)"
        stroke="white"
      />
      <rect
        x="116.16"
        y="111.188"
        width="44.4126"
        height="1.48042"
        rx="0.74021"
        fill="#D3DFF2"
        fillOpacity="0.21"
      />
      <rect
        x="112.211"
        y="87.5039"
        width="26.6476"
        height="1.48042"
        rx="0.74021"
        fill="#E7ECF1"
      />
      <rect
        x="112.211"
        y="105.269"
        width="26.6476"
        height="1.48042"
        rx="0.74021"
        fill="#E7ECF1"
      />
      <rect
        x="200.055"
        y="72.6992"
        width="88.8252"
        height="51.3212"
        rx="3.94779"
        fill="#FBFCFE"
      />
      <rect
        x="203.016"
        y="75.6592"
        width="82.9035"
        height="7.89558"
        rx="1.97389"
        fill="#F0F4F7"
        fillOpacity="0.5"
      />
      <rect
        x="206.961"
        y="87.5039"
        width="26.6476"
        height="1.48042"
        rx="0.74021"
        fill="#E7ECF1"
      />
      <circle cx="210.535" cy="96.8802" r="4.44126" fill="#ECF0F3" />
      <rect
        x="217.938"
        y="94.6597"
        width="16.7781"
        height="1.97389"
        rx="0.986947"
        fill="#E7ECF1"
      />
      <rect
        x="217.938"
        y="97.6206"
        width="26.6476"
        height="1.48042"
        rx="0.74021"
        fill="#E7ECF1"
      />
      <rect
        x="105.305"
        y="53.9473"
        width="183.572"
        height="14.8042"
        rx="1.97389"
        fill="#F0F4F6"
        fillOpacity="0.43"
      />
      <rect
        x="258.281"
        y="57.8945"
        width="24.6737"
        height="7.89558"
        rx="1.97389"
        fill="#DDE3EB"
        fillOpacity="0.2"
      />
      <rect
        x="112.211"
        y="58.8823"
        width="51.3212"
        height="1.97389"
        rx="0.986947"
        fill="#DDE3EB"
        fillOpacity="0.35"
      />
      <rect
        x="112.211"
        y="62.0889"
        width="16.0379"
        height="1.60379"
        rx="0.801894"
        fill="#DDE3EB"
        fillOpacity="0.35"
      />
      <path
        d="M57.9297 65.7907C57.9297 59.2497 63.2321 53.9473 69.7731 53.9473H102.342V196.068H69.7731C63.2321 196.068 57.9297 190.765 57.9297 184.224V65.7907Z"
        fill="#EEF1F5"
        fillOpacity="0.6"
      />
      <path
        d="M69.154 66.5759C69.154 66.9055 69.0563 67.2277 68.8732 67.5018C68.6901 67.7758 68.4298 67.9894 68.1253 68.1156C67.8207 68.2417 67.4857 68.2747 67.1624 68.2104C66.8391 68.1461 66.5422 67.9874 66.3091 67.7543C66.076 67.5212 65.9173 67.2243 65.853 66.901C65.7887 66.5777 65.8217 66.2427 65.9478 65.9381C66.074 65.6336 66.2876 65.3733 66.5616 65.1902C66.8357 65.0071 67.1579 64.9094 67.4875 64.9094C67.7065 64.9088 67.9235 64.9515 68.1259 65.035C68.3284 65.1186 68.5123 65.2413 68.6672 65.3962C68.8221 65.5511 68.9448 65.735 69.0284 65.9375C69.1119 66.1399 69.1546 66.3569 69.154 66.5759ZM70.6938 66.5759C70.6938 65.7255 70.356 64.91 69.7547 64.3087C69.1534 63.7074 68.3379 63.3696 67.4875 63.3696C66.6372 63.3696 65.8216 63.7074 65.2203 64.3087C64.6191 64.91 64.2812 65.7255 64.2812 66.5759V72.128H65.821V69.3112C66.3066 69.6084 66.8627 69.7708 67.4319 69.7816C68.0012 69.7923 68.563 69.6511 69.0595 69.3724C69.5559 69.0937 69.9691 68.6876 70.2564 68.1961C70.5437 67.7045 70.6946 67.1452 70.6938 66.5759Z"
        fill="#DDE3EB"
      />
      <path
        d="M64 61.8969L70.4046 61C70.7204 61 70.9766 61.4016 70.9766 61.8969C70.9766 62.3922 70.7205 62.7937 70.4046 62.7937L64 62.0763V61.8969Z"
        fill="#DDE3EB"
      />
      <rect
        x="62.0742"
        y="87.5039"
        width="36.1149"
        height="8.33422"
        rx="1.97389"
        fill="#DDE3EB"
        fillOpacity="0.5"
      />
      <rect
        x="74.5781"
        y="65.2793"
        width="22.2246"
        height="1.38904"
        rx="0.694518"
        fill="#DDE3EB"
        fillOpacity="0.53"
      />
      <rect
        x="74.8203"
        y="67.7642"
        width="9.86947"
        height="1.97389"
        rx="0.986947"
        fill="#DDE3EB"
        fillOpacity="0.53"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.893 154.178C189.62 154.178 202.368 141.429 202.368 125.703C202.368 109.976 189.62 97.2275 173.893 97.2275C158.167 97.2275 145.418 109.976 145.418 125.703C145.418 141.429 158.167 154.178 173.893 154.178ZM185.306 128.094C186.408 128.094 187.381 127.143 187.381 126.002C187.381 124.924 186.408 123.973 185.306 124.036H175.968V114.906C175.968 113.765 174.995 112.814 173.893 112.814C172.725 112.814 171.818 113.765 171.818 114.906V124.036H162.479C161.312 124.036 160.404 124.924 160.404 126.002C160.404 127.143 161.312 128.094 162.479 128.094H171.818V137.225C171.818 138.303 172.725 139.191 173.893 139.191C174.995 139.191 175.968 138.303 175.968 137.225V128.094H185.306Z"
        fill="#BCC1D1"
      />
      <path
        d="M294 35V43"
        stroke="#BCC1D1"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M303.559 58H311.559"
        stroke="#BCC1D1"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M307.656 43L301.999 48.6569"
        stroke="#BCC1D1"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d_4096_20601"
          x="0.685333"
          y="0.652653"
          width="346.418"
          height="256.606"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.94779" />
          <feGaussianBlur stdDeviation="26.6476" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.351111 0 0 0 0 0.381833 0 0 0 0 0.658333 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4096_20601"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4096_20601"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
