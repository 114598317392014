import { useEffect, useRef } from 'react';

/**
 * Custom hook to get the previous value of a variable.
 * @param {T} value The current value to track.
 * @return {T | undefined} The previous value of the provided variable.
 */
function usePrevious<T>(value: T): T | undefined {
  // Ref to store the previous value
  const ref = useRef<T>();

  useEffect(() => {
    // Update the ref to the current value after render
    ref.current = value;
  }, [value]); // Only run when the value changes

  // Return the previous value
  return ref.current;
}

export default usePrevious;
