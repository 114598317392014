import { SvgIconProps } from './SvgIconProps';

export const DangerIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.134 13.2665L12.8007 3.6665C12.084 2.37484 11.0924 1.6665 10.0007 1.6665C8.90905 1.6665 7.91738 2.37484 7.20071 3.6665L1.86738 13.2665C1.19238 14.4915 1.11738 15.6665 1.65905 16.5915C2.20071 17.5165 3.26738 18.0248 4.66738 18.0248H15.334C16.734 18.0248 17.8007 17.5165 18.3424 16.5915C18.884 15.6665 18.809 14.4832 18.134 13.2665ZM9.37571 7.49984C9.37571 7.15817 9.65905 6.87484 10.0007 6.87484C10.3424 6.87484 10.6257 7.15817 10.6257 7.49984V11.6665C10.6257 12.0082 10.3424 12.2915 10.0007 12.2915C9.65905 12.2915 9.37571 12.0082 9.37571 11.6665V7.49984ZM10.5924 14.7582C10.5507 14.7915 10.509 14.8248 10.4674 14.8582C10.4174 14.8915 10.3674 14.9165 10.3174 14.9332C10.2674 14.9582 10.2174 14.9748 10.159 14.9832C10.109 14.9915 10.0507 14.9998 10.0007 14.9998C9.95071 14.9998 9.89238 14.9915 9.83405 14.9832C9.78405 14.9748 9.73405 14.9582 9.68405 14.9332C9.63405 14.9165 9.58405 14.8915 9.53405 14.8582C9.49238 14.8248 9.45071 14.7915 9.40905 14.7582C9.25905 14.5998 9.16738 14.3832 9.16738 14.1665C9.16738 13.9498 9.25905 13.7332 9.40905 13.5748C9.45071 13.5415 9.49238 13.5082 9.53405 13.4748C9.58405 13.4415 9.63405 13.4165 9.68405 13.3998C9.73405 13.3748 9.78405 13.3582 9.83405 13.3498C9.94238 13.3248 10.059 13.3248 10.159 13.3498C10.2174 13.3582 10.2674 13.3748 10.3174 13.3998C10.3674 13.4165 10.4174 13.4415 10.4674 13.4748C10.509 13.5082 10.5507 13.5415 10.5924 13.5748C10.7424 13.7332 10.834 13.9498 10.834 14.1665C10.834 14.3832 10.7424 14.5998 10.5924 14.7582Z"
        fill={color}
      />
    </svg>
  );
};
