import { styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DARK_GRAY } from '../../constants/colors';

const PyntDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: '0px',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'unset',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '-0.3px',
    color: DARK_GRAY,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${
      theme.palette.mode === 'light' ? '#F5F8FD' : '#F5F8FD'
    }`,
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: 'white', // Remove background
    boxShadow: 'none', // Remove shadow
  },
  '& .MuiDataGrid-pinnedColumns': {
    backgroundColor: 'white', // Remove background
    boxShadow: 'none', // Remove shadow
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${
      theme.palette.mode === 'light' ? '#F5F8FD' : '#F5F8FD'
    }`,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'unset',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'unset',
  },
  '& .MuiDataGrid-overlay': {
    backgroundColor: 'unset',
  },
}));

export default PyntDataGrid;
