import { SvgIconProps } from './SvgIconProps';

export const MenuIcon = ({ color = '#ACACBB', size = 16 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.00022"
        y="1"
        width="10.9999"
        height="1.82608"
        rx="0.913041"
        fill={color}
      />
      <rect
        x="2.00013"
        y="7.08704"
        width="13.9999"
        height="1.82608"
        rx="0.913041"
        fill={color}
      />
      <rect
        x="7.00014"
        y="13.174"
        width="8.99992"
        height="1.82608"
        rx="0.913041"
        fill={color}
      />
    </svg>
  );
};
