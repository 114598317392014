import { SvgIconProps } from './SvgIconProps';

export const OverviewIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4285 11.4285H8.57139C8.19251 11.4285 7.82915 11.579 7.56124 11.8469C7.29333 12.1148 7.14282 12.4782 7.14282 12.857V18.5713C7.14282 18.9502 7.29333 19.3136 7.56124 19.5815C7.82915 19.8494 8.19251 19.9999 8.57139 19.9999H11.4285C11.8074 19.9999 12.1708 19.8494 12.4387 19.5815C12.7066 19.3136 12.8571 18.9502 12.8571 18.5713V12.857C12.8571 12.4782 12.7066 12.1148 12.4387 11.8469C12.1708 11.579 11.8074 11.4285 11.4285 11.4285ZM8.57139 18.5713V12.857H11.4285V18.5713H8.57139Z"
        fill={color}
      />
      <path
        d="M4.28571 13.5715H1.42857C1.04969 13.5715 0.686328 13.722 0.418419 13.99C0.15051 14.2579 0 14.6212 0 15.0001V18.5715C0 18.9504 0.15051 19.3138 0.418419 19.5817C0.686328 19.8496 1.04969 20.0001 1.42857 20.0001H4.28571C4.66459 20.0001 5.02796 19.8496 5.29587 19.5817C5.56378 19.3138 5.71429 18.9504 5.71429 18.5715V15.0001C5.71429 14.6212 5.56378 14.2579 5.29587 13.99C5.02796 13.722 4.66459 13.5715 4.28571 13.5715ZM1.42857 18.5715V15.0001H4.28571V18.5715H1.42857Z"
        fill={color}
      />
      <path
        d="M18.5715 7.85718H15.7143C15.3355 7.85718 14.9721 8.00769 14.7042 8.2756C14.4363 8.54351 14.2858 8.90687 14.2858 9.28575V18.5715C14.2858 18.9503 14.4363 19.3137 14.7042 19.5816C14.9721 19.8495 15.3355 20 15.7143 20H18.5715C18.9504 20 19.3137 19.8495 19.5816 19.5816C19.8495 19.3137 20.0001 18.9503 20.0001 18.5715V9.28575C20.0001 8.90687 19.8495 8.54351 19.5816 8.2756C19.3137 8.00769 18.9504 7.85718 18.5715 7.85718ZM15.7143 18.5715V9.28575H18.5715V18.5715H15.7143Z"
        fill={color}
      />
      <path
        d="M2.85713 10.7143C2.91663 10.7142 2.97589 10.7068 3.03356 10.6921C5.59018 10.0408 8.05007 9.0562 10.35 7.76357C12.8538 6.34871 15.1414 4.58163 17.1428 2.51643V4.28571C17.1428 4.47515 17.2181 4.65684 17.3521 4.79079C17.486 4.92475 17.6677 5 17.8571 5C18.0466 5 18.2283 4.92475 18.3622 4.79079C18.4962 4.65684 18.5714 4.47515 18.5714 4.28571V0.714286C18.5714 0.524845 18.4962 0.343164 18.3622 0.209209C18.2283 0.0752549 18.0466 0 17.8571 0H14.2857C14.0963 0 13.9146 0.0752549 13.7806 0.209209C13.6467 0.343164 13.5714 0.524845 13.5714 0.714286C13.5714 0.903726 13.6467 1.08541 13.7806 1.21936C13.9146 1.35332 14.0963 1.42857 14.2857 1.42857H16.1971C14.2738 3.43493 12.0682 5.15011 9.64999 6.52C7.45908 7.75012 5.11589 8.68673 2.6807 9.30571C2.51002 9.34692 2.3605 9.44954 2.26068 9.594C2.16086 9.73845 2.11775 9.91461 2.13957 10.0888C2.16139 10.2631 2.24662 10.4231 2.37898 10.5385C2.51134 10.6539 2.68156 10.7165 2.85713 10.7143Z"
        fill={color}
      />
    </svg>
  );
};
