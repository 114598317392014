import { Snackbar } from '@mui/material';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import React, { createContext } from 'react';

interface SnackbarContext {
  show: (message: string, type?: AlertColor) => void;
  showDebug: (message: string, type?: AlertColor) => void;
  hide: () => void;
}

export const SnackbarContext = createContext<SnackbarContext>({
  show: () => {},
  showDebug: () => {},
  hide: () => {},
});

function SnackbarContextProvider(props: any) {
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [type, setType] = React.useState<AlertColor>();
  const [message, setMessage] = React.useState('');

  const show = (message: string, type: AlertColor = 'info') => {
    setType(type);
    setMessage(message);
    setShowSnackbar(true);
  };

  const showDebug = (message: string, type: AlertColor = 'info') => {
    if (process.env.REACT_APP_NODE_ENV !== 'development') return;
    show(message, type);
  };

  const hide = () => {
    setShowSnackbar(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        show: show,
        showDebug: showDebug,
        hide: hide,
      }}
    >
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={hide}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert elevation={6} variant="filled" onClose={hide} severity={type}>
          {message}
        </MuiAlert>
      </Snackbar>
      <div>{props.children}</div>
    </SnackbarContext.Provider>
  );
}

export default SnackbarContextProvider;
