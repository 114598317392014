import { SvgIconProps } from './SvgIconProps';

export const DeleteIcon = ({ color = '#ACACBB', size = 16 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4014 3.07861L11.4578 14.7028H4.54017L3.59679 3.07861L2.4014 3.19189L3.36201 15.0269C3.41257 15.5724 3.85038 16.0001 4.35899 16.0001H11.639C12.1474 16.0001 12.5854 15.5726 12.6368 15.0191L13.5968 3.19189L12.4014 3.07861Z"
        fill={color}
      />
      <path
        d="M10.1981 0H5.79809C5.24668 0 4.79808 0.484969 4.79808 1.08109V3.13516H5.99806V1.29728H9.99805V3.13512H11.198V1.08106C11.1981 0.484969 10.7495 0 10.1981 0Z"
        fill={color}
      />
      <path
        d="M14.4005 2.48682H1.60049C1.26908 2.48682 1.00049 2.77719 1.00049 3.13547C1.00049 3.49375 1.26908 3.78413 1.60049 3.78413H14.4005C14.7319 3.78413 15.0005 3.49375 15.0005 3.13547C15.0005 2.77719 14.7319 2.48682 14.4005 2.48682Z"
        fill={color}
      />
    </svg>
  );
};
