import { ContentCopy, KeyboardArrowLeftRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Stack, SxProps, Typography } from '@mui/material';
import { CSSProperties, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BRAND_ORANGE } from '../../constants/colors';
import { SnackbarContext } from '../../contexts/Snackbar';
import { track } from '../../utils/analytics';

const TITLE: SxProps = {
  color: 'white',
  fontSize: 36,
  fontWeight: 700,
  letterSpacing: '-1.2px',
};

const TEXT: SxProps = {
  color: 'white',
};

const LINK: CSSProperties = {
  color: BRAND_ORANGE,
};

const MEDIUM_TEXT: SxProps = {
  color: 'white',
  fontSize: '22px',
  fontWeight: 600,
};

const SMALL_TEXT: SxProps = {
  color: '#ccd2e9',
};

const COPY_BOX: SxProps = {
  border: '1px solid #40475c',
  p: 2,
  borderRadius: 1,
};

const SUBMIT_BUTTON: CSSProperties = {
  width: '204px',
  height: '60px',
  fontFamily: 'Raleway',
  fontSize: '18px',
};

const PostmanOnboardRun = () => {
  const navigate = useNavigate();

  const { show } = useContext(SnackbarContext);

  const handlePrevClick = useCallback(() => {
    track('onboarding-3-previous');
    navigate(-1);
  }, [navigate]);

  const handleNextClick = useCallback(() => {
    track('onboarding-3-finish');
    window.location.href = 'https://www.pynt.io';
  }, []);

  return (
    <Stack alignItems={'center'} p={2} height={'100%'}>
      <Stack
        direction={'column'}
        spacing={1}
        pt={5}
        maxWidth={'700px'}
        height={'100%'}
      >
        <Typography sx={TITLE} textAlign={'start'}>
          You&apos;re almost done!
        </Typography>

        <Stack direction={'column'} spacing={2} mt={1}>
          <Typography sx={TEXT}>
            <strong>1. Download and run Pynt CLI</strong> by executing the
            following Python commands from the cmd:
          </Typography>

          <Stack direction={'column'} spacing={1} pb={1}>
            <Box sx={COPY_BOX}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography sx={{ color: '#6da6ea' }}>
                  pip install pyntcli
                </Typography>
                <IconButton
                  onClick={() => {
                    track('onboarding-3-copy');
                    navigator.clipboard.writeText('pip install pyntcli');
                    show('Copied to clipboard');
                  }}
                >
                  <ContentCopy sx={{ color: BRAND_ORANGE }} />
                </IconButton>
              </Stack>
            </Box>
            <Box sx={COPY_BOX}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography sx={{ color: '#6da6ea' }}>pynt postman</Typography>
                <IconButton
                  onClick={() => {
                    track('onboarding-3-copy');
                    navigator.clipboard.writeText('pynt postman');
                    show('Copied to clipboard');
                  }}
                >
                  <ContentCopy sx={{ color: BRAND_ORANGE }} />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
          <Stack direction={'row'} gap={3}>
            <Typography sx={TEXT}>
              <strong>2. Open Postman App</strong>
            </Typography>
            <Typography sx={TEXT}>
              <a
                href="https://www.postman.com/downloads/"
                target="_blank"
                style={LINK}
                rel="noreferrer"
                onClick={() => {
                  track('onboarding-3-no-postman-app');
                }}
              >
                I don&apos;t have the postman app
              </a>
            </Typography>
          </Stack>
          <Stack direction={'row'} gap={3}>
            <Typography sx={TEXT}>
              <strong>3. Run Pynt collection from your workspace</strong>
            </Typography>
            <Typography sx={TEXT}>
              <a
                href="https://global-uploads.webflow.com/6357c772bd0a59790d6cfdf5/63a3fe31c8c1bd279fe5db41_how_run_it.png"
                target="_blank"
                style={LINK}
                rel="noreferrer"
                onClick={() => {
                  track('onboarding-3-how-to-run');
                }}
              >
                How do I run it?
              </a>
            </Typography>
          </Stack>
          <Typography sx={MEDIUM_TEXT} textAlign={'start'} pt={2}>
            Once completed, you have successfully onboarded to Pynt!
          </Typography>
          <Stack>
            <Typography sx={SMALL_TEXT} textAlign={'start'} pt={2}>
              To continue using Pynt, proceed with Pynt collection
              documentation.{' '}
              <a
                href="https://global-uploads.webflow.com/6357c772bd0a59790d6cfdf5/63a3fe314cbb3c17a1c15178_where_find_it.png"
                target="_blank"
                rel="noreferrer"
                style={LINK}
                onClick={() => {
                  track('onboarding-3-where-to-find');
                }}
              >
                where I can find it?
              </a>
            </Typography>
            <Typography sx={SMALL_TEXT} textAlign={'start'}>
              For any issues, please contact{' '}
              <a href="mailto:support@pynt.io" style={LINK}>
                support@pynt.io
              </a>{' '}
              or join our{' '}
              <a
                href="https://www.pynt.io/community"
                target="_blank"
                rel="noreferrer"
                style={LINK}
                onClick={() => {
                  track('onboarding-3-slack');
                }}
              >
                slack community
              </a>
            </Typography>
          </Stack>
        </Stack>

        <Box height={48} />
        <Stack direction={'row'} justifyContent={'end'} gap={2}>
          <LoadingButton
            variant="contained"
            sx={SUBMIT_BUTTON}
            onClick={handlePrevClick}
            startIcon={<KeyboardArrowLeftRounded />}
          >
            Previous
          </LoadingButton>
          <LoadingButton
            variant="contained"
            sx={SUBMIT_BUTTON}
            onClick={handleNextClick}
          >
            Finish
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PostmanOnboardRun;
