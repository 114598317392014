import { Close } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import pyntIcon from '../assets/images/pyntIcon.png';
import slackIcon from '../assets/images/slackIcon.png';
import { DocumentIcon } from '../assets/svgs/icons/DocumentIcon';
import { ProfileIcon2 } from '../assets/svgs/icons/ProfileIcon2';
import { QuesionMarkCircleIcon } from '../assets/svgs/icons/QuesionMarkCircleIcon';
import { track } from '../utils/analytics';

interface Props {
  open: boolean;
  onClose: () => void;
}

const links = [
  {
    icon: <img src={pyntIcon} height={20} width={20} />,
    title: 'Quickstart',
    id: 'quickstart',
  },
  {
    icon: <img src={slackIcon} height={20} width={20} />,
    title: 'Join Slack community',
    id: 'join-slack',
    link: 'https://www.pynt.io/community',
  },
  {
    icon: <ProfileIcon2 size={20} />,
    title: 'Contact support',
    id: 'contact-support',
    link: 'mailto:support@pynt.io',
  },
  {
    icon: <QuesionMarkCircleIcon size={20} />,
    title: 'FAQ',
    id: 'faq',
    link: 'https://docs.pynt.io/documentation/security-testing/troubleshooting/pynt-postman-troubleshooting',
  },
  {
    icon: <DocumentIcon size={20} />,
    title: 'View documentation',
    id: 'view-documentation',
    link: 'https://docs.pynt.io/documentation/intro/what-is-pynt',
  },
  {
    icon: <DocumentIcon size={20} />,
    title: 'Privacy policy',
    id: 'privacy-policy',
    link: 'https://www.pynt.io/privacy-policy',
  },
  {
    icon: <DocumentIcon size={20} />,
    title: 'Terms of use',
    id: 'terms-of-use',
    link: 'https://www.pynt.io/terms-of-service',
  },
];

export default function HelpDialog({ open, onClose }: Props) {
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { overflowX: 'hidden' } }}
    >
      <DialogTitle id="alert-dialog-title">Help</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ pt: 0, mx: -2, px: 4, width: 320 }}>
        <Stack direction={'column'} gap={1}>
          {links.map((link) => (
            <Button
              key={link.id}
              variant="text"
              color="secondary"
              size="large"
              onClick={() => {
                track('web_app_help_dialog_button_click', {
                  button: link.id,
                  link: link.link,
                });

                if (link.id === 'quickstart') {
                  navigate('/dashboard/instructions');
                  onClose();
                  return;
                }

                window.open(link.link, '_blank');
              }}
            >
              <Stack
                direction={'row'}
                gap={2}
                justifyContent={'start'}
                alignItems={'center'}
                width={'100%'}
              >
                {link.icon}
                <Typography>{link.title}</Typography>
              </Stack>
            </Button>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
