import { SvgIconProps } from './SvgIconProps';

export const LinkIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.2513 10.0007C3.2513 8.57565 4.40964 7.41732 5.83464 7.41732H9.16797V5.83398H5.83464C3.53464 5.83398 1.66797 7.70065 1.66797 10.0007C1.66797 12.3007 3.53464 14.1673 5.83464 14.1673H9.16797V12.584H5.83464C4.40964 12.584 3.2513 11.4257 3.2513 10.0007ZM6.66797 10.834H13.3346V9.16732H6.66797V10.834ZM14.168 5.83398H10.8346V7.41732H14.168C15.593 7.41732 16.7513 8.57565 16.7513 10.0007C16.7513 11.4257 15.593 12.584 14.168 12.584H10.8346V14.1673H14.168C16.468 14.1673 18.3346 12.3007 18.3346 10.0007C18.3346 7.70065 16.468 5.83398 14.168 5.83398Z"
        fill={color}
      />
    </svg>
  );
};
