import { useCallback, useContext, useMemo, useState } from 'react';
import { AuthContext } from '../../contexts/Auth';
import { emailIsValid } from '../../utils/validator';
import { Box, Stack, SxProps, TextField, Typography } from '@mui/material';
import TextFieldTitle from './TextFieldTitle';
import { LoadingButton } from '@mui/lab';

const SSO_BOX: SxProps = {
  minHeight: 320,
  maxWidth: 500,
  justifyContent: 'space-between',
};

const TITLE: SxProps = {
  fontSize: 32,
  fontWeight: 700,
};

const SUBTITLE: SxProps = {
  fontSize: 16,
};

const ERROR_BOX: SxProps = {
  padding: 2,
  fontSize: 14,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'error.light',
  borderRadius: '5px',
  color: 'error.dark',
  backgroundColor: 'error.lighter',
};

const ACTIONABLE_LINK: SxProps = {
  fontSize: 14,
  color: 'primary.main',
  textDecoration: 'underline',
  cursor: 'pointer',
};

interface Props {
  clickedBack: () => void;
}

const SSOLoginForm = ({ clickedBack }: Props) => {
  const { loginWithSSO, discoverSSO } = useContext(AuthContext);

  const [email, setEmail] = useState<string>('');
  const [loadingEmailLogin, setLoadingEmailLogin] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const canSubmit = useMemo(() => emailIsValid(email), [email]);

  const processEmail = useCallback(
    async (email: string) => {
      setLoadingEmailLogin(true);
      setShowError(false);

      try {
        const provider = await discoverSSO(email);
        loginWithSSO(provider);
      } catch (_) {
        setShowError(true);
        setLoadingEmailLogin(false);
      }
    },
    [setLoadingEmailLogin, setShowError, discoverSSO, loginWithSSO],
  );

  return (
    <Box>
      <Stack direction={'column'} sx={SSO_BOX}>
        <Stack direction={'column'} spacing={2}>
          <Stack direction={'column'} spacing={1}>
            <Typography sx={TITLE}>Single sign-on</Typography>
            <Typography sx={SUBTITLE}>
              Single sign-on allows you to log in to Pynt using your
              organization&apos;s identity provider.
            </Typography>
          </Stack>
          {showError && (
            <Box sx={ERROR_BOX}>
              This email is not associated with an SSO account or there is no
              organization associated with this email domain.
            </Box>
          )}
        </Stack>
        <Stack direction={'column'} spacing={1}>
          <Stack
            direction={'column'}
            spacing={0}
            justifySelf={'bottom'}
            alignSelf={'center'}
            justifyContent={'start'}
            alignItems={'start'}
          >
            <TextFieldTitle title="Work email" />
            <Stack
              direction={'row'}
              spacing={2}
              alignSelf={'start'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <TextField
                size="small"
                variant="filled"
                disabled={loadingEmailLogin}
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && processEmail(email)}
                inputRef={(input) => input && input.focus()}
              />
              <LoadingButton
                loading={loadingEmailLogin}
                onClick={() => processEmail(email)}
                disabled={!canSubmit}
                variant="contained"
              >
                Log in
              </LoadingButton>
            </Stack>
          </Stack>
          <Typography sx={ACTIONABLE_LINK} onClick={clickedBack}>
            Sign in without SSO
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SSOLoginForm;
