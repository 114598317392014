import { SvgIconProps } from './SvgIconProps';

export const CheckIcon = ({ color = 'white', size = 22 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={(size * 22) / 16}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 2C21.5 2.42188 21.3125 2.79688 21.0312 3.07812L9.03125 15.0781C8.75 15.3594 8.375 15.5 8 15.5C7.57812 15.5 7.20312 15.3594 6.92188 15.0781L0.921875 9.07812C0.640625 8.79688 0.5 8.42188 0.5 8C0.5 7.15625 1.15625 6.5 2 6.5C2.375 6.5 2.75 6.6875 3.03125 6.96875L8 11.8906L18.9219 0.96875C19.2031 0.6875 19.5781 0.5 20 0.5C20.7969 0.5 21.5 1.15625 21.5 2Z"
        fill={color}
      />
    </svg>
  );
};
