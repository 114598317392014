import { SvgIconProps } from './SvgIconProps';

export const ScanIcon2 = ({ color = '#ACACBB', size = 16 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33301 6.50016C1.05967 6.50016 0.833008 6.2735 0.833008 6.00016V4.3335C0.833008 2.40016 2.40634 0.833496 4.33301 0.833496H5.99967C6.27301 0.833496 6.49967 1.06016 6.49967 1.3335C6.49967 1.60683 6.27301 1.8335 5.99967 1.8335H4.33301C2.95301 1.8335 1.83301 2.9535 1.83301 4.3335V6.00016C1.83301 6.2735 1.60634 6.50016 1.33301 6.50016Z"
        fill={color}
      />
      <path
        d="M14.6667 6.50016C14.3933 6.50016 14.1667 6.2735 14.1667 6.00016V4.3335C14.1667 2.9535 13.0467 1.8335 11.6667 1.8335H10C9.72667 1.8335 9.5 1.60683 9.5 1.3335C9.5 1.06016 9.72667 0.833496 10 0.833496H11.6667C13.5933 0.833496 15.1667 2.40016 15.1667 4.3335V6.00016C15.1667 6.2735 14.94 6.50016 14.6667 6.50016Z"
        fill={color}
      />
      <path
        d="M11.667 15.1665H10.667C10.3937 15.1665 10.167 14.9398 10.167 14.6665C10.167 14.3932 10.3937 14.1665 10.667 14.1665H11.667C13.047 14.1665 14.167 13.0465 14.167 11.6665V10.6665C14.167 10.3932 14.3937 10.1665 14.667 10.1665C14.9403 10.1665 15.167 10.3932 15.167 10.6665V11.6665C15.167 13.5998 13.5937 15.1665 11.667 15.1665Z"
        fill={color}
      />
      <path
        d="M5.99967 15.1667H4.33301C2.40634 15.1667 0.833008 13.6 0.833008 11.6667V10C0.833008 9.72667 1.05967 9.5 1.33301 9.5C1.60634 9.5 1.83301 9.72667 1.83301 10V11.6667C1.83301 13.0467 2.95301 14.1667 4.33301 14.1667H5.99967C6.27301 14.1667 6.49967 14.3933 6.49967 14.6667C6.49967 14.94 6.27301 15.1667 5.99967 15.1667Z"
        fill={color}
      />
      <path
        d="M9.33366 12.1668H6.66699C5.05366 12.1668 4.16699 11.2802 4.16699 9.66683V6.3335C4.16699 4.72016 5.05366 3.8335 6.66699 3.8335H9.33366C10.947 3.8335 11.8337 4.72016 11.8337 6.3335V9.66683C11.8337 11.2802 10.947 12.1668 9.33366 12.1668ZM6.66699 4.8335C5.61366 4.8335 5.16699 5.28016 5.16699 6.3335V9.66683C5.16699 10.7202 5.61366 11.1668 6.66699 11.1668H9.33366C10.387 11.1668 10.8337 10.7202 10.8337 9.66683V6.3335C10.8337 5.28016 10.387 4.8335 9.33366 4.8335H6.66699Z"
        fill={color}
      />
      <path
        d="M12.6663 8.5H3.33301C3.05967 8.5 2.83301 8.27333 2.83301 8C2.83301 7.72667 3.05967 7.5 3.33301 7.5H12.6663C12.9397 7.5 13.1663 7.72667 13.1663 8C13.1663 8.27333 12.9397 8.5 12.6663 8.5Z"
        fill={color}
      />
    </svg>
  );
};
