import { SxProps, Typography } from '@mui/material';

const TEXT: SxProps = {
  color: '#7B7B88', // DARK_GRAY,
  fontWeight: 500,
  fontSize: 12,
  letterSpacing: '-0.3 px',
};

const PAD_BOTTOM: SxProps = {
  pb: 1,
};

interface Props {
  title: string;
}

const TextFieldTitle = ({ title }: Props) => {
  return (
    <Typography variant="body2" sx={[TEXT, PAD_BOTTOM]}>
      {title}
    </Typography>
  );
};

export default TextFieldTitle;
