import { Stack, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import postmanOnboardMask from '../../assets/images/postmanOnboardMask.png';
import seleniumOnboardIcon from '../../assets/images/seleniumOnboardIcon.png';
import { LightLogo } from '../../assets/svgs/LightLogo';
import { analyticsIdentify } from '../../utils/analytics';
import { SeleniumOnboardStepData } from './SeleniumOnboardStep';
import SeleniumOnboardSteps from './SeleniumOnboardSteps';

const CONTAINER: SxProps = {
  bgcolor: '#050d21',
  height: '100vh',
  overflow: 'hidden',
};

const APP_BAR: SxProps = {
  bgcolor: '#050d21',
  height: '108px',
  minHeight: '108px',
};

const STEPS_CONTAINER: SxProps = {
  width: '40vw',
  minWidth: '400px',
  backgroundImage: `url(${postmanOnboardMask})`,
  backgroundSize: 'cover',
};

const OUTLET_CONTAINER: SxProps = {
  overflow: 'scroll',
};

const steps: SeleniumOnboardStepData[] = [
  { path: '/login', name: 'Signup' },
  { path: '/setup', name: 'Integrate Pynt\nwith Selenium' },
  { path: '/run', name: 'Run Pynt from\nSelenium App' },
];

export default function SeleniumOnboardWrapper() {
  const location = useLocation();
  const navigate = useNavigate();

  const activeMenuItemIndex = useMemo(
    () => steps.findIndex((step) => location.pathname.endsWith(step.path)),
    [location],
  );

  useEffect(() => {
    if (
      !sessionStorage.getItem('seleniumAuth') &&
      !location.pathname.endsWith('login')
    ) {
      navigate('/onboard/selenium/login');
    }
  }, [location]);

  useEffect(() => {
    const seleniumAuth = sessionStorage.getItem('seleniumAuth');
    if (!seleniumAuth) return;

    const token = JSON.parse(seleniumAuth);
    const userId = token.userId;
    const email = token.email;

    if (userId && typeof userId === 'string') {
      analyticsIdentify(userId, email || undefined);
    }
  }, []);

  return (
    <Stack direction={'column'} sx={CONTAINER}>
      <Stack sx={APP_BAR} justifyContent={'center'} px={10}>
        <LightLogo height={70} />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'stretch'}
        sx={{ height: 'calc(100% - 108px)' }}
      >
        <Stack
          sx={STEPS_CONTAINER}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          pt={5}
        >
          <SeleniumOnboardSteps
            currentStep={activeMenuItemIndex}
            steps={steps}
          />
          <img src={seleniumOnboardIcon} width={350} />
        </Stack>
        <Box component="main" sx={OUTLET_CONTAINER} flex={1}>
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
}
