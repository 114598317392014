import { useNavigate } from 'react-router-dom';
import { track } from '../../utils/analytics';
import ApplicationsRiskScoreGrid from '../ApplicationsRiskScore/ApplicationsRiskScoreGrid';
import ViewAllButton from '../Common/ViewAllButton';
import CustomCard from '../CustomCard/CustomCard';

const ApplicationsRiskScore = () => {
  const navigate = useNavigate();

  return (
    <CustomCard
      title="Application Risk Score"
      action={
        <ViewAllButton
          onClick={() => {
            track(
              'web_app_overview_applications_risk_score_view_all_button_click',
            );
            navigate('/dashboard/applications');
          }}
        />
      }
      sx={{ height: 300 }}
    >
      <ApplicationsRiskScoreGrid
        id={'ApplicationsRiskScoreGrid'}
        autoHeight={false}
        autoPageSize={true}
        hideFooter
        pagination={true}
        sorting={[{ field: 'risk', sort: 'desc' }]}
        filter={{
          items: [
            {
              id: 1,
              field: 'risk',
              operator: 'isAnyOf',
              value: ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'],
            },
          ],
        }}
        limit={3}
      />
    </CustomCard>
  );
};

export default ApplicationsRiskScore;
