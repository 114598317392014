import { Box, Stack, SxProps } from '@mui/material';
import PostmanOnboardStep, {
  PostmanOnboardStepData,
} from './PostmanOnboardStep';

const VERTICAL_LINE_CONTAINER: SxProps = {
  width: 50,
  display: 'grid',
  alignItems: 'stretch',
  justifyContent: 'center',
};

const VERTICAL_LINE: (index: number) => SxProps = (index) => ({
  width: 4,
  backgroundColor: index % 2 === 0 ? '#040C1F' : '#0059F733',
  minHeight: 50,
  height: '100%',
});

interface Props {
  currentStep: number;
  steps: PostmanOnboardStepData[];
}

export default function PostmanOnboardSteps({ currentStep, steps }: Props) {
  return (
    <Stack direction="column" height={'100%'} maxHeight={'600px'}>
      {steps.map((step, index) => (
        <Stack
          key={step.name}
          direction="column"
          alignItems={'start'}
          flex={index + 1 < steps.length ? 1 : 0}
        >
          <PostmanOnboardStep
            step={step}
            index={index}
            selected={index === currentStep}
            passed={currentStep > index}
          />
          {index + 1 < steps.length ? (
            <Box flex={1} sx={VERTICAL_LINE_CONTAINER}>
              <Box sx={VERTICAL_LINE(index)} />
            </Box>
          ) : (
            <></>
          )}
        </Stack>
      ))}
    </Stack>
  );
}
