import axios, { AxiosRequestConfig } from 'axios';
import { track } from '../utils/analytics';

const POSTMAN_KEY_HEADER = 'X-API-Key';
export const apiKeySessionStorageKey = 'postmanApiKeySessionStorageKey';
const postmanBaseRoute = '/postman-proxy';
export const pyntCollectionUid =
  '21132333-8064f672-29e5-4d7f-bef4-3004cd6dd636';
const getWorkspacesGeneralErrorMessage = 'Failed to retrieve workspaces.';
const getCollectionsGeneralErrorMessage = 'Failed to retrieve collections.';
const addVariablesToCollectionGeneralErrorMessage =
  'Failed to add variables to collection.';

const BASE = process.env.REACT_APP_PYNT_API_BASE_URL;

const instance = axios.create({
  baseURL: BASE + postmanBaseRoute,
  headers: {
    'Content-type': 'application/json',
  },
});

async function request(path: string, config?: AxiosRequestConfig) {
  if (!config) config = {};
  if (!config.headers) config.headers = {};

  if (!config.headers[POSTMAN_KEY_HEADER]) {
    config.headers[POSTMAN_KEY_HEADER] = sessionStorage.getItem(
      apiKeySessionStorageKey,
    );
  }
  return instance(path, {
    ...config,
  });
}

async function forkCollection(
  label: string,
  destinationWorkspace: string,
  apiKey?: string,
) {
  const res = await request(
    `/collections/fork/${pyntCollectionUid}?workspace=${destinationWorkspace}`,
    {
      method: 'post',
      data: JSON.stringify({ label }),
      headers: apiKey ? { [POSTMAN_KEY_HEADER]: apiKey } : undefined,
    },
  );

  return res.data;
}

async function getCollection(collectionId: string, apiKey?: string) {
  const res = await request(`/collections/${collectionId}`, {
    method: 'get',
    headers: apiKey ? { [POSTMAN_KEY_HEADER]: apiKey } : undefined,
  });

  return res.data;
}

async function putCollection(
  collectionId: string,
  collection: any,
  apiKey?: string,
) {
  const res = await request(`/collections/${collectionId}`, {
    method: 'put',
    data: JSON.stringify(collection),
    headers: apiKey ? { [POSTMAN_KEY_HEADER]: apiKey } : undefined,
  });

  return res.data;
}

export async function addVariablesToCollection(
  destinationCollectionUid: string,
  destinationWorkspace: string,
  appId?: string,
  apiKey?: string,
) {
  const newCollection = await forkCollection(
    'Pynt - Security tests',
    destinationWorkspace,
    apiKey,
  ).catch((e) => {
    const message =
      e.response?.data?.error?.message ||
      addVariablesToCollectionGeneralErrorMessage;
    track('onboarding-fork-collection-failure', {
      errorMessage: message,
    });
    alert(message);

    throw e;
  });

  track('onboarding-fork-collection-success');

  const newCollectionId = newCollection['collection']['id'];
  const parsedCollection = await getCollection(newCollectionId, apiKey).catch(
    (e) => {
      const message =
        e.response?.data?.error?.message ||
        addVariablesToCollectionGeneralErrorMessage;

      track('onboarding-get-collection-failure', {
        errorMessage: message,
      });

      alert(message);

      throw e;
    },
  );

  const _apiKey = apiKey || sessionStorage.getItem(apiKeySessionStorageKey);

  if (!_apiKey) {
    const message = 'No valid api key.';
    track('onboarding-invalid-api-key', {
      errorMessage: message,
    });
    alert(message);
    throw message;
  }

  track('onboarding-get-collection-success');

  parsedCollection['collection']['variable'].forEach(
    (variable: any, index: number) => {
      if (variable['key'] === 'API-KEY') {
        parsedCollection['collection']['variable'][index]['value'] = _apiKey;
      }
      if (variable['key'] === 'YOUR-COLLECTION') {
        parsedCollection['collection']['variable'][index]['value'] =
          destinationCollectionUid;
      }
      if (appId && variable['key'] === 'PYNT-ENTERPRISE-APP-ID') {
        parsedCollection['collection']['variable'][index]['value'] = appId;
      }
    },
  );

  const putRes = await putCollection(
    newCollectionId,
    parsedCollection,
    apiKey,
  ).catch((e) => {
    const message =
      e.response?.data?.error?.message ||
      addVariablesToCollectionGeneralErrorMessage;

    track('onboarding-create-new-collection-failure', {
      errorMessage: message,
    });

    alert(message);

    throw e;
  });

  track('onboarding-create-new-collection-success');

  return putRes;
}

export async function getCollectionsInWorkspace(
  workspaceId: string,
  apiKey?: string,
) {
  const res = await request(`/workspaces/${workspaceId}`, {
    method: 'get',
    headers: apiKey ? { [POSTMAN_KEY_HEADER]: apiKey } : undefined,
  });

  const collectionsResponse = res.data;

  if (!collectionsResponse) {
    const message = getCollectionsGeneralErrorMessage;
    track('onboarding-get-collections-in-workspace-failure', {
      errorMessage: message,
    });

    alert(message);

    return null;
  }

  const { error, workspace } = collectionsResponse;

  if (error) {
    const message = error.message || getCollectionsGeneralErrorMessage;
    track('onboarding-get-collections-in-workspace-failure', {
      errorMessage: message,
    });

    alert(message);

    return null;
  }

  track('onboarding-get-collections-in-workspace-success');

  return workspace.collections?.map((c: any) => ({ ...c, workspaceId })) || [];
}

export async function getEnviromentsInWorkspace(
  workspaceId: string,
  apiKey?: string,
) {
  const res = await request('/environments', {
    method: 'get',
    params: { workspace: workspaceId },
    headers: apiKey ? { [POSTMAN_KEY_HEADER]: apiKey } : undefined,
  });

  const environmentsResponse = res.data;

  if (!environmentsResponse) {
    const message = getCollectionsGeneralErrorMessage;
    track('onboarding-get-environments-in-workspace-failure', {
      errorMessage: message,
    });

    alert(message);

    return null;
  }

  const { error, environments } = environmentsResponse;

  if (error) {
    const message = error.message || getCollectionsGeneralErrorMessage;
    track('onboarding-get-environments-in-workspace-failure', {
      errorMessage: message,
    });

    alert(message);

    return null;
  }

  track('onboarding-get-environments-in-workspace-success');

  return environments || [];
}

export async function getAllWorkspaces(apiKey?: string) {
  const res = await request('/workspaces', {
    method: 'get',
    headers: apiKey ? { [POSTMAN_KEY_HEADER]: apiKey } : undefined,
  });

  const workspacesResponse = res.data;

  if (!workspacesResponse) {
    const message = getWorkspacesGeneralErrorMessage;
    track('onboarding-get-all-workspaces-failure', {
      errorMessage: message,
    });

    alert(message);

    return null;
  }

  const { error, workspaces } = workspacesResponse;

  if (error) {
    const message = error?.message || getWorkspacesGeneralErrorMessage;

    if (error?.name === 'AuthenticationError') {
      track('onboarding-invalid-api-key', {
        errorMessage: message,
      });
    } else {
      track('onboarding-get-all-workspaces-failure', {
        errorMessage: message,
      });
    }

    alert(message);
    return null;
  }

  if (!workspaces) {
    const message =
      // eslint-disable-next-line quotes
      "You don't have any workspaces. Please create a workspace and try again.";
    track('onboarding-user-has-no-workspaces', {
      errorMessage: message,
    });

    alert(message);
    return null;
  } else {
    track('onboarding-get-all-workspaces-success');

    return workspaces;
  }
}
