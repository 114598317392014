interface Props {
  width?: number;
  height?: number;
}

export const DarkLogo = ({ height = 48, width = 120 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 94 38"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.7444 13.1109C8.7444 13.6876 8.569 14.2513 8.24039 14.7309C7.91177 15.2104 7.44469 15.5841 6.89822 15.8048C6.35175 16.0255 5.75043 16.0833 5.1703 15.9707C4.59017 15.8582 4.05729 15.5805 3.63904 15.1727C3.22079 14.7649 2.93596 14.2454 2.82057 13.6797C2.70517 13.1141 2.7644 12.5278 2.99075 11.995C3.21711 11.4622 3.60043 11.0068 4.09224 10.6864C4.58405 10.366 5.16226 10.195 5.75375 10.195C6.14678 10.194 6.53614 10.2687 6.89944 10.4149C7.26275 10.5611 7.59284 10.7758 7.87076 11.0468C8.14867 11.3178 8.36892 11.6396 8.51885 11.9938C8.66878 12.3481 8.74543 12.7277 8.7444 13.1109M11.5075 13.1109C11.5075 11.623 10.9013 10.1961 9.82227 9.14408C8.74323 8.09202 7.27974 7.50098 5.75375 7.50098C4.22776 7.50098 2.76427 8.09202 1.68524 9.14408C0.606198 10.1961 0 11.623 0 13.1109V22.8252H2.7631V17.8967C3.63451 18.4168 4.63242 18.7009 5.65396 18.7198C6.6755 18.7386 7.6837 18.4915 8.57465 18.0038C9.4656 17.5162 10.2071 16.8058 10.7226 15.9457C11.2381 15.0856 11.5091 14.1071 11.5075 13.1109"
        fill="black"
      />
      <path
        d="M22.283 7.72363H19.5199L18.1708 12.6521L16.8543 17.438C16.8543 17.4538 16.838 17.4697 16.838 17.4855L13.5873 7.72363H10.8242L14.4163 18.4997H16.3667C15.6353 19.5932 14.335 20.3697 13.0672 20.3697C13.0022 20.3697 12.9534 20.3539 12.9047 20.3539V23.032C15.8953 22.7626 18.8372 20.3539 19.6336 17.438L22.283 7.72363Z"
        fill="black"
      />
      <path
        d="M33.32 18.4989V13.1109C33.32 11.623 32.7138 10.1961 31.6348 9.14408C30.5557 8.09202 29.0922 7.50098 27.5663 7.50098C26.0403 7.50098 24.5768 8.09202 23.4977 9.14408C22.4187 10.1961 21.8125 11.623 21.8125 13.1109V18.4989H24.5756V13.1109C24.5756 12.3375 24.8907 11.5959 25.4515 11.049C26.0124 10.5022 26.7731 10.195 27.5663 10.195C28.3594 10.195 29.1201 10.5022 29.681 11.049C30.2418 11.5959 30.5569 12.3375 30.5569 13.1109V18.4989H33.32Z"
        fill="black"
      />
      <path
        d="M36.8139 13.111V10.417H39.9996V7.72299H36.8139V3.38086H34.0508V13.111C34.0508 14.5989 34.657 16.0258 35.736 17.0778C36.8151 18.1299 38.2785 18.7209 39.8045 18.7209C39.8646 18.7243 39.9249 18.719 39.9833 18.7051V15.9952C39.9183 15.9952 39.8695 16.0111 39.8045 16.0111C39.4127 16.0125 39.0245 15.9386 38.662 15.7936C38.2996 15.6486 37.97 15.4353 37.6922 15.1659C37.4144 14.8965 37.1939 14.5764 37.0432 14.2238C36.8924 13.8712 36.8145 13.493 36.8139 13.111Z"
        fill="black"
      />
      <path
        d="M0.632812 2.90565L29.0264 0.549805C30.4265 0.549805 31.562 1.60459 31.562 2.90565C31.562 4.2067 30.427 5.26149 29.0264 5.26149L0.632812 3.37695V2.90565Z"
        fill="#FB813A"
      />
    </svg>
  );
};
