import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';

export default function SelectVulnerabilityStatus({
  selectedVulnerabilityStatus,
  setSelectedVulnerabilityStatus,
}: {
  selectedVulnerabilityStatus: string | undefined;
  setSelectedVulnerabilityStatus: any;
}) {
  const [inputValue, setInputValue] = React.useState<string>();

  const handleInputChange = (event: SelectChangeEvent) => {
    setInputValue(event.target.value);
  };

  React.useEffect(() => {
    if (!inputValue) return;

    const timeoutId = setTimeout(() => {
      setSelectedVulnerabilityStatus(inputValue);
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  return (
    <Box sx={{ minWidth: 220 }}>
      <FormControl fullWidth>
        <Select
          value={selectedVulnerabilityStatus}
          variant="filled"
          onChange={handleInputChange}
          size="small"
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: 4,
                borderRadius: 6,
                textAlign: 'start',
                backgroundColor: 'white',
                border: 'solid 1px #D3D3D3',
                boxShadow: 'none',
              },
            },
          }}
        >
          <MenuItem value={'*'}>All statuses</MenuItem>
          <MenuItem value={'detected'}>Detected</MenuItem>
          <MenuItem value={'waived'}>Waived</MenuItem>
          <MenuItem value={'false_positive_claimed'}>
            False positive claimed
          </MenuItem>
          <MenuItem value={'resolved'}>Resolved</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
