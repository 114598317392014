import { SvgIconProps } from './SvgIconProps';

export const CatalogIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9583 5.00008C18.9583 3.95008 18.5416 2.94341 17.7991 2.20091C17.0566 1.45841 16.05 1.04175 15 1.04175H4.99996C3.94996 1.04175 2.94329 1.45841 2.20079 2.20091C1.45829 2.94341 1.04163 3.95008 1.04163 5.00008V15.0001C1.04163 16.0501 1.45829 17.0567 2.20079 17.7992C2.94329 18.5417 3.94996 18.9584 4.99996 18.9584H15C16.05 18.9584 17.0566 18.5417 17.7991 17.7992C18.5416 17.0567 18.9583 16.0501 18.9583 15.0001V5.00008ZM17.7083 5.00008V15.0001C17.7083 15.7184 17.4233 16.4076 16.915 16.9151C16.4075 17.4234 15.7183 17.7084 15 17.7084H4.99996C4.28163 17.7084 3.59246 17.4234 3.08496 16.9151C2.57663 16.4076 2.29163 15.7184 2.29163 15.0001V5.00008C2.29163 4.28175 2.57663 3.59258 3.08496 3.08508C3.59246 2.57675 4.28163 2.29175 4.99996 2.29175H15C15.7183 2.29175 16.4075 2.57675 16.915 3.08508C17.4233 3.59258 17.7083 4.28175 17.7083 5.00008Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4066 6.80246L8.40663 12.8025C8.2983 13.13 8.47496 13.4841 8.80246 13.5933C9.12996 13.7016 9.48413 13.525 9.5933 13.1975L11.5933 7.19746C11.7016 6.86996 11.525 6.5158 11.1975 6.40663C10.87 6.2983 10.5158 6.47496 10.4066 6.80246Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5582 8.4417L14.1157 10L12.5582 11.5584C12.3141 11.8017 12.3141 12.1984 12.5582 12.4417C12.8016 12.6859 13.1982 12.6859 13.4416 12.4417L15.4416 10.4417C15.6857 10.1975 15.6857 9.80254 15.4416 9.55837L13.4416 7.55837C13.1982 7.3142 12.8016 7.3142 12.5582 7.55837C12.3141 7.8017 12.3141 8.19837 12.5582 8.4417Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55825 7.55837L4.55825 9.55837C4.31408 9.80254 4.31408 10.1975 4.55825 10.4417L6.55825 12.4417C6.80158 12.6859 7.19825 12.6859 7.44158 12.4417C7.68575 12.1984 7.68575 11.8017 7.44158 11.5584L5.88408 10L7.44158 8.4417C7.68575 8.19837 7.68575 7.8017 7.44158 7.55837C7.19825 7.3142 6.80158 7.3142 6.55825 7.55837Z"
        fill={color}
      />
    </svg>
  );
};
