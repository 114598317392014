import { Components, Theme } from '@mui/material';

export default function themedMuiPaper(
  theme: Theme,
): Pick<Components<Theme>, 'MuiPaper'> {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          textAlign: 'start',
          backgroundColor: 'white',
          border: 'solid 1px #D3D3D3',
          boxShadow: '0px 0px 4px 0px #0000001A',
        },
      },
    },
  };
}
