import { Box, Tooltip } from '@mui/material';
import {
  GridColDef,
  GridColumnsInitialState,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import * as Sentry from '@sentry/react';
import { track } from 'mixpanel-browser';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BRAND_DARK_BLUE, BRAND_ORANGE } from '../../constants/colors';
import {
  Application,
  UserContext,
  UserContextProps,
} from '../../contexts/User';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import { APICatalogRisk } from '../../models/apiCatalog';
import {
  PyntFilter,
  getApplications,
  getApplicationsCount,
} from '../../services/ApplicationsService';
import { reformatFilterModel, reformatSortModel } from '../../utils/datagrid';
import RiskScoreChip, { normalizeRiskScore } from '../APICatalog/RiskScoreChip';
import NumberChipIndicator from '../Common/NumberChipIndicator';
import PyntDataGrid from '../Common/PyntDataGrid';

export const RISK_SCORE_MAP: any = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
  CRITICAL: 4,
};

interface Props {
  id: string;
  filter?: GridFilterModel;
  hideFooter?: boolean;
  paginationModel?: Partial<GridPaginationModel>;
  autoHeight?: boolean;
  autoPageSize?: boolean;
  pagination?: boolean;
  limit?: number | undefined;
  sorting?: GridSortModel;
}

export default function ApplicationsRiskScoreGrid({
  id,
  filter,
  hideFooter,
  paginationModel,
  autoHeight = true,
  autoPageSize = false,
  pagination = true,
  limit,
  sorting = [],
}: Props) {
  const navigate = useNavigate();
  const { setSelectedApplicationId } = useContext(
    UserContext,
  ) as UserContextProps;

  const [applications, setApplications] = useState<Application[]>();
  const [isLoadingApplications, setIsLoadingApplications] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>(sorting);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [count, setCount] = useState<number>();

  useEffect(() => {
    const storedApplications = localStorage.getItem(`applications-${id}`);
    if (storedApplications) {
      try {
        const parsedApplications = JSON.parse(storedApplications);
        setApplications(parsedApplications);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const fetchApplications = useCallback(async () => {
    if (isLoadingApplications) return;

    setIsLoadingApplications(true);

    const filter: PyntFilter = {
      where: reformatFilterModel(filterModel, columns),
      sort: reformatSortModel(sortModel, columns),
      offset: page * pageSize,
      limit: limit ? limit : pageSize,
    };

    const applications = await getApplications(filter).catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });

    setApplications(applications);
    setIsLoadingApplications(false);

    const applicationsCount = await getApplicationsCount(filter.where).catch(
      (e) => {
        console.error(e);
        Sentry.captureException(e);
      },
    );

    setCount(applicationsCount?.count);

    if (page === 0) {
      if (applications) {
        localStorage.setItem(
          `applications-${id}`,
          JSON.stringify(applications),
        );
      } else {
        localStorage.removeItem(`applications-${id}`);
      }
    }

    return applications;
  }, [isLoadingApplications, filterModel, sortModel, page, pageSize]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchApplications();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterModel, sortModel, page, pageSize]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Application',
        width: 140,
        type: 'string',
      },
      {
        field: 'sources',
        headerName: 'Sources',
        type: 'number',
        align: 'center',
        width: 140,
        valueGetter: ({ row }) => {
          return Object.entries(row.data_sources || {}).filter(
            ([_, v]: any) => !!v.source_id,
          ).length;
        },
        headerAlign: 'center',
        renderCell: ({ value }) => (
          <NumberChipIndicator
            value={value}
            color={'#FBFCFF'}
            labelColor={BRAND_DARK_BLUE}
            borderColor={'#F5F8FD'}
          />
        ),
      },
      {
        field: 'risk',
        headerName: 'Risk Score',
        width: 140,
        type: 'singleSelect',
        valueGetter: ({ value }) => normalizeRiskScore(value) || 'N/A',
        sortComparator: (v1, v2) =>
          ((v1 && RISK_SCORE_MAP[v1.toUpperCase() as APICatalogRisk]) || 0) -
          ((v2 && RISK_SCORE_MAP[v2.toUpperCase() as APICatalogRisk]) || 0),
        valueOptions: ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'N/A'],
        renderCell: ({ value }) => (
          <Tooltip
            title={
              <>
                The risk of the application is determined based on the highest
                risk associated API within the application. For more
                information, visit:{' '}
                <a
                  href="https://docs.pynt.io/documentation/api-catalog/navigate-catalog/apis-at-risk"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: BRAND_ORANGE }}
                  onClick={() => {
                    track(
                      'web_app_applications_risk_grid_risk_calculation_button_click',
                    );
                  }}
                >
                  API Risk Calculation
                </a>
                .
              </>
            }
          >
            <Box>
              <RiskScoreChip score={value ?? 'N/A'} />
            </Box>
          </Tooltip>
        ),
      },
    ],
    [applications],
  );

  const [gridColumnsState, setGridColumnsState] =
    usePersistedJsonState<GridColumnsInitialState>(
      'ApplicationRiskScoreGridGridColumnsState',
      {
        columnVisibilityModel: {},
      },
    );

  const gridInitialState = useMemo<GridInitialStateCommunity>(() => {
    return {
      sorting: {
        sortModel: [{ field: 'risk', sort: 'desc' }],
      },
      filter: {
        filterModel: filter,
      },
      columns: gridColumnsState,
      pagination: { paginationModel: paginationModel ?? { pageSize: 10 } },
    };
  }, [gridColumnsState, filter, paginationModel]);

  return (
    <PyntDataGrid
      paginationMode="server"
      filterMode="server"
      sortingMode={'server'}
      sortModel={sortModel}
      rowCount={count}
      filterModel={filterModel}
      onSortModelChange={(sortModel) => setSortModel([...sortModel])}
      onFilterModelChange={(filterModel) => setFilterModel({ ...filterModel })}
      paginationModel={{ page: page || 0, pageSize: pageSize || 50 }}
      onPaginationModelChange={(paginationModel) => {
        setPage(paginationModel.page);
        setPageSize(paginationModel.pageSize);
      }}
      autoHeight={autoHeight}
      autoPageSize={autoPageSize}
      loading={isLoadingApplications}
      sx={{ width: '100%' }}
      rows={applications || []}
      columns={columns}
      getRowId={(row) => row.app_id}
      initialState={gridInitialState}
      onStateChange={(state) => {
        setGridColumnsState(state.columns);
      }}
      onRowClick={({ row }) => {
        setSelectedApplicationId(row.app_id);

        navigate({
          pathname: '/dashboard/api-catalog',
          search: `?sorting=${JSON.stringify([
            { field: 'properties.risk', sort: 'desc' },
          ])}`,
        });
      }}
      pagination={pagination}
      hideFooter={hideFooter}
    />
  );
}
