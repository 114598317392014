import { Box, SxProps } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';

const APP_BAR: SxProps = {
  width: '100%',
  bgcolor: 'background.default',
  height: 64 + 16,
  mt: -2,
  mb: 2,
  display: 'flex',
  justifyContent: 'center',
  border: 'none',
  boxShadow: 'none',
};

const PAGE_TITLE: SxProps = {
  color: 'black',
  textAlign: 'start',
  fontSize: '30px',
  fontWeight: 600,
  paddingInlineEnd: 2,
};

const HEADER_TOOLBAR: SxProps = {
  height: 64,
};

export interface Props {
  title: string;
  startActions?: ReactElement;
  endActions?: ReactElement;
}

export default function DashboardAppBar({
  title,
  startActions,
  endActions,
}: Props) {
  return (
    <AppBar position="sticky" elevation={0} sx={APP_BAR}>
      <Toolbar sx={HEADER_TOOLBAR}>
        <Typography variant="h6" noWrap component="div" sx={PAGE_TITLE}>
          {title}
        </Typography>
        {startActions}
        {endActions ? (
          <>
            <Box flex={1}></Box>
            {endActions}
          </>
        ) : (
          <></>
        )}
      </Toolbar>
    </AppBar>
  );
}
