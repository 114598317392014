import { SvgIconProps } from './SvgIconProps';

export const AddIcon = ({ color = '#ACACBB', size = 16 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.1421 7.87014H0.999992" stroke={color} strokeWidth="1.3" />
      <path d="M8.07107 0.798927V14.9411" stroke={color} strokeWidth="1.3" />
    </svg>
  );
};
