import { SvgIconProps } from './SvgIconProps';

export const TotalAPIsIcon = ({ size = 50 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="8" fill="#DBDDE4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5175 27.3896C32.0125 27.404 33.244 27.4159 34.6014 25.7616C36.0018 24.0549 36.3747 18.7616 31.3481 18.1216C29.501 9.05493 16.5015 11.8749 17.5478 20.0549H17.4945C12.0545 20.4416 12.1577 27.3883 17.4947 27.3883H24.251V29.3049H20.001C17.581 29.3049 16.251 30.6349 16.251 33.0549V33.4082C15.0961 33.7343 14.251 34.795 14.251 36.0549C14.251 37.5749 15.481 38.8049 17.001 38.8049C18.521 38.8049 19.751 37.5749 19.751 36.0549C19.751 34.795 18.9059 33.7343 17.751 33.4082V33.0549C17.751 31.4749 18.421 30.8049 20.001 30.8049H24.251V33.4082C23.0961 33.7343 22.251 34.795 22.251 36.0549C22.251 37.5749 23.481 38.8049 25.001 38.8049C26.521 38.8049 27.751 37.5749 27.751 36.0549C27.751 34.795 26.9059 33.7343 25.751 33.4082V30.8049H30.001C31.581 30.8049 32.251 31.4749 32.251 33.0549V33.4082C31.0961 33.7343 30.251 34.795 30.251 36.0549C30.251 37.5749 31.481 38.8049 33.001 38.8049C34.521 38.8049 35.751 37.5749 35.751 36.0549C35.751 34.795 34.9059 33.7343 33.751 33.4082V33.0549C33.751 30.6349 32.421 29.3049 30.001 29.3049H25.751V27.3883H30.3747L30.5175 27.3896ZM33.001 34.8049C32.311 34.8049 31.751 35.3649 31.751 36.0549C31.751 36.7449 32.311 37.3049 33.001 37.3049C33.691 37.3049 34.251 36.7449 34.251 36.0549C34.251 35.3649 33.691 34.8049 33.001 34.8049ZM17.001 34.8049C16.311 34.8049 15.751 35.3649 15.751 36.0549C15.751 36.7449 16.311 37.3049 17.001 37.3049C17.691 37.3049 18.251 36.7449 18.251 36.0549C18.251 35.3649 17.691 34.8049 17.001 34.8049ZM23.751 36.0549C23.751 35.3649 24.311 34.8049 25.001 34.8049C25.691 34.8049 26.251 35.3649 26.251 36.0549C26.251 36.7449 25.691 37.3049 25.001 37.3049C24.311 37.3049 23.751 36.7449 23.751 36.0549Z"
        fill="white"
      />
    </svg>
  );
};
