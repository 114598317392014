import { useContext, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { AuthContext } from './contexts/Auth';

import Login from './screens/Login';
import Overview from './screens/Overview';

import DashboardLayout from './components/Common/DashboardLayout';
import Loader from './components/Common/Loader';

import { Box, Hidden, Stack, Typography } from '@mui/material';
import './App.css';
import { LightLogo } from './assets/svgs/LightLogo';
import BurpOnboardLogin from './components/BurpOnboard/BurpOnboardLogin';
import BurpOnboardRun from './components/BurpOnboard/BurpOnboardRun';
import BurpOnboardSetup from './components/BurpOnboard/BurpOnboardSetup';
import BurpOnboardWrapper from './components/BurpOnboard/BurpOnboardWrapper';
import Instructions from './components/Instructions';
import PostmanOnboardLogin from './components/PostmanOnboard/PostmanOnboardLogin';
import PostmanOnboardRun from './components/PostmanOnboard/PostmanOnboardRun';
import PostmanOnboardSetup from './components/PostmanOnboard/PostmanOnboardSetup';
import PostmanOnboardWrapper from './components/PostmanOnboard/PostmanOnboardWrapper';
import RerunScanInstructions from './components/RerunScanInstructions';
import SeleniumOnboardLogin from './components/SeleniumOnboard/SeleniumOnboardLogin';
import SeleniumOnboardRun from './components/SeleniumOnboard/SeleniumOnboardRun';
import SeleniumOnboardSetup from './components/SeleniumOnboard/SeleniumOnboardSetup';
import SeleniumOnboardWrapper from './components/SeleniumOnboard/SeleniumOnboardWrapper';
import APIDetailedView from './screens/APIDetailedView';
import Application from './screens/Application';
import Applications from './screens/Applications';
import Catalog from './screens/Catalog';
import ExpiredPlan from './screens/ExpiredPlan';
import History from './screens/History';
import Integrations from './screens/Integrations';
import OrgSettings from './screens/OrgSettings';
import SettingsWrapper from './screens/SettingsWrapper';
import Users from './screens/Users';
import Vulnerabilities from './screens/Vulnerabilities';
import { connectAnonymousUser, track, trackPage } from './utils/analytics';
import SignupOnboarding from './screens/SignupOnboarding';
import SSOLogin from './components/Common/SSOLogin';

const ProtectedRoute = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean | null;
}) => {
  if (!isAuthenticated) {
    const params = ['?goto=', window.location.pathname, window.location.search]
      .filter((a) => !!a)
      .filter((_, __, arr) => arr.length > 1)
      .join('');
    return <Navigate to={'/login' + params} replace />;
  }

  return <Outlet />;
};

function App() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    track('web_app_startup');
  }, []);

  useEffect(() => {
    const segmentId = searchParams.get('segmentId');
    if (!segmentId) return;

    setSearchParams((searchParams) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('segmentId');
      return newSearchParams;
    });
    connectAnonymousUser(segmentId);
  }, [searchParams]);

  useEffect(() => {
    trackPage();
  }, [location.pathname]);

  useEffect(() => {
    // if (hotjar.initialized()) {
    //  hotjar.stateChange(location.pathname);
    // }
    // track('page_view', location.pathname);
  }, [location]);

  return (
    <Box className="App">
      <Hidden smUp>
        <Box
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            background: '#050d21',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            zIndex: 99999999,
          }}
        >
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ p: 3 }}
          >
            <LightLogo />
            <Typography variant="h3" sx={{ mt: 3 }}>
              Mobile is not supported
            </Typography>
            <Typography variant="h5" fontWeight={500}>
              Please use the desktop version instead :)
            </Typography>
          </Stack>
        </Box>
      </Hidden>
      {user === null ? (
        <Loader />
      ) : (
        <Routes>
          <Route path="/login/:provider" element={<SSOLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/onboard/postman">
            <Route element={<PostmanOnboardWrapper />}>
              <Route path="login" element={<PostmanOnboardLogin />} />
              <Route path="setup" element={<PostmanOnboardSetup />} />
              <Route path="run" element={<PostmanOnboardRun />} />
            </Route>
            <Route path="" element={<Navigate to="/onboard/postman/login" />} />
            <Route
              path="*"
              element={<Navigate to="/onboard/postman/login" />}
            />
          </Route>
          <Route path="/onboard/selenium">
            <Route element={<SeleniumOnboardWrapper />}>
              <Route path="login" element={<SeleniumOnboardLogin />} />
              <Route path="setup" element={<SeleniumOnboardSetup />} />
              <Route path="run" element={<SeleniumOnboardRun />} />
            </Route>
            <Route
              path=""
              element={<Navigate to="/onboard/selenium/login" />}
            />
            <Route
              path="*"
              element={<Navigate to="/onboard/selenium/login" />}
            />
          </Route>
          <Route path="/onboard/burp">
            <Route element={<BurpOnboardWrapper />}>
              <Route path="login" element={<BurpOnboardLogin />} />
              <Route path="setup" element={<BurpOnboardSetup />} />
              <Route path="run" element={<BurpOnboardRun />} />
            </Route>
            <Route path="" element={<Navigate to="/onboard/burp/login" />} />
            <Route path="*" element={<Navigate to="/onboard/burp/login" />} />
          </Route>
          <Route path="/auth" element={<Login />} />

          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/onboarding" element={<SignupOnboarding />} />
            <Route path="/expired" element={<ExpiredPlan />} />
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="overview" element={<Overview />} />
              <Route path="applications" element={<Applications />} />
              <Route path="application/:id" element={<Application />} />
              <Route path="api-catalog" element={<Catalog />} />
              <Route path="api-catalog/:id" element={<APIDetailedView />} />
              <Route path="vulnerabilities" element={<Vulnerabilities />} />
              <Route path="scans-history" element={<History />} />
              <Route path="settings" element={<SettingsWrapper />}>
                <Route path="general" element={<OrgSettings />} />
                <Route path="users-management" element={<Users />} />
                <Route path="integrations" element={<Integrations />} />
                <Route
                  path="*"
                  element={<Navigate to="/dashboard/settings/general" />}
                />
                <Route
                  path=""
                  element={<Navigate to="/dashboard/settings/general" />}
                />
              </Route>
              <Route path="instructions" element={<Instructions />} />
              <Route
                path="rerun-instructions"
                element={<RerunScanInstructions />}
              />
              <Route path="*" element={<Navigate to="/dashboard/overview" />} />
              <Route path="" element={<Navigate to="/dashboard/overview" />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="/dashboard/overview" />} />
        </Routes>
      )}
    </Box>
  );
}

export default App;
