import * as Sentry from '@sentry/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RunningScanHistoryItem, ScanHistoryItem } from '../models/scanHistory';
import { TestCase } from '../models/test-case';
import { getApplicationById } from '../services/ApplicationsService';
import { getAppScans, getRunningScans } from '../services/ScansService';
import { getScanProfile, getTestCases } from '../services/TestsService';
import { AuthContext } from './Auth';
import { Application } from './User';

export interface ApplicationContextProps {
  application?: Application;
  isLoadingApplication: boolean;
  error?: string;
  refetchApplication: () => Promise<Application>;

  latestScans?: ScanHistoryItem[];
  isLoadingLatestScans: boolean;

  runningScans?: RunningScanHistoryItem[];
  isLoadingRunningScans: boolean;

  testCases?: TestCase[];
  isLoadingTestCases: boolean;
  fetchTestCases: () => Promise<TestCase>;

  endpointsRisks: Record<string, number>;
  setApplicationId: (id: string | undefined) => any;

  scanProfileId: string | undefined;
}

export const ApplicationContext = createContext<ApplicationContextProps | null>(
  null,
);

function ApplicationContextProvider(props: any) {
  const { isAuthenticated } = useContext(AuthContext);
  const [application, setApplication] = useState<Application>();
  const [error, setError] = useState<string>();
  const [applicationId, setApplicationId] = useState<string>();
  const [isLoadingApplication, setIsLoadingApplication] = useState(false);
  const [latestScans, setLatestScans] = useState<ScanHistoryItem[]>();
  const [isLoadingLatestScans, setIsLoadingLatestScans] = useState(false);
  const [runningScans, setRunningScans] = useState<RunningScanHistoryItem[]>();
  const [isLoadingRunningScans, setIsLoadingRunningScans] = useState(false);
  const [testCases, setTestCases] = useState<TestCase[]>();
  const [isLoadingTestCases, setIsLoadingTestCases] = useState(false);
  const [scanProfileId, setScanProfileId] = useState();

  const endpointsRisks = useMemo(() => {
    const risks = (application as any)?.endpoints_risks;
    if (!risks) return {};

    return Object.fromEntries(
      Object.entries(risks).map(([k, v]: any) => [k?.toUpperCase(), v]),
    ) as any;
  }, [application]);

  useEffect(() => {
    if (!applicationId || !isAuthenticated) {
      setIsLoadingApplication(false);
      setApplication(undefined);
      setTestCases(undefined);
      return;
    }

    setIsLoadingApplication(true);
    setApplication(undefined);
    setError(undefined);

    getApplicationById(applicationId)
      .then((app) => {
        setApplication(app);
      })
      .catch((e) => {
        setError(e.message || e.toString());
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoadingApplication(false);
      });
  }, [applicationId, isAuthenticated]);

  const fetchLatestScans = useCallback(async (applicationId: string) => {
    const scans = await getAppScans(applicationId);

    return scans.filter((s: any) => s.application === applicationId);
  }, []);

  const fetchRunningScans = useCallback(async (applicationId: string) => {
    const scans = await getRunningScans(applicationId);

    return scans;
  }, []);

  const fetchTestCases = useCallback(async () => {
    if (!applicationId) {
      setTestCases([]);
      return;
    }

    setIsLoadingTestCases(true);

    const scanProfile = await getScanProfile(applicationId);

    const tests = await getTestCases(applicationId).finally(() => {
      setIsLoadingTestCases(false);
    });

    setScanProfileId(scanProfile?.[0]?.scan_profile_id);
    const includedTests = scanProfile?.[0]?.tests;
    tests.forEach(
      (t: {
        category: string;
        subcategory: string;
        includedInScans: boolean;
      }) => {
        t.includedInScans =
          includedTests?.[t.category]?.[t.subcategory]?.should_attack ?? true;
      },
    );

    setTestCases(tests);

    return tests;
  }, [application]);

  const refetchApplication = useCallback(async () => {
    if (!applicationId || isLoadingApplication) return;

    return getApplicationById(applicationId).then((app) => {
      setApplication(app);
      return app;
    });
  }, [applicationId, isLoadingApplication]);

  useEffect(() => {
    if (!applicationId || !isAuthenticated) {
      setIsLoadingLatestScans(false);
      setLatestScans(undefined);
      return;
    }

    setIsLoadingLatestScans(true);
    fetchLatestScans(applicationId)
      .then((scans) => {
        setLatestScans(scans);
      })
      .catch((e) => {
        console.error(e);
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoadingLatestScans(false);
      });
  }, [applicationId, fetchLatestScans, isAuthenticated]);

  useEffect(() => {
    if (!applicationId || !isAuthenticated) {
      setIsLoadingRunningScans(false);
      setRunningScans(undefined);
      return;
    }

    setIsLoadingRunningScans(true);
    let relevant = true;

    let timer: any;
    const _fetchLoop = () => {
      if (!relevant) return;

      fetchRunningScans(applicationId)
        .then((scans) => {
          setRunningScans(scans);
          if (!scans.length) {
            // If there are no running scans, fetch every 10 seconds
            timer = setTimeout(_fetchLoop, 10000);
          } else {
            // If there are running scans, fetch every 3 seconds
            timer = setTimeout(_fetchLoop, 3000);
          }
        })
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);

          // If there is an error, fetch every 5 seconds
          timer = setTimeout(_fetchLoop, 5000);
        })
        .finally(() => {
          setIsLoadingRunningScans(false);
        });
    };

    _fetchLoop();

    return () => {
      relevant = false;
      if (timer) clearTimeout(timer);
    };
  }, [applicationId, fetchRunningScans, isAuthenticated]);

  useEffect(() => {
    if (!application || !isAuthenticated) return;
    fetchTestCases();
  }, [application, isAuthenticated]);

  return (
    <ApplicationContext.Provider
      value={{
        application,
        isLoadingApplication,
        error,
        refetchApplication,

        latestScans,
        isLoadingLatestScans,

        runningScans,
        isLoadingRunningScans,

        testCases,
        isLoadingTestCases,
        fetchTestCases,

        setApplicationId,
        endpointsRisks,

        scanProfileId,
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
}

export default ApplicationContextProvider;
