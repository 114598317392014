import { Components, Theme } from '@mui/material';

export default function themedMuiButton(
  theme: Theme,
): Pick<Components<Theme>, 'MuiButton'> {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
        contained: {
          boxShadow: 'unset',
        },
      },
    },
  };
}
