import { SvgIconProps } from './SvgIconProps';

export const UploadIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 13V16H4V13H2V16C2 17.1 2.9 18 4 18H16C17.1 18 18 17.1 18 16V13H16ZM5 7L6.41 8.41L9 5.83V14H11V5.83L13.59 8.41L15 7L10 2L5 7Z"
        fill={color}
      />
    </svg>
  );
};
