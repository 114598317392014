import {
  Checkbox,
  IconButton,
  Stack,
  SxProps,
  Typography,
  styled,
  Theme,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';

import { ChevronLeft } from '@mui/icons-material';
import React, { MouseEventHandler, useContext, useState } from 'react';
import { LightLogo } from '../../assets/svgs/LightLogo';
import { LogoutIcon } from '../../assets/svgs/icons/LogoutIcon';
import { QuesionMarkCircleIcon } from '../../assets/svgs/icons/QuesionMarkCircleIcon';
import { AuthContext } from '../../contexts/Auth';
import HelpDialog from '../../dialogs/HelpDialog';
import { track } from '../../utils/analytics';
import DashboardAvatar from './DashboardAvatar';
import { MenuItem } from './DashboardLayout';

export const drawerWidth = 260;
export const miniDrawerWidth = 88;

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: miniDrawerWidth,
  [theme.breakpoints.up('sm')]: {
    width: miniDrawerWidth,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(
  ({ theme, open }) =>
    ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }) as any,
);

const DRAWER: SxProps = {
  'width': drawerWidth,
  'flexShrink': 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    border: 'none',
    bgcolor: 'secondary.main',
    borderRadius: 2.5,
    top: 20,
    left: 20,
    bottom: 20,
    height: 'calc(100vh - 40px)',
  },
};

const DRAWER_TOOLBAR = (open: boolean): SxProps => ({
  height: 64,
  m: open ? 2 : 0,
  mt: 1.5,
  mb: 1.5,
  ml: open ? 2 : -1.2,
});

const TAB_BUTTON: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  height: 54,
  borderRadius: 1.5,
  p: 1,
  paddingInline: 2,
  my: 1.5,
};

const TAB_BUTTON_ACTIVE: SxProps = {
  ...TAB_BUTTON,
  'bgcolor': 'primary.main',
  ':hover': {
    bgcolor: 'primary.main',
  },
};

const TAB_CONTENT: SxProps = {
  color: 'white',
  fontWeight: 600,
};

const LIST: SxProps = { pl: 2, pr: 2 };

interface Props {
  menuItems: MenuItem[];
  bottomItems?: MenuItem[];
  open: boolean;
  setOpen: any;
}

export default function DashboardDrawer({
  menuItems,
  bottomItems,
  open,
  setOpen,
}: Props) {
  const { logout, isProd, setIsProd } = useContext(AuthContext);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  return (
    <Drawer
      sx={DRAWER}
      variant="permanent"
      anchor="left"
      open={open}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}
    >
      <HelpDialog
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
      />
      <Toolbar sx={DRAWER_TOOLBAR(open)} disableGutters>
        <Stack
          direction="row"
          alignItems={'end'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <LightLogo height={open ? 50 : 24} />
          {open && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
              sx={{ background: '#39394B', borderRadius: 1 }}
            >
              <ChevronLeft />
            </IconButton>
          )}
        </Stack>
      </Toolbar>
      <DashboardAvatar />
      <Stack
        direction={'column'}
        alignItems={'space-between'}
        justifyContent={'space-between'}
        flex={1}
      >
        <List sx={LIST}>
          {menuItems.map((item) => (
            <_DrawerItem key={item.name} item={item} open={open} />
          ))}
        </List>
        <List sx={LIST}>
          {process.env.REACT_APP_NODE_ENV === 'development' ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={open ? 'inherit' : 'center'}
              paddingLeft={Number(open)}
            >
              <Checkbox
                title="Prod"
                value={isProd}
                onChange={(e) => {
                  e.stopPropagation();
                  setIsProd(!!e.target.checked);
                }}
              ></Checkbox>
              {open && <Typography color={'white'}>Prod</Typography>}
            </Stack>
          ) : (
            <></>
          )}
          {bottomItems?.map((item) => (
            <_DrawerItem key={item.name} item={item} open={open} />
          )) ?? []}
          <_DrawerItem
            item={{
              id: 'help',
              name: 'Help',
              path: 'help',
              icon: <QuesionMarkCircleIcon color="#969BA9" />,
            }}
            onClick={(e) => {
              e.stopPropagation();
              track('web_app_drawer_help_button_click');
              setHelpDialogOpen(true);
            }}
            open={open}
          />
          <_DrawerItem
            item={{
              id: 'logout',
              name: 'Log Out',
              path: 'logout',
              icon: <LogoutIcon color="#969BA9" />,
            }}
            onClick={(e) => {
              e.stopPropagation();
              track('web_app_drawer_logout_button_click');
              logout();
            }}
            open={open}
          />
        </List>
      </Stack>
    </Drawer>
  );
}

function _DrawerItem({
  item,
  onClick,
  open,
}: {
  item: MenuItem;
  onClick?: MouseEventHandler<HTMLDivElement>;
  open: boolean;
}) {
  const navigate = useNavigate();

  return (
    <ListItemButton
      key={item.name}
      id={`drawer-item-${item.id}`}
      sx={item.active ? TAB_BUTTON_ACTIVE : TAB_BUTTON}
      onClick={
        onClick ??
        ((e) => {
          e.stopPropagation();
          track('web_app_drawer_tab_button_click', { tab: item.name });
          navigate(item.path);
        })
      }
    >
      <ListItemIcon sx={{ minWidth: open ? 36 : 'auto' }}>
        {React.cloneElement(item.icon as any, {
          color: item.active ? 'white' : '#969BA9',
        })}
      </ListItemIcon>
      {open && <ListItemText primary={item.name} sx={TAB_CONTENT} />}
    </ListItemButton>
  );
}
