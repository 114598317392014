import * as Sentry from '@sentry/react';
import { createContext, useCallback, useState } from 'react';
import {
  getInvites,
  getOrgUsers,
  getSSOConfig,
} from '../services/OrgUsersService';

export interface OrgUser {
  name: string;
  lastname: string;
  user_id?: string;
  role?: string;
}

export interface OrgUserInvite {
  invite_id?: string;
  email?: string;
  role?: string;
  status: 'accepted' | 'pending';
}

export interface OrgSSOConfig {
  identity_provider: string | null;
}

export interface OrganizationContextProps {
  isLoadingUsers: boolean;
  users?: OrgUser[];
  fetchUsers: () => Promise<OrgUser[]>;

  isLoadingInvites: boolean;
  invites?: OrgUserInvite[];
  fetchInvites: () => Promise<OrgUserInvite[]>;

  ssoConfig?: OrgSSOConfig;
  fetchSSOConfig: () => void;
}

export const OrganizationContext =
  createContext<OrganizationContextProps | null>(null);

function OrganizationContextProvider(props: any) {
  const [users, setUsers] = useState<OrgUser[]>();
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const [invites, setInvites] = useState<OrgUserInvite[]>();
  const [isLoadingInvites, setIsLoadingInvites] = useState(false);

  const [ssoConfig, setSsoConfig] = useState<OrgSSOConfig | undefined>(
    undefined,
  );

  const fetchUsers = useCallback(
    async () => {
      if (isLoadingUsers) return;

      setIsLoadingUsers(true);

      const apiSources = await getOrgUsers()
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        })
        .finally(() => {
          setIsLoadingUsers(false);
        });
      setUsers(apiSources);

      return apiSources;
    },
    [isLoadingUsers],
  );

  const fetchInvites = useCallback(
    async () => {
      if (isLoadingInvites) return;

      setIsLoadingInvites(true);

      const invites = await getInvites()
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        })
        .finally(() => {
          setIsLoadingInvites(false);
        });
      setInvites(invites || []);

      return invites;
    },
    [isLoadingInvites],
  );

  const fetchSSOConfig = useCallback(
    () => getSSOConfig().then(setSsoConfig),
    [setSsoConfig],
  );

  return (
    <OrganizationContext.Provider
      value={{
        isLoadingUsers,
        users,
        fetchUsers,

        isLoadingInvites,
        invites,
        fetchInvites,

        ssoConfig,
        fetchSSOConfig,
      }}
    >
      {props.children}
    </OrganizationContext.Provider>
  );
}

export default OrganizationContextProvider;
