import { SvgIconProps } from './SvgIconProps';

export const StorageIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66797 16.6667H18.3346V13.3334H1.66797V16.6667ZM3.33464 14.1667H5.0013V15.8334H3.33464V14.1667ZM1.66797 3.33337V6.66671H18.3346V3.33337H1.66797ZM5.0013 5.83337H3.33464V4.16671H5.0013V5.83337ZM1.66797 11.6667H18.3346V8.33337H1.66797V11.6667ZM3.33464 9.16671H5.0013V10.8334H3.33464V9.16671Z"
        fill={color}
      />
    </svg>
  );
};
