import { Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { APIStatusTooltip } from './APIStatusTooltip';

interface Props {
  status: string | undefined;
  showTooltip?: boolean;
}

export default function APIStatusChip({ status, showTooltip = true }: Props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const statusTitle = useMemo(() => {
    switch (status?.toUpperCase()) {
      case 'VALIDATED':
        return 'Validated';

      case 'UNDOCUMENTED':
        return 'Undocumented API';

      case 'NEW_API':
        return 'New API';

      case 'SHADOW':
        return 'Shadow API';

      case undefined:
        return 'No Gaps';

      default:
        return status;
    }
  }, [status]);

  return (
    <APIStatusTooltip
      status={status}
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          bgcolor: tooltipOpen ? '' : '#39394B',
          color: 'white',
          px: 0.5,
          py: 0,
          fontWeight: 400,
          fontSize: 12,
          borderRadius: '3px',
        }}
        onClick={(e) => {
          setTooltipOpen((x) => !x);
          e.stopPropagation();
        }}
      >
        {statusTitle}
      </Button>
    </APIStatusTooltip>
  );
}
