import { SvgIconProps } from './SvgIconProps';

export const ChevronRightIcon = ({
  color = 'black',
  size = 24,
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00156 5.59246C7.67656 5.91746 7.67656 6.44246 8.00156 6.76746L11.2349 10.0008L8.00156 13.2341C7.67656 13.5591 7.67656 14.0841 8.00156 14.4091C8.32656 14.7341 8.85156 14.7341 9.17656 14.4091L13.0016 10.5841C13.3266 10.2591 13.3266 9.73413 13.0016 9.40913L9.17656 5.58412C8.8599 5.26746 8.32656 5.26746 8.00156 5.59246Z"
        fill={color}
      />
    </svg>
  );
};
