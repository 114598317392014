import { Box, CircularProgress, SxProps } from '@mui/material';

const BACKGROUND: SxProps = {
  width: '100%',
  height: '100%',
  bgcolor: 'white',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const Loader = () => {
  return (
    <Box sx={BACKGROUND}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
