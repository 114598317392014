import { SvgIconProps } from './SvgIconProps';

export const ClockIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3346 9.99935C18.3346 14.5993 14.6013 18.3327 10.0013 18.3327C5.4013 18.3327 1.66797 14.5993 1.66797 9.99935C1.66797 5.39935 5.4013 1.66602 10.0013 1.66602C14.6013 1.66602 18.3346 5.39935 18.3346 9.99935Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0914 12.6495L10.5081 11.1078C10.0581 10.8411 9.69141 10.1995 9.69141 9.67448V6.25781"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
