import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import PyntDataGrid from '../Common/PyntDataGrid';

const CHIP_WRAPPER = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#00A676',
  bgcolor: '#00A67610',
  pl: 2,
  pr: 2,
  borderRadius: 2,
  fontWeight: 600,
};

const columns: GridColDef[] = [
  {
    field: 'tls',
    headerName: 'TLS',
    width: 280,
    renderCell: ({ value }) => <Box sx={CHIP_WRAPPER}>{value}</Box>,
  },
  {
    field: 'verboseError',
    headerName: 'Verbose Error',
    width: 280,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'serverResponseTime',
    headerName: 'Server Response Time',
    width: 260,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'sensitiveInformationInGetParams',
    headerName: 'Sensitive Information In GET Params',
    width: 260,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'enumerableResourcesId',
    headerName: 'Sensitive Information - Secrets',
    width: 260,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
];

export default function SecurityInfoGrid() {
  const apiCatalog = [] as any;
  const isLoadingAPICatalog = false;

  return (
    <PyntDataGrid
      autoHeight
      sx={{ width: '100%' }}
      rows={[
        {
          id: '123',
          tls: 'TRUE',
          verboseError: 'Stack trace found',
          serverResponseTime: 'password',
          sensitiveInformationInGetParams: 'password',
          enumerableResourcesId: '-',
        },
      ]}
      loading={!apiCatalog && isLoadingAPICatalog}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      hideFooterPagination
      initialState={{
        sorting: { sortModel: [{ field: 'timestamp', sort: 'desc' }] },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
