import { SvgIconProps } from './SvgIconProps';

export const EyeIcon = ({ color = '#ACACBB', size = 16 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2434 9.02806L15.2435 9.0281L15.2483 9.02182C15.5801 8.5922 15.5896 7.97765 15.2425 7.54731C14.7725 6.95836 13.8352 5.87402 12.5938 4.93284C11.3578 3.99572 9.76861 3.16145 8.00529 3.16117C6.24128 3.1551 4.65153 3.98669 3.41525 4.92249C2.17431 5.86181 1.23733 6.94562 0.767022 7.53477C0.41099 7.97242 0.410993 8.59253 0.76703 9.03018C1.23735 9.61935 2.17422 10.703 3.41494 11.6437C4.65121 12.581 6.2407 13.4153 8.00438 13.4153C9.76806 13.4153 11.3576 12.581 12.5938 11.6437C13.836 10.7019 14.7737 9.61682 15.2434 9.02806ZM8.00438 6.00046C9.26678 6.00046 10.2807 7.01436 10.2807 8.27674C10.2807 9.53913 9.26678 10.553 8.00438 10.553C6.74199 10.553 5.72808 9.53913 5.72808 8.27674C5.72808 7.01436 6.74199 6.00046 8.00438 6.00046Z"
        stroke={color}
      />
    </svg>
  );
};
