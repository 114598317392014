import { ReactNode, useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  ThemeOptions,
} from '@mui/material/styles';

import componentsOverride from './overrides';
import palette from './palette';
import typography from './typography';

interface Props {
  children: ReactNode;
}

export default function ThemeProvider({ children }: Props) {
  const themeMode = 'light';
  const themeDirection = 'ltr';
  const isLight = themeMode === 'light';

  const themeOptions = useMemo<ThemeOptions>(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
    }),
    [isLight, themeDirection],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
