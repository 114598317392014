import { SvgIconProps } from './SvgIconProps';

export const AppsIcon = ({ color = 'white', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.92308 0.875H2.30769C1.69565 0.875 1.10868 1.11813 0.675907 1.55091C0.243131 1.98368 0 2.57065 0 3.18269V7.79808C0 8.41012 0.243131 8.99709 0.675907 9.42986C1.10868 9.86264 1.69565 10.1058 2.30769 10.1058H6.92308C7.53512 10.1058 8.12209 9.86264 8.55486 9.42986C8.98764 8.99709 9.23077 8.41012 9.23077 7.79808V3.18269C9.23077 2.57065 8.98764 1.98368 8.55486 1.55091C8.12209 1.11813 7.53512 0.875 6.92308 0.875ZM7.69231 7.79808C7.69231 8.00209 7.61126 8.19775 7.46701 8.34201C7.32275 8.48626 7.12709 8.56731 6.92308 8.56731H2.30769C2.10368 8.56731 1.90802 8.48626 1.76376 8.34201C1.61951 8.19775 1.53846 8.00209 1.53846 7.79808V3.18269C1.53846 2.97868 1.61951 2.78302 1.76376 2.63876C1.90802 2.49451 2.10368 2.41346 2.30769 2.41346H6.92308C7.12709 2.41346 7.32275 2.49451 7.46701 2.63876C7.61126 2.78302 7.69231 2.97868 7.69231 3.18269V7.79808Z"
        fill={color}
      />
      <path
        d="M17.6923 0.875H13.0769C12.4649 0.875 11.8779 1.11813 11.4451 1.55091C11.0124 1.98368 10.7692 2.57065 10.7692 3.18269V7.79808C10.7692 8.41012 11.0124 8.99709 11.4451 9.42986C11.8779 9.86264 12.4649 10.1058 13.0769 10.1058H17.6923C18.3043 10.1058 18.8913 9.86264 19.3241 9.42986C19.7569 8.99709 20 8.41012 20 7.79808V3.18269C20 2.57065 19.7569 1.98368 19.3241 1.55091C18.8913 1.11813 18.3043 0.875 17.6923 0.875ZM18.4615 7.79808C18.4615 8.00209 18.3805 8.19775 18.2362 8.34201C18.092 8.48626 17.8963 8.56731 17.6923 8.56731H13.0769C12.8729 8.56731 12.6773 8.48626 12.533 8.34201C12.3887 8.19775 12.3077 8.00209 12.3077 7.79808V3.18269C12.3077 2.97868 12.3887 2.78302 12.533 2.63876C12.6773 2.49451 12.8729 2.41346 13.0769 2.41346H17.6923C17.8963 2.41346 18.092 2.49451 18.2362 2.63876C18.3805 2.78302 18.4615 2.97868 18.4615 3.18269V7.79808Z"
        fill={color}
      />
      <path
        d="M6.92308 11.6443H2.30769C1.69565 11.6443 1.10868 11.8874 0.675907 12.3202C0.243131 12.753 0 13.3399 0 13.952V18.5674C0 19.1794 0.243131 19.7664 0.675907 20.1991C1.10868 20.6319 1.69565 20.8751 2.30769 20.8751H6.92308C7.53512 20.8751 8.12209 20.6319 8.55486 20.1991C8.98764 19.7664 9.23077 19.1794 9.23077 18.5674V13.952C9.23077 13.3399 8.98764 12.753 8.55486 12.3202C8.12209 11.8874 7.53512 11.6443 6.92308 11.6443ZM7.69231 18.5674C7.69231 18.7714 7.61126 18.967 7.46701 19.1113C7.32275 19.2556 7.12709 19.3366 6.92308 19.3366H2.30769C2.10368 19.3366 1.90802 19.2556 1.76376 19.1113C1.61951 18.967 1.53846 18.7714 1.53846 18.5674V13.952C1.53846 13.748 1.61951 13.5523 1.76376 13.4081C1.90802 13.2638 2.10368 13.1827 2.30769 13.1827H6.92308C7.12709 13.1827 7.32275 13.2638 7.46701 13.4081C7.61126 13.5523 7.69231 13.748 7.69231 13.952V18.5674Z"
        fill={color}
      />
      <path
        d="M19.2308 15.4904H16.1538V12.4135C16.1538 12.2095 16.0728 12.0138 15.9285 11.8696C15.7843 11.7253 15.5886 11.6443 15.3846 11.6443C15.1806 11.6443 14.9849 11.7253 14.8407 11.8696C14.6964 12.0138 14.6154 12.2095 14.6154 12.4135V15.4904H11.5385C11.3344 15.4904 11.1388 15.5715 10.9945 15.7157C10.8503 15.86 10.7692 16.0557 10.7692 16.2597C10.7692 16.4637 10.8503 16.6593 10.9945 16.8036C11.1388 16.9479 11.3344 17.0289 11.5385 17.0289H14.6154V20.1058C14.6154 20.3098 14.6964 20.5055 14.8407 20.6498C14.9849 20.794 15.1806 20.8751 15.3846 20.8751C15.5886 20.8751 15.7843 20.794 15.9285 20.6498C16.0728 20.5055 16.1538 20.3098 16.1538 20.1058V17.0289H19.2308C19.4348 17.0289 19.6304 16.9479 19.7747 16.8036C19.919 16.6593 20 16.4637 20 16.2597C20 16.0557 19.919 15.86 19.7747 15.7157C19.6304 15.5715 19.4348 15.4904 19.2308 15.4904Z"
        fill={color}
      />
    </svg>
  );
};
