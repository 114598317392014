import { Stack, SxProps, Typography } from '@mui/material';
import { BRAND_DARK_BLUE } from '../../constants/colors';

const CHIP: (color?: string, borderColor?: string) => SxProps = (
  color,
  borderColor,
) => ({
  bgcolor: color ?? '#F5F8FD',
  borderRadius: '6px',
  minWidth: '28px',
  minHeight: '28px',
  paddingInline: 0.5,
  border: `1px solid ${borderColor ?? null}`,
});

const TEXT: (labelColor?: string) => SxProps = (labelColor) => ({
  color: labelColor ?? BRAND_DARK_BLUE,
  fontSize: 12,
  fontWeight: 500,
});

interface Props {
  value?: number;
  color?: string;
  labelColor?: string;
  borderColor?: string;
}

export default function NumberChipIndicator({
  value,
  color,
  labelColor,
  borderColor,
}: Props) {
  return (
    <Stack
      sx={CHIP(color, borderColor)}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Typography variant="body1" sx={TEXT(labelColor)}>
        {value}
      </Typography>
    </Stack>
  );
}
