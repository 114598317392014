import { Stack, SxProps, Typography, capitalize } from '@mui/material';
import { useMemo } from 'react';

const CHIP: (bgColor: string, textColor: string) => SxProps = (
  bgColor,
  textColor,
) => ({
  bgcolor: bgColor ?? '#969BA933',
  color: textColor,
  borderRadius: 0.5,
  py: 0.5,
  px: 1,
  minWidth: '24px',
});

const TEXT: SxProps = {
  fontSize: 11,
  fontWeight: 700,
  letterSpacing: '0.2 px',
};

interface Props {
  status: string;
  grayed?: boolean;
}

export default function TestStatusChip({ status, grayed }: Props) {
  const textColor = useMemo(() => {
    switch (status?.toUpperCase()) {
      default:
        return '#fff';
    }
  }, [status]);

  const bgColor = useMemo(() => {
    switch (status.toLowerCase()) {
      case 'warning':
        return '#FF8C00';

      case 'error':
      case 'failed':
        return '#F82F02';

      case 'passed':
        return '#66BF00';

      default:
        return '#FF8C00';
    }
  }, [status]);

  const title = useMemo(() => {
    switch (status.toLowerCase()) {
      case 'warning':
        return 'Warning';

      case 'error':
      case 'failed':
        return 'Error';

      case 'passed':
        return 'Passed';

      default:
        return capitalize(status.toLowerCase());
    }
  }, [status]);

  if (!status) return <></>;

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={0.5}
      sx={CHIP(bgColor, textColor)}
    >
      <Typography variant="body1" sx={TEXT}>
        {title}
      </Typography>
    </Stack>
  );
}
