export type RegionCode =
  | 'USE1'
  | 'USE2'
  | 'USW1'
  | 'USW2'
  | 'AFS1'
  | 'APE1'
  | 'APS1'
  | 'APN2'
  | 'APS3'
  | 'APS2'
  | 'APN1'
  | 'CAN1'
  | 'EUC1'
  | 'EU'
  | 'EUW2'
  | 'EUS1'
  | 'EUW3'
  | 'EUN1'
  | 'MES1'
  | 'SAE1';

export interface AWSRegion {
  regionName: string;
  regionFullName: string;
  regionCode: RegionCode;
}

export const AWSRegios = [
  {
    regionName: 'us-east-1',
    regionFullName: 'US East (N. Virginia)',
    regionCode: 'USE1',
  },
  {
    regionName: 'us-east-2',
    regionFullName: 'US East (Ohio)',
    regionCode: 'USE2',
  },
  {
    regionName: 'us-west-1',
    regionFullName: 'US West (N. California)',
    regionCode: 'USW1',
  },
  {
    regionName: 'us-west-2',
    regionFullName: 'US West (Oregon)',
    regionCode: 'USW2',
  },
  {
    regionName: 'af-south-1',
    regionFullName: 'Africa (Cape Town)',
    regionCode: 'AFS1',
  },
  {
    regionName: 'ap-east-1',
    regionFullName: 'Asia Pacific (Hong Kong)',
    regionCode: 'APE1',
  },
  {
    regionName: 'ap-south-1',
    regionFullName: 'Asia Pacific (Mumbai)',
    regionCode: 'APS1',
  },
  {
    regionName: 'ap-northeast-2',
    regionFullName: 'Asia Pacific (Seoul)',
    regionCode: 'APN2',
  },
  {
    regionName: 'ap-southeast-1',
    regionFullName: 'Asia Pacific (Singapore)',
    regionCode: 'APS3',
  },
  {
    regionName: 'ap-southeast-2',
    regionFullName: 'Asia Pacific (Sydney)',
    regionCode: 'APS2',
  },
  {
    regionName: 'ap-northeast-1',
    regionFullName: 'Asia Pacific (Tokyo)',
    regionCode: 'APN1',
  },
  {
    regionName: 'ca-central-1',
    regionFullName: 'Canada (Central)',
    regionCode: 'CAN1',
  },
  {
    regionName: 'eu-central-1',
    regionFullName: 'EU (Frankfurt)',
    regionCode: 'EUC1',
  },
  {
    regionName: 'eu-west-1',
    regionFullName: 'EU (Ireland)',
    regionCode: 'EU',
  },
  {
    regionName: 'eu-west-2',
    regionFullName: 'EU (London)',
    regionCode: 'EUW2',
  },
  {
    regionName: 'eu-south-1',
    regionFullName: 'EU (Milan)',
    regionCode: 'EUS1',
  },
  {
    regionName: 'eu-west-3',
    regionFullName: 'EU (Paris)',
    regionCode: 'EUW3',
  },
  {
    regionName: 'eu-north-1',
    regionFullName: 'EU (Stockholm)',
    regionCode: 'EUN1',
  },
  {
    regionName: 'me-south-1',
    regionFullName: 'Middle East (Bahrain)',
    regionCode: 'MES1',
  },
  {
    regionName: 'sa-east-1',
    regionFullName: 'South America (Sao Paulo)',
    regionCode: 'SAE1',
  },
];
