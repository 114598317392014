import { useContext, useMemo } from 'react';
import { UserContext, UserContextProps } from '../contexts/User';

export function useHasFeature(featureCode: string): boolean {
  const { features } = useContext(UserContext) as UserContextProps;

  const available = useMemo<boolean>(
    () => features?.[featureCode]?.feature_enabled ?? false,
    [features, featureCode],
  );

  return available;
}
