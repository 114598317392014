import { Components, Theme } from '@mui/material';

export default function themedMuiTooltip(
  theme: Theme,
): Pick<Components<Theme>, 'MuiTooltip'> {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#050D21',
        },
        popper: {
          p: 2,
          border: 'none',
        },
        arrow: {
          color: '#050D21',
        },
      },
    },
  };
}
