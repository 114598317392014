import { Add } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { NoApplications } from '../../assets/svgs/NoApplications';

interface Props {
  onClick: () => void;
}

const NoApplicationsView = ({ onClick }: Props) => {
  return (
    <Stack alignItems="center" mt={4} spacing={3}>
      <NoApplications />
      <Stack alignItems="center" spacing={1}>
        <Typography variant="h3">Add new application </Typography>
        <Typography>Add applications</Typography>
      </Stack>
      <Button variant="contained" startIcon={<Add />} onClick={onClick}>
        New application
      </Button>
    </Stack>
  );
};

export default NoApplicationsView;
