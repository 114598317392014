import { DeleteOutline } from '@mui/icons-material';
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  capitalize,
} from '@mui/material';
import { GridColDef, GridColumnsInitialState } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import * as Sentry from '@sentry/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CheckCircleFilledIcon } from '../../assets/svgs/icons/CheckCircleFilledIcon';
import { ProfileIcon } from '../../assets/svgs/icons/ProfileIcon';
import { QuesionMarkCircleIcon } from '../../assets/svgs/icons/QuesionMarkCircleIcon';
import { DARK_GRAY } from '../../constants/colors';
import {
  OrgUserInvite,
  OrganizationContext,
  OrganizationContextProps,
} from '../../contexts/Organization';
import AlertDialog from '../../dialogs/AlertDialog';
import { useHasFeature } from '../../hooks/useHasFeature';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import {
  deleteInvite,
  deleteUser,
  updateUserRole,
} from '../../services/OrgUsersService';
import PyntDataGrid from '../Common/PyntDataGrid';
import InviteUserDrawer from './InviteUserDrawer';

export default function UsersGrid() {
  const hasChangeRoleFeature = useHasFeature('edit_user_role');
  const hasInviteUserFeature = useHasFeature('invite_user');
  const {
    isLoadingUsers,
    users,
    fetchUsers,
    isLoadingInvites,
    invites,
    fetchInvites,
  } = useContext(OrganizationContext) as OrganizationContextProps;

  const [selectedInvite, setSelectedInvite] = useState<OrgUserInvite>();
  const [selectedInviteId, setSelectedInviteId] = useState<
    string | undefined
  >();
  const [openDeleteInviteDialog, setOpenDeleteInviteDialog] =
    useState<boolean>(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] =
    useState<boolean>(false);
  const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false);
  const [isDeletingInvite, setIsDeletingInvite] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();

  const allUsers = useMemo(
    () =>
      hasInviteUserFeature
        ? !invites || !users
          ? undefined
          : [...(invites || []), ...(users || [])]
        : users,
    [invites, users, hasInviteUserFeature],
  );
  const isLoading = useMemo(
    () =>
      hasInviteUserFeature
        ? isLoadingInvites || isLoadingUsers
        : isLoadingUsers,
    [isLoadingInvites, isLoadingUsers, hasInviteUserFeature],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'user_id',
        headerName: 'User ID',
        width: 90,
        valueGetter: ({ row }) => row.user_id || row.invite_id,
      },
      {
        field: 'user',
        headerName: 'User name',
        width: 150,
        valueGetter: ({ row }) =>
          [row.name, row.lastname].filter((a) => !!a).join(' '),
        renderCell: ({ value }) => (
          <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
            <ProfileIcon color={DARK_GRAY} />
            <Box>{value}</Box>
          </Stack>
        ),
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 160,
      },
      {
        field: 'role',
        headerName: 'Role',
        width: 160,
        valueGetter: ({ row }) => row.role ?? 'Admin',
        renderCell: ({ value, row }) => {
          return hasChangeRoleFeature && !row.invite_id ? (
            <Select
              defaultValue={value || 'Admin'}
              onChange={(v) => updateUserRole(row.user_id, v.target.value)}
              sx={{ width: '90%', height: 32 }}
            >
              <MenuItem value={'Admin'}>Admin</MenuItem>
              <MenuItem value={'User'}>User</MenuItem>
            </Select>
          ) : (
            <>{value}</>
          );
        },
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        width: 160,
        type: 'dateTime',
        valueGetter: ({ row }) =>
          row.created_at ? new Date(row.created_at + 'Z') : null,
        renderCell: ({ value }: { value?: Date }) =>
          value ? (
            <>
              {value.toLocaleString(undefined, {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </>
          ) : (
            <></>
          ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: ({ value }) => (
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {value === 'pending' ? (
              <QuesionMarkCircleIcon size={16} />
            ) : (
              <CheckCircleFilledIcon size={16} />
            )}
            <Box>{capitalize(value || 'Approved')}</Box>
          </Stack>
        ),
      },
      {
        field: 'actions',
        headerName: '',
        width: 50,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <IconButton
            onClick={(e) => {
              e.stopPropagation(); // Don't open the drawer
              if (row.status == 'pending' && row.invite_id) {
                setSelectedInviteId(row.invite_id);
                setOpenDeleteInviteDialog(true);
              } else {
                setSelectedUserId(row.user_id);
                setOpenDeleteUserDialog(true);
              }
            }}
          >
            <DeleteOutline sx={{ color: '#ACACBB' }} />
          </IconButton>
        ),
      },
    ],
    [hasChangeRoleFeature],
  );

  const [gridColumnsState, setGridColumnsState] =
    usePersistedJsonState<GridColumnsInitialState>('UsersGridColumnsState', {
      columnVisibilityModel: {
        user_id: false,
        created_at: false,
      },
    });

  const gridInitialState = useMemo<GridInitialStateCommunity>(() => {
    return {
      sorting: { sortModel: [{ field: 'email', sort: 'desc' }] },
      pinnedColumns: { right: ['actions'] },
      columns: gridColumnsState,
    };
  }, [gridColumnsState]);

  useEffect(() => {
    fetchUsers();
    if (hasInviteUserFeature) {
      fetchInvites();
    }
  }, [hasInviteUserFeature]);

  const handleDeleteUser = useCallback(async () => {
    if (isDeletingUser || !selectedUserId) return;

    setIsDeletingUser(true);

    await deleteUser(selectedUserId as string)
      .catch((e) => {
        console.error(e);
        Sentry.captureException(e);
      })
      .then(async () => {
        await fetchUsers().catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
      })
      .finally(() => {
        setIsDeletingUser(false);
        setOpenDeleteUserDialog(false);
      });
  }, [selectedUserId]);

  const handleDeleteInvite = useCallback(async () => {
    if (isDeletingInvite || !selectedInviteId) return;

    setIsDeletingInvite(true);

    await deleteInvite(selectedInviteId as string)
      .catch((e) => {
        console.error(e);
        Sentry.captureException(e);
      })
      .then(async () => {
        await fetchInvites().catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
      })
      .finally(() => {
        setIsDeletingInvite(false);
        setOpenDeleteInviteDialog(false);
      });
  }, [selectedInviteId]);

  return (
    <>
      <AlertDialog
        title="Delete User"
        content="Are you sure you want to delete this user?"
        open={openDeleteUserDialog}
        onClose={() => {
          setOpenDeleteUserDialog(false);
        }}
        actions={[
          {
            title: 'Cancel',
            variant: 'outlined',
            onClick: () => {
              setOpenDeleteUserDialog(false);
            },
          },
          {
            title: 'Delete',
            variant: 'contained',
            loading: isDeletingUser,
            onClick: () => {
              handleDeleteUser();
            },
          },
        ]}
      />
      <AlertDialog
        title="Delete Invite"
        content="Are you sure you want to delete this invite?"
        open={openDeleteInviteDialog}
        onClose={() => {
          setOpenDeleteInviteDialog(false);
        }}
        actions={[
          {
            title: 'Cancel',
            variant: 'outlined',
            onClick: () => {
              setOpenDeleteInviteDialog(false);
            },
          },
          {
            title: 'Delete',
            variant: 'contained',
            loading: isDeletingInvite,
            onClick: () => {
              handleDeleteInvite();
            },
          },
        ]}
      />
      <InviteUserDrawer
        invite={selectedInvite}
        isOpen={!!selectedInvite}
        onClose={() => setSelectedInvite(undefined)}
      />
      <PyntDataGrid
        autoHeight
        rows={allUsers ?? []}
        getRowId={(row) => row.user_id || row.invite_id}
        loading={!allUsers && isLoading}
        columns={columns}
        initialState={gridInitialState}
        onStateChange={(state) => {
          setGridColumnsState(state.columns);
        }}
        onRowClick={({ row }) => {
          // allow to select text without interaption
          if (window.getSelection()?.type === 'Range') return;

          if (row.invite_id) {
            setSelectedInvite(row);
          }
        }}
      />
    </>
  );
}
