import { IconButton, InputAdornment, SxProps, TextField } from '@mui/material';
import { useContext } from 'react';
import { CopyIcon2 } from '../../assets/svgs/icons/CopyIcon2';
import { SnackbarContext } from '../../contexts/Snackbar';

interface CopyFieldProps {
  text: string;
  title?: string;
  onClick?: () => void;
  sx?: SxProps;
}
const CopyField = ({ text, title, onClick, sx }: CopyFieldProps) => {
  const { show } = useContext(SnackbarContext);

  return (
    <TextField
      size="small"
      variant="filled"
      sx={sx}
      value={title}
      onChange={() => {}}
      onClick={() => {
        navigator.clipboard.writeText(text);
        show('Copied to clipboard');
        onClick?.();
      }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(text);
                show('Copied to clipboard');
                onClick?.();
              }}
            >
              <CopyIcon2 color="#FF8C00" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CopyField;
