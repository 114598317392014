import { Button, Stack, SxProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useCallback, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/Auth';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import { track } from '../../utils/analytics';

const SNACKBAR_CONTAINER: SxProps = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bgcolor: 'white',
  margin: 'auto',
  p: 2,
  pl: 4,
  pr: 4,
  zIndex: 1111,
  width: 'fit-content',
  boxShadow: '0px 4px 8px #00000010',
  borderBottomRightRadius: 16,
  borderBottomLeftRadius: 16,
};

const SNACKBAR_TEXT: SxProps = { color: 'red', fontSize: 14 };

const SNACKBAR_BUTTON: SxProps = {
  color: 'red',
  fontWeight: 600,
  fontSize: 14,
  textDecoration: 'underline',
};

const DIALOG_CONTAINER: SxProps = {
  position: 'fixed',
  top: 24,
  right: 24,
  bgcolor: 'white',
  margin: 'auto',
  p: 2,
  zIndex: 1111,
  boxShadow: '0px 4px 8px #00000010',
  borderRadius: 2,
  width: 440,
};

const DIALOG_TITLE: SxProps = {
  fontWeight: 600,
  textAlign: 'start',
  fontSize: 16,
};

const DIALOG_TEXT: SxProps = {
  color: '#969BA9',
  textAlign: 'start',
  fontWeight: 500,
  fontSize: 16,
};

const DIALOG_BUTTON: SxProps = {
  fontWeight: 600,
  fontSize: 14,
};

const DIALOG_SKIP_BUTTON: SxProps = {
  color: '#969BA9',
  fontWeight: 500,
  fontSize: 14,
};

export default function PlanManagement() {
  const { license, planExpirationInDays } = useContext(AuthContext);
  const [showTrialDialog, setShowTrialDialog] = usePersistedJsonState(
    'showTrialDialog',
    true,
    sessionStorage,
  );

  const onScheduleMeetingClick = useCallback(() => {
    const url = 'https://meetings-eu1.hubspot.com/tural-mirzayev/upgrade-pynt';
    window.open(url, '_blank');
  }, []);

  return (
    <>
      {planExpirationInDays < 0 && <Navigate to={'/expired'} />}
      {license === 'trial' && planExpirationInDays > 0 && (
        <Box sx={SNACKBAR_CONTAINER}>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography sx={SNACKBAR_TEXT}>
              {`Your trial period expires in ${planExpirationInDays} days, to unlock Pynt Pro features `}
            </Typography>
            <Button
              variant="text"
              size="small"
              sx={SNACKBAR_BUTTON}
              onClick={() => {
                track('web_app_top_banner_upgrade_button_click');
                onScheduleMeetingClick();
              }}
            >
              Contact our team.
            </Button>
            <Typography sx={SNACKBAR_TEXT}></Typography>
          </Stack>
        </Box>
      )}
      {license === 'trial' && planExpirationInDays > 0 && showTrialDialog && (
        <Box sx={DIALOG_CONTAINER}>
          <Stack
            direction={'column'}
            justifyContent={'left'}
            alignItems={'start'}
            spacing={2}
          >
            <Stack
              direction={'column'}
              justifyContent={'left'}
              alignItems={'start'}
              spacing={0.5}
            >
              <Typography sx={DIALOG_TITLE}>
                {`Your trial period is scheduled to expire in ${planExpirationInDays} days.`}
              </Typography>
              <Typography sx={DIALOG_TEXT}>
                Contact our team to unlock Pynt Pro features.
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'end'}
              alignItems={'center'}
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Button
                variant="text"
                sx={DIALOG_SKIP_BUTTON}
                onClick={() => {
                  track('web_app_side_banner_skip_button_click');
                  setShowTrialDialog(false);
                }}
              >
                Skip
              </Button>
              <Button
                variant="contained"
                size="medium"
                sx={DIALOG_BUTTON}
                onClick={() => {
                  track('web_app_side_banner_upgrade_button_click');
                  onScheduleMeetingClick();
                }}
              >
                Contact us
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
}
