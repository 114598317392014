export const RunFirstScan = () => {
  return (
    <svg
      width="233"
      height="248"
      viewBox="0 0 233 248"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.842 91.8847C161.234 73.1564 153.279 52.7718 139.263 40.1681C124.882 27.2359 104.476 23.8253 85.6615 26.9764C67.938 29.9449 52.0387 40.7422 42.5783 56.3615C34.0765 70.3983 37.7842 87.4762 39.0122 103.996C40.2989 121.306 37.0122 141.417 49.7548 152.905C62.6708 164.549 82.9831 164.246 99.0259 158.101C112.418 152.971 114.378 135.296 123.996 124.468C134.829 112.273 154.901 108.13 157.842 91.8847Z"
        fill="#E6E7EA"
      />
      <g filter="url(#filter0_d_837_24702)">
        <path
          d="M75.9105 48.3301H138.178C140.804 48.3301 143.318 49.3954 145.144 51.2824L162.38 69.0929C164.129 70.9007 165.107 73.3179 165.107 75.8337V182.407C165.107 187.761 160.768 192.1 155.414 192.1H75.9105C70.5571 192.1 66.2173 187.761 66.2173 182.407V58.0233C66.2173 52.6699 70.5571 48.3301 75.9105 48.3301Z"
          fill="white"
        />
      </g>
      <path
        d="M75.9105 48.3301H138.178C140.804 48.3301 143.318 49.3954 145.144 51.2824L162.38 69.0929C164.129 70.9007 165.107 73.3179 165.107 75.8337V182.407C165.107 187.761 160.768 192.1 155.414 192.1H75.9105C70.5571 192.1 66.2173 187.761 66.2173 182.407V58.0233C66.2173 52.6699 70.5571 48.3301 75.9105 48.3301Z"
        fill="white"
      />
      <path
        d="M140.128 53.7705V72.8472C140.128 73.6875 140.809 74.3686 141.65 74.3686H159.515"
        stroke="#E9EDF2"
        strokeWidth="2.4233"
        strokeLinecap="round"
      />
      <path
        d="M85.1963 166.238H108.18M85.1963 151.135H142.326M85.1963 136.688H142.326M85.1963 120.271H142.326M85.1963 105.168H142.326M85.1963 89.4082H142.326"
        stroke="#E9EDF2"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M53.4949 21.0537H16.5398C15.8706 21.0537 15.3281 21.5962 15.3281 22.2654V62.2499"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M15.3281 71.335L15.3281 84.6631"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M23.8086 31.3506V44.0729"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M49.86 214.31H16.5398C15.8706 214.31 15.3281 213.767 15.3281 213.098V174.325"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M23.8086 204.013V191.29"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M178.901 21.0537H215.856C216.525 21.0537 217.067 21.5962 217.067 22.2654V62.2499"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M208.587 31.3506V40.438"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M217.067 71.335V76.7874"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M181.323 214.31H215.855C216.524 214.31 217.066 213.767 217.066 213.098V173.113"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M208.586 205.224V196.136"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M217.066 163.419V155.543"
        stroke="#BCC1D1"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <path
        d="M2 114.521H52.8891"
        stroke="#FF8C00"
        strokeWidth="3.21888"
        strokeLinecap="round"
      />
      <path
        d="M180.111 114.522H231"
        stroke="#FF8C00"
        strokeWidth="3.21888"
        strokeLinecap="round"
      />
      <path
        d="M27.4453 107.248H39.5618"
        stroke="#FF8C00"
        strokeWidth="3.21888"
        strokeLinecap="round"
      />
      <path
        d="M192.229 122.998H211.615"
        stroke="#FF8C00"
        strokeWidth="3.21888"
        strokeLinecap="round"
      />
      <path
        d="M85.1963 105.169H142.326M85.1963 89.4092H142.326"
        stroke="#FF8C00"
        strokeWidth="3.67872"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d_837_24702"
          x="14.3825"
          y="0.335695"
          width="202.559"
          height="247.439"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.83996" />
          <feGaussianBlur stdDeviation="25.9172" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.351111 0 0 0 0 0.381833 0 0 0 0 0.658333 0 0 0 0.21 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_837_24702"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_837_24702"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
