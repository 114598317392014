import {
  KeyboardArrowDownRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { Box, SxProps } from '@mui/material';
const BOX: SxProps = {
  width: 32,
  height: 32,
  borderRadius: 16,
  // bgcolor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // boxShadow: '0px 4px 15px rgba(19, 31, 44, 0.1)',
};

type ArrowDirection = 'LEFT' | 'RIGHT' | 'TOP' | 'BOTTOM';

interface Props {
  direction: ArrowDirection;
}

const Arrow = ({ direction }: Props) => {
  switch (direction) {
    case 'BOTTOM':
      return (
        <Box sx={BOX}>
          <KeyboardArrowDownRounded />
        </Box>
      );

    case 'LEFT':
      return (
        <Box sx={BOX}>
          <KeyboardArrowLeftRounded />
        </Box>
      );

    case 'RIGHT':
      return (
        <Box sx={BOX}>
          <KeyboardArrowRightRounded />
        </Box>
      );

    case 'TOP':
    default:
      return (
        <Box sx={BOX}>
          <KeyboardArrowUpRounded />
        </Box>
      );
  }
};

export default Arrow;
