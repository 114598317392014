import { SvgIconProps } from './SvgIconProps';

export const EditIcon2 = ({ color = '#ACACBB', size = 16 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_8516)">
        <path
          d="M15.222 0.779789C14.1822 -0.259867 12.4992 -0.259992 11.4593 0.779789L1.62337 10.6145C1.55781 10.68 1.50356 10.7653 1.47241 10.8588L0.0330309 15.1764C-0.129469 15.6639 0.3355 16.1297 0.823593 15.9669L5.14178 14.5277C5.23309 14.4973 5.31881 14.444 5.38612 14.3768L15.222 4.54204C16.2593 3.50479 16.2593 1.81704 15.222 0.779789ZM1.61419 14.386L2.33847 12.2133L3.78709 13.6618L1.61419 14.386ZM4.94419 13.051L2.94928 11.0564L11.3015 2.70513L13.2964 4.69982L4.94419 13.051ZM14.3381 3.65826L14.1803 3.81604L12.1854 1.82135L12.3432 1.6636C12.8932 1.1137 13.7881 1.1137 14.3381 1.6636C14.8881 2.21351 14.8881 3.10832 14.3381 3.65826Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_8516">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
