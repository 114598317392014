import { LinkRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DARK_GRAY } from '../../constants/colors';
import APISourceIcon from '../APISources/APISourceIcon';
import PyntDataGrid from '../Common/PyntDataGrid';

const columns: GridColDef[] = [
  {
    field: 'sourceInfo',
    headerName: 'Sources List',
    width: 180,
    renderCell: ({ value }) => (
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <APISourceIcon type={value} />
        <Box>{value}</Box>
      </Stack>
    ),
  },
  {
    field: 'firstSeen',
    headerName: 'First Seen',
    width: 160,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'lastSeen',
    headerName: 'Last Seen',
    width: 160,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'host',
    headerName: 'Host',
    width: 180,
    renderCell: ({ value }) => (
      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'center'}
        sx={{ cursor: 'pointer' }}
        onClick={() => window.open(value, '_blank')}
      >
        <LinkRounded sx={{ color: 'blue' }} fontSize={'small'} />
        <Box sx={{ color: DARK_GRAY }}>
          <u>{value}</u>
        </Box>
      </Stack>
    ),
  },
];

export default function SourceInfoGrid() {
  const apiCatalog = [] as any;
  const isLoadingAPICatalog = false;

  return (
    <PyntDataGrid
      autoHeight
      sx={{ width: '100%' }}
      rows={[
        {
          id: '123',
          sourceInfo: 'aws',
          firstSeen: 'Jan 5, 2023',
          lastSeen: 'Jun 12, 2023',
          host: 'goat.pynt.io',
        },
        {
          id: '1223',
          sourceInfo: 'swagger',
          firstSeen: 'Jan 5, 2023',
          lastSeen: 'Jun 12, 2023',
          host: 'goat.pynt.io',
        },
      ]}
      loading={!apiCatalog && isLoadingAPICatalog}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      hideFooterPagination
      initialState={{
        sorting: { sortModel: [{ field: 'timestamp', sort: 'desc' }] },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
