import awsIcon from '../../assets/images/awsIcon.png';
import azureIcon from '../../assets/images/azureIcon.png';
import kongIcon from '../../assets/images/kongIcon.png';
import postmanIcon from '../../assets/images/postman.png';
import pyntIcon from '../../assets/images/pyntIcon.png';
import swaggerIcon from '../../assets/images/swaggerIcon.png';

interface Props {
  type:
    | 'swagger'
    | 'swagger_file'
    | 'postman_file'
    | 'aws_api_gw'
    | 'azure_api_gw'
    | 'kong_api_gw'
    | 'scan';
  size?: number;
}

export default function APISourceIcon({ type, size = 20 }: Props) {
  switch (type) {
    case 'aws_api_gw':
      return <img src={awsIcon} height={size} width={size} />;
    case 'azure_api_gw':
      return <img src={azureIcon} height={size} width={size} />;
    case 'kong_api_gw':
      return <img src={kongIcon} height={size} width={size} />;
    case 'scan':
      return <img src={pyntIcon} height={size} width={size} />;
    case 'postman_file':
      return <img src={postmanIcon} height={size} width={size} />;
    case 'swagger':
    case 'swagger_file':
    default:
      return <img src={swaggerIcon} height={size} width={size} />;
  }
}
