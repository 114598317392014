import dayjs from 'dayjs';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext, UserContextProps } from '../../contexts/User';
import { track } from '../../utils/analytics';
import APICatalogGrid from '../APICatalog/APICatalogGrid';
import ViewAllButton from '../Common/ViewAllButton';
import CustomCard from '../CustomCard/CustomCard';

const LastWeekAPIs = () => {
  const navigate = useNavigate();
  const { setSelectedApplicationId } = useContext(
    UserContext,
  ) as UserContextProps;

  return (
    <CustomCard
      title="Last Week APIs"
      sx={{ height: 360 }}
      action={
        <ViewAllButton
          onClick={() => {
            track('web_app_overview_last_week_apis_view_all_button_click');

            setSelectedApplicationId('*');
            navigate('/dashboard/api-catalog');
          }}
        />
      }
    >
      <APICatalogGrid
        id="LastWeekAPIsGrid"
        autoHeight={false}
        autoPageSize={true}
        hideFooter
        sorting={[{ field: 'properties.last_scan_time', sort: 'desc' }]}
        filter={{
          items: [
            {
              id: 1,
              field: 'scan_time',
              operator: 'onOrAfter',
              value: dayjs().subtract(8, 'days').format('YYYY-MM-DDTHH:mm'),
            },
          ],
        }}
        limit={4}
      />
    </CustomCard>
  );
};

export default LastWeekAPIs;
