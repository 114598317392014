import { Box, Stack, SxProps, Typography } from '@mui/material';
import { CheckIcon } from '../../assets/svgs/icons/CheckIcon';

const DISABLED_COLOR_TEXT = '#CCD2E966';

const NUMBER_CONTAINER: SxProps = {
  backgroundColor: '#ff8237',
  borderRadius: '50%',
  height: 50,
  width: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const FUTURE_NUMBER_CONTAINER: SxProps = {
  ...NUMBER_CONTAINER,
  backgroundColor: 'transparent',
  border: `1.5px ${NUMBER_CONTAINER.backgroundColor} solid`,
};

const NUMBER_TEXT: SxProps = {
  // eslint-disable-next-line quotes
  fontFeatureSettings: "'lnum' 1",
  color: 'white',
  fontWeight: 700,
  fontSize: 24,
  // eslint-disable-next-line quotes
  fontFamily: "'Raleway', sans-serif;",
};

const FUTURE_NUMBER_TEXT: SxProps = {
  ...NUMBER_TEXT,
  color: DISABLED_COLOR_TEXT,
};

const NAME_TEXT: SxProps = {
  fontSize: 18,
  color: DISABLED_COLOR_TEXT,
  fontWeight: 500,
  whiteSpace: 'break-spaces',
  // eslint-disable-next-line quotes
  fontFamily: "'Raleway', sans-serif;",
  textAlign: 'start',
};

const SELECTED_NAME_TEXT: SxProps = {
  ...NAME_TEXT,
  color: 'white',
  fontWeight: 700,
};

export interface BurpOnboardStepData {
  name: string;
  path: string;
}

interface Props {
  index: number;
  selected: boolean;
  passed: boolean;
  step: BurpOnboardStepData;
}

export default function BurpOnboardStep({
  index,
  step,
  selected,
  passed,
}: Props) {
  return (
    <Stack direction="row" alignItems={'center'} spacing={3}>
      <Box sx={passed || selected ? NUMBER_CONTAINER : FUTURE_NUMBER_CONTAINER}>
        {passed ? (
          <CheckIcon />
        ) : (
          <Typography sx={selected ? NUMBER_TEXT : FUTURE_NUMBER_TEXT}>
            {index + 1}
          </Typography>
        )}
      </Box>
      <Typography sx={selected ? SELECTED_NAME_TEXT : NAME_TEXT}>
        {step.name}
      </Typography>
    </Stack>
  );
}
