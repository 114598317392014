import { Box, ButtonBase, Stack, SxProps, Typography } from '@mui/material';
import { JiraIcon } from '../../assets/svgs/icons/JiraIcon';
import { VulnerabilityTicket } from '../../models/vulnerability';

const BADGE: SxProps = {
  'bgcolor': '#E8E8E8',
  'borderRadius': 0.5,
  'pl': 0.7,
  'pr': 0.7,
  'pt': 0.5,
  'pb': 0.5,
  ':hover': {
    bgcolor: '#DDDDDD',
  },
};

const STATUS: SxProps = {
  bgcolor: '#D3D3D3',
  borderRadius: 0.5,
  pl: 0.7,
  pr: 0.7,
  pt: 0.3,
  pb: 0.3,
};

const TEXT: SxProps = {
  fontSize: 11,
  color: 'black',
  letterSpacing: '-0.3 px',
};

interface Props {
  ticket: VulnerabilityTicket;
}

export default function JiraTicketingBadge({ ticket }: Props) {
  return (
    <ButtonBase
      sx={BADGE}
      disabled={!ticket.link}
      onClick={(e) => {
        e.stopPropagation();
        window.open(ticket.link ?? '', '_blank');
      }}
    >
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        direction={'row'}
        spacing={1}
      >
        <JiraIcon size={16} />
        <Typography variant="body1" sx={TEXT}>
          {ticket.name}
        </Typography>
        {ticket.status ? (
          <Box sx={STATUS}>
            <Typography variant="body1" sx={TEXT}>
              {ticket.status}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Stack>
    </ButtonBase>
  );
}
