import { SvgIconProps } from './SvgIconProps';

export const InsertDriveFileIcon = ({
  color = 'black',
  size = 24,
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6654 1.66663H4.9987C4.08203 1.66663 3.34036 2.41663 3.34036 3.33329L3.33203 16.6666C3.33203 17.5833 4.0737 18.3333 4.99036 18.3333H14.9987C15.9154 18.3333 16.6654 17.5833 16.6654 16.6666V6.66663L11.6654 1.66663ZM4.9987 16.6666V3.33329H10.832V7.49996H14.9987V16.6666H4.9987Z"
        fill={color}
      />
    </svg>
  );
};
