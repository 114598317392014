import { SvgIconProps } from './SvgIconProps';

export const IntegrationInstructionsIcon = ({
  color = 'black',
  size = 24,
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16667 12.6416L7.35833 10.8333L9.16667 9.02496L7.99167 7.84163L5 10.8333L7.99167 13.825L9.16667 12.6416Z"
        fill={color}
      />
      <path
        d="M12.0083 13.825L15 10.8333L12.0083 7.84163L10.8333 9.02496L12.6417 10.8333L10.8333 12.6416L12.0083 13.825Z"
        fill={color}
      />
      <path
        d="M15.8333 3.33329H12.35C12 2.36663 11.0833 1.66663 10 1.66663C8.91667 1.66663 8 2.36663 7.65 3.33329H4.16667C4.05 3.33329 3.94167 3.34163 3.83333 3.36663C3.50833 3.43329 3.21667 3.59996 2.99167 3.82496C2.84167 3.97496 2.71667 4.15829 2.63333 4.35829C2.55 4.54996 2.5 4.76663 2.5 4.99996V16.6666C2.5 16.8916 2.55 17.1166 2.63333 17.3166C2.71667 17.5166 2.84167 17.6916 2.99167 17.85C3.21667 18.075 3.50833 18.2416 3.83333 18.3083C3.94167 18.325 4.05 18.3333 4.16667 18.3333H15.8333C16.75 18.3333 17.5 17.5833 17.5 16.6666V4.99996C17.5 4.08329 16.75 3.33329 15.8333 3.33329ZM10 3.12496C10.3417 3.12496 10.625 3.40829 10.625 3.74996C10.625 4.09163 10.3417 4.37496 10 4.37496C9.65833 4.37496 9.375 4.09163 9.375 3.74996C9.375 3.40829 9.65833 3.12496 10 3.12496ZM15.8333 16.6666H4.16667V4.99996H15.8333V16.6666Z"
        fill={color}
      />
    </svg>
  );
};
