import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { ReactNode } from 'react';

export type IconTextFieldProps = TextFieldProps & {
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
};

const IconTextField = ({
  iconStart,
  iconEnd,
  InputProps,
  ...props
}: IconTextFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment
            position="start"
            sx={{
              '&.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
                {
                  marginTop: '0 !important',
                },
            }}
          >
            {iconStart}
          </InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null,
      }}
    />
  );
};

export default IconTextField;
