export function emailIsValid(email: string) {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    )
  ) {
    return true;
  }
  return false;
}

export function fullNameIsValid(fullName: string) {
  if (/^([\w]{3,})+\s+([\w\s]{3,})+$/.test(fullName)) {
    return true;
  }
  return false;
}

export function passwordIsValid(password: string) {
  if (password.length >= 8) {
    return true;
  }
  return false;
}

export function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}
