import Hotjar from '@hotjar/browser';
import { Organization } from '../models/organization';
import { User } from '../models/user';

export const updateHotjar = async (
  user?: User,
  organization?: Organization,
) => {
  if (user != null) {
    if (Hotjar.isReady()) {
      Hotjar.identify(user.uid, {
        email: user.email,
        name: [user.first_name, user.last_name].filter((x) => !!x).join(' '),
        organizationId: user.organization_id ?? '',
        organizationName: organization?.name ?? '',
        createdAt: user.created_at,
        // avatar: user.photoURL,
      });
    }
  }
};
