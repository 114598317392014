import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BRIGHT_BLUE, DARK_GRAY } from '../../constants/colors';
import { AuthContext } from '../../contexts/Auth';
import { APICatalogItem } from '../../models/apiCatalog';
import { track } from '../../utils/analytics';
import APIMethodChip from '../Common/APIMethodChip';
import Arrow from '../Common/Arrow';

const CONTAINER: SxProps = {
  p: 3,
  transition: 'all 0.3s ease-in-out',
  position: 'sticky',
  top: 80,
  zIndex: 1000,
};

const CONTAINER_STICKY: SxProps = {
  boxShadow: '0px 4px 15px rgba(19, 31, 44, 0.1)',
};

interface Props {
  endpointId: string;
  endpoint?: APICatalogItem;
}

export default function APIDetailedViewHeader({ endpoint }: Props) {
  const navigate = useNavigate();
  const { isProd } = useContext(AuthContext);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0 && !isSticky) {
        setIsSticky(true);
      } else if (scrollTop === 0 && isSticky) {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky]);

  return (
    <Paper
      elevation={0}
      sx={[CONTAINER, isSticky ? CONTAINER_STICKY : []].flat()}
    >
      <Grid container alignItems={'center'}>
        <Grid item xs={12} md={5}>
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent="space-between"
          >
            <Stack direction={'row'} alignItems={'center'} spacing={3}>
              <IconButton
                onClick={() => {
                  track('web_app_api_detailed_view_header_back_button_click', {
                    endpoint: endpoint?.endpoint_id,
                  });
                  navigate(-1);
                }}
              >
                <Arrow direction="LEFT" />
              </IconButton>

              <Stack direction="column" alignItems={'start'} spacing={0.5}>
                <Stack direction={'row'} spacing={2}>
                  <APIMethodChip method={endpoint?.method ?? 'get'} />
                  <Typography variant="subtitle1">{endpoint?.path}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={0} md={1} />
        {!isProd && (
          <Grid item xs={12} md={6}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-around'}
              spacing={3}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={3}>
                <Typography variant="subtitle1" sx={{ color: DARK_GRAY }}>
                  Type
                </Typography>
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    bgcolor: BRIGHT_BLUE,
                    pl: 2,
                    pr: 2,
                    borderRadius: 2,
                    fontWeight: 500,
                  }}
                >
                  Json
                </Box>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} spacing={3}>
                <Typography variant="subtitle1" sx={{ color: DARK_GRAY }}>
                  Description
                </Typography>
                <Typography variant="subtitle2">Login to system</Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
