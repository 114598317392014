import { SvgIconProps } from './SvgIconProps';

export const SettingsIcon = ({ color = 'white', size = 22 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_399_9068)">
        <path
          d="M10.9376 19.237C10.3575 19.572 9.64273 19.572 9.0626 19.237L2.90233 15.6804C2.3222 15.3455 1.96483 14.7265 1.96483 14.0566L1.96483 6.94338C1.96483 6.27351 2.3222 5.65452 2.90233 5.31958L9.0626 1.76295C9.64273 1.42802 10.3575 1.42802 10.9376 1.76295L17.0979 5.31958C17.678 5.65452 18.0354 6.2735 18.0354 6.94338L18.0354 14.0566C18.0354 14.7265 17.678 15.3455 17.0979 15.6804L10.9376 19.237Z"
          stroke={color}
          strokeWidth="1.25"
        />
        <path
          d="M10.0001 7.375C11.726 7.375 13.1251 8.77411 13.1251 10.5C13.1251 12.2259 11.726 13.625 10.0001 13.625C8.27424 13.625 6.87512 12.2259 6.87512 10.5C6.87512 8.77411 8.27424 7.375 10.0001 7.375Z"
          stroke={color}
          strokeWidth="1.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_399_9068">
          <rect
            width="20"
            height="20"
            fill={color}
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
