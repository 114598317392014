import { get } from './BaseService';

export type PyntFilter = {
  where?: {
    [key: string]: string | number | { [key: string]: string | number };
  };
  sort?: { [key: string]: number };
  limit?: number;
  offset?: number;
};

export async function getTags(filter: PyntFilter = {}) {
  const res = await get('/tag', {
    params: { filter: JSON.stringify(filter) },
  });

  return res.data;
}
