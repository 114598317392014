import { SvgIconProps } from './SvgIconProps';

export const CopyIcon2 = ({ color = '#ACACBB', size = 16 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_282_11990)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.54521 -0.000244141C5.34023 -0.000244141 4.36339 0.976592 4.36339 2.18157V4.36339H2.18157C0.976592 4.36339 -0.000244141 5.34023 -0.000244141 6.54521V13.8179C-0.000244141 15.023 0.976592 15.9998 2.18157 15.9998H9.4543C10.6593 15.9998 11.6361 15.023 11.6361 13.8179V11.6361H13.8179C15.023 11.6361 15.9998 10.6593 15.9998 9.4543V2.18157C15.9998 0.976592 15.023 -0.000244141 13.8179 -0.000244141H6.54521ZM11.6361 10.1816H13.8179C14.2196 10.1816 14.5452 9.85597 14.5452 9.4543V2.18157C14.5452 1.77992 14.2196 1.4543 13.8179 1.4543H6.54521C6.14355 1.4543 5.81794 1.77992 5.81794 2.18157V4.36339H9.4543C10.6593 4.36339 11.6361 5.34023 11.6361 6.54521V10.1816ZM1.4543 6.54521C1.4543 6.14355 1.77992 5.81794 2.18157 5.81794H9.4543C9.85597 5.81794 10.1816 6.14355 10.1816 6.54521V13.8179C10.1816 14.2196 9.85597 14.5452 9.4543 14.5452H2.18157C1.77992 14.5452 1.4543 14.2196 1.4543 13.8179V6.54521Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_282_11990">
          <rect width={'16'} height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
