interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const GithubIcon = ({
  width = 35,
  height = 32,
  color = 'white',
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5001 0C8.27588 0 0.804688 7.34598 0.804688 16.406C0.804688 23.656 5.58792 29.8041 12.2202 31.9717C13.0549 32.1261 13.361 31.6183 13.361 31.1828C13.361 30.7933 13.3471 29.761 13.3402 28.3933C8.69605 29.3825 7.71658 26.1923 7.71658 26.1923C6.95694 24.2988 5.85922 23.7923 5.85922 23.7923C4.3469 22.7753 5.97609 22.7962 5.97609 22.7962C7.65259 22.9103 8.53327 24.4866 8.53327 24.4866C10.0219 26.9951 12.4414 26.2702 13.3958 25.8514C13.5461 24.7899 13.976 24.0678 14.4532 23.6574C10.7454 23.247 6.84842 21.8362 6.84842 15.5504C6.84842 13.7598 7.49537 12.2962 8.56666 11.1484C8.37883 10.7338 7.81537 9.0656 8.71274 6.80616C8.71274 6.80616 10.111 6.36651 13.304 8.48822C14.6396 8.1237 16.0587 7.94284 17.4778 7.93449C18.8969 7.94284 20.316 8.1237 21.6517 8.48822C24.8238 6.36651 26.222 6.80616 26.222 6.80616C27.1194 9.0656 26.556 10.7338 26.389 11.1484C27.4533 12.2962 28.1003 13.7598 28.1003 15.5504C28.1003 21.8529 24.1977 23.24 20.483 23.6435C21.0673 24.136 21.6099 25.1419 21.6099 26.6793C21.6099 28.8747 21.5891 30.6388 21.5891 31.1717C21.5891 31.6016 21.8812 32.115 22.7369 31.9508C29.4164 29.7971 34.1955 23.6449 34.1955 16.406C34.1955 7.34598 26.7201 0 17.5001 0Z"
        fill={color}
      />
    </svg>
  );
};
