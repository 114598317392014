import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import TextFieldTitle from '../components/Common/TextFieldTitle';
import { AuthContext } from '../contexts/Auth';
import { updateOrgName } from '../services/OrgService';
import { LightLogo } from '../assets/svgs/LightLogo';
import { SigninBackground } from '../assets/svgs/SigninBackground';
import { useNavigate } from 'react-router-dom';

const GRID_CONTAINER: SxProps = {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
};

const GRID_LEFT: SxProps = {
  bgcolor: 'secondary.main',
  height: '100vh',
  position: 'relative',
};

const GRID_RIGHT: SxProps = {
  bgcolor: 'white',
  height: '100vh',
};

const LogoContainer: SxProps = {
  position: 'fixed',
  top: 32,
  left: 32,
};

const LOGIN_BOX: SxProps = {
  p: 3,
  textAlign: 'start',
  width: 600,
};

const TITLE: SxProps = {
  fontSize: 32,
  fontWeight: 700,
};

const SUBTITLE: SxProps = {
  fontSize: 16,
};

const TEXT_FIELD: SxProps = {
  '.MuiFilledInput-root': {
    backgroundColor: 'white',
    border: 'solid 1px #D3D3D3',
    minWidth: 320,
  },
};

const SignupOnboarding = () => {
  const navigate = useNavigate();

  const { organization, refreshUser } = useContext(AuthContext);
  const [organizationName, setOrganizationName] = useState(organization?.name);
  const [loadingSaveName, setLoadingSaveName] = useState(false);

  const onNameUpdateClick = useCallback(() => {
    setLoadingSaveName(true);
    updateOrgName(organizationName as string)
      .then(() => {
        refreshUser();
        navigate('/dashboard/instructions');
      })
      .finally(() => setLoadingSaveName(false));
  }, [organizationName]);

  return (
    <Grid
      container
      sx={GRID_CONTAINER}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid item xs={3} sx={GRID_LEFT}>
        <Box sx={LogoContainer}>
          <LightLogo />
        </Box>
        <SigninBackground width={'100%'} height={'100%'} />
      </Grid>
      <Grid
        container
        item
        xs={9}
        justifyContent={'center'}
        alignItems={'center'}
        sx={GRID_RIGHT}
      >
        <Box sx={LOGIN_BOX}>
          <Stack direction={'column'} spacing={5}>
            <Stack direction={'column'} spacing={1}>
              <Typography sx={TITLE}>Welcome to Pynt.</Typography>
              <Typography sx={SUBTITLE}>
                {'What is the name of your organization?'}
              </Typography>
            </Stack>
            <Stack
              direction={'column'}
              spacing={0}
              justifyContent={'start'}
              alignItems={'start'}
            >
              <TextFieldTitle title="Organization name" />
              <Stack
                direction={'row'}
                spacing={2}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <TextField
                  size="small"
                  variant="filled"
                  sx={TEXT_FIELD}
                  onChange={(e) => setOrganizationName(e.target.value)}
                />
                <LoadingButton
                  loading={loadingSaveName}
                  onClick={onNameUpdateClick}
                  disabled={organizationName === organization?.name}
                  variant="contained"
                >
                  Continue
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignupOnboarding;
