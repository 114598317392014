import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AddIcon } from '../assets/svgs/icons/AddIcon';
import ApplicationsGrid from '../components/Applications/ApplicationsGrid';
import EditApplicationDrawer from '../components/Setup/EditApplicationDrawer';
import NoApplicationsView from '../components/Setup/NoApplicationsView';
import { OnboardContext } from '../contexts/Onboard';
import { UserContext, UserContextProps } from '../contexts/User';
import { useHasFeature } from '../hooks/useHasFeature';
import { track } from '../utils/analytics';

import { DashboardAppBar, GridWrapper } from '../components/Common';
import ApplicationsSummaryReportButton from '../reports/ApplicationsSummary/ApplicationsSummaryReportButton';

const Applications = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state: onboardState, setState: setOnboardState } =
    useContext(OnboardContext);
  const { applications } = useContext(UserContext) as UserContextProps;
  const hasCreateApplicationFeature = useHasFeature('create_application');

  const [isCreateApplicationDrawerOpen, setIsCreateApplicationDrawerOpen] =
    useState(false);

  const filter = useMemo(() => {
    const val = searchParams.get('filter');
    if (!val) return null;

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [searchParams]);

  const sorting = useMemo(() => {
    const val = searchParams.get('sorting');
    if (!val) return null;

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [searchParams]);

  useEffect(() => {
    const showTour = searchParams.get('tour');
    if (showTour !== 'true') return;
    if (onboardState.run) return;

    let show = true;
    setTimeout(() => {
      if (!show) return;

      track('web_app_show_create_inventory_onboard');
      setOnboardState((s) => ({
        ...s,
        steps: [
          {
            target: '#add-application-button',
            content: (
              <Stack alignItems={'stretch'}>
                <Box paddingX={2} paddingY={1}>
                  <Typography variant="subtitle1" textAlign="start">
                    Add new application
                  </Typography>
                </Box>
                <Divider variant="fullWidth" />
                <Stack paddingX={2} paddingY={1} spacing={2}>
                  <Typography textAlign={'start'}>
                    Integrate your first app with Pynt for automated API
                    cataloging.
                  </Typography>
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    spacing={1}
                  >
                    <Button
                      variant="text"
                      onClick={() => {
                        track(
                          'web_app_create_inventory_onboard_skip_button_click',
                          { step: 0 },
                        );
                        setSearchParams((s) => {
                          const newSearchParams = new URLSearchParams(s);
                          newSearchParams.delete('tour');
                          return newSearchParams;
                        });
                        setOnboardState((s) => ({
                          ...s,
                          run: false,
                          stepIndex: 0,
                        }));
                      }}
                    >
                      Skip
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            ),
            handleTargetClick: () => {
              setOnboardState((s) => ({
                ...s,
                stepIndex: 1,
                run: false,
              }));
            },
            disableCloseOnEsc: true,
            disableBeacon: true,
            hideCloseButton: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            hideFooter: true,
            styles: {
              tooltip: { padding: 0 },
              tooltipContent: { padding: 0 },
            },
          },
        ],
        run: true,
      }));
    }, 200);

    return () => {
      show = false;
    };
  }, []);

  const onNewApplicationButtonClick = useMemo(() => {
    return () => {
      track('web_app_add_new_application_button_click');

      setIsCreateApplicationDrawerOpen(true);
    };
  }, []);

  return (
    <>
      <EditApplicationDrawer
        mode={'create'}
        isOpen={isCreateApplicationDrawerOpen}
        setIsOpen={setIsCreateApplicationDrawerOpen}
        onSave={(app, created) => {
          if (searchParams.get('tour') === 'true') {
            navigate(`/dashboard/application/${app.app_id}?tour=true`);
          }

          if (created) {
            navigate(`/dashboard/application/${app.app_id}`);
          }
        }}
      />
      {!applications || applications.length ? (
        <>
          <DashboardAppBar
            title={'Applications'}
            endActions={
              hasCreateApplicationFeature ? (
                <Stack direction={'row'} spacing={2}>
                  <Button
                    id={'add-application-button'}
                    startIcon={<AddIcon color="white" />}
                    variant="contained"
                    onClick={() => setIsCreateApplicationDrawerOpen(true)}
                  >
                    Add
                  </Button>
                  <ApplicationsSummaryReportButton />
                </Stack>
              ) : (
                <></>
              )
            }
          />
          <Container maxWidth={false}>
            <GridWrapper>
              <ApplicationsGrid
                id="ApplicationsGrid"
                autoHeight={false}
                filter={filter}
                sorting={sorting}
              />
            </GridWrapper>
          </Container>
        </>
      ) : (
        <Stack justifyContent="center" minHeight={'calc(100vh - 100px)'}>
          <NoApplicationsView onClick={onNewApplicationButtonClick} />
        </Stack>
      )}
    </>
  );
};

export default Applications;
