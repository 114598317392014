import * as Sentry from '@sentry/react';
import { createContext, useContext, useEffect, useState } from 'react';
import { getEndpointsCount } from '../services/EndpointsService';
import { getScansCount } from '../services/ScansService';
import { AuthContext } from './Auth';

export interface APISource {
  source_id: string;
  name: string;
  type: 'swagger' | 'aws_api_gw' | 'azure_api_gw' | 'kong_api_gw';
  application: string;
}

export interface Application {
  app_id: string;
  name: string;
}

export interface OverviewContextProps {
  totalApisCount?: number;
  isLoadingTotalApisCount: boolean;
  totalScansCount?: number;
  isLoadingTotalScansCount: boolean;
}

export const OverviewContext = createContext<OverviewContextProps | null>(null);

function OverviewContextProvider(props: any) {
  const { isAuthenticated } = useContext(AuthContext);
  const [totalApisCount, setTotalApisCount] = useState<number | undefined>();
  const [totalScansCount, setTotalScansCount] = useState<number | undefined>();

  useEffect(() => {
    if (isAuthenticated) {
      getTotals();
    }
  }, [isAuthenticated]);

  const getTotals = async () => {
    const apiCatalogCount = await getEndpointsCount().catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });

    setTotalApisCount(apiCatalogCount?.count);

    const scansCount = await getScansCount().catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });

    setTotalScansCount(scansCount?.count);
  };

  return (
    <OverviewContext.Provider
      value={{
        totalApisCount,
        isLoadingTotalApisCount: totalApisCount === undefined,
        totalScansCount,
        isLoadingTotalScansCount: totalScansCount === undefined,
      }}
    >
      {props.children}
    </OverviewContext.Provider>
  );
}

export default OverviewContextProvider;
