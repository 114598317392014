import { get, put } from './BaseService';

export async function getTestCases(applicationId: string) {
  const res = await get(`/application/${applicationId}/testcase`);
  return res.data;
}

export async function getScanProfile(applicationId: string) {
  const filter = { where: { application_id: applicationId } };
  const res = await get('/scan-profile', {
    params: { filter: JSON.stringify(filter) },
  });
  return res.data;
}

export async function updateScanProfile(data: any) {
  const res = await put('/scan-profile', data);
  return res.data;
}
