import { ContentCopy, KeyboardArrowLeftRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Stack, SxProps, Typography } from '@mui/material';
import { CSSProperties, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BRAND_ORANGE } from '../../constants/colors';
import { SnackbarContext } from '../../contexts/Snackbar';
import { track } from '../../utils/analytics';

const TITLE: SxProps = {
  color: 'white',
  fontSize: 36,
  fontWeight: 700,
  letterSpacing: '-1.2px',
};

const TEXT: SxProps = {
  color: 'white',
  textAlign: 'start',
};

const LINK: CSSProperties = {
  color: BRAND_ORANGE,
};

const SMALL_TEXT: SxProps = {
  color: '#ccd2e9',
};

const COPY_BOX: SxProps = {
  border: '1px solid #40475c',
  p: 2,
  borderRadius: 1,
};

const SUBMIT_BUTTON: CSSProperties = {
  width: '204px',
  height: '60px',
  fontFamily: 'Raleway',
  fontSize: '18px',
};

const SeleniumOnboardRun = () => {
  const navigate = useNavigate();

  const { show } = useContext(SnackbarContext);

  const handlePrevClick = useCallback(() => {
    track('onboarding-3-previous');
    navigate(-1);
  }, [navigate]);

  const handleNextClick = useCallback(() => {
    track('onboarding-3-finish');
    window.location.href = 'https://www.pynt.io';
  }, []);

  return (
    <Stack alignItems={'center'} p={2} height={'100%'}>
      <Stack
        direction={'column'}
        spacing={1}
        pt={5}
        maxWidth={'700px'}
        height={'100%'}
      >
        <Typography sx={TITLE} textAlign={'start'}>
          You&apos;re almost done!
        </Typography>

        <Stack direction={'column'} spacing={2} mt={1}>
          <Stack direction={'column'} spacing={1} pb={1}>
            <Typography sx={TEXT}>
              <strong>1. Download the Pynt CLI</strong> by executing the
              following Python command from the cmd:
            </Typography>
            <Box sx={COPY_BOX}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography sx={{ color: '#6da6ea' }}>
                  pip install pyntcli
                </Typography>
                <IconButton
                  onClick={() => {
                    track('onboarding-3-copy');
                    navigator.clipboard.writeText('pip install pyntcli');
                    show('Copied to clipboard');
                  }}
                >
                  <ContentCopy sx={{ color: BRAND_ORANGE }} />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
          <Stack direction={'column'} spacing={1} pb={1}>
            <Typography sx={TEXT}>
              <strong>2. Run the Selenium tests using the Pynt CLI</strong>:
            </Typography>

            <Box sx={COPY_BOX}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography sx={{ color: '#6da6ea', textAlign: 'start' }}>
                  {
                    'pynt command --cmd "<COMMAND TO RUN SELENIUM TESTS>" --no-proxy-export'
                  }
                </Typography>
                <IconButton
                  onClick={() => {
                    track('onboarding-3-copy');
                    navigator.clipboard.writeText(
                      'pynt command --cmd "<COMMAND TO RUN SELENIUM TESTS>" --no-proxy-export',
                    );
                    show('Copied to clipboard');
                  }}
                >
                  <ContentCopy sx={{ color: BRAND_ORANGE }} />
                </IconButton>
              </Stack>
            </Box>
          </Stack>

          <Stack>
            <Typography sx={SMALL_TEXT} textAlign={'start'}>
              For any issues, see our{' '}
              <a
                href="https://docs.pynt.io/documentation/pynt-examples/pynt-with-testing-frameworks/selenium"
                target="_blank"
                rel="noreferrer"
                style={LINK}
              >
                docs
              </a>
              , contact{' '}
              <a href="mailto:support@pynt.io" style={LINK}>
                support@pynt.io
              </a>{' '}
              or join our{' '}
              <a
                href="https://www.pynt.io/community"
                target="_blank"
                rel="noreferrer"
                style={LINK}
                onClick={() => {
                  track('onboarding-3-slack');
                }}
              >
                slack community
              </a>
            </Typography>
          </Stack>
        </Stack>

        <Box height={48} />
        <Stack direction={'row'} justifyContent={'end'} gap={2}>
          <LoadingButton
            variant="contained"
            sx={SUBMIT_BUTTON}
            onClick={handlePrevClick}
            startIcon={<KeyboardArrowLeftRounded />}
          >
            Previous
          </LoadingButton>
          <LoadingButton
            variant="contained"
            sx={SUBMIT_BUTTON}
            onClick={handleNextClick}
          >
            Finish
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SeleniumOnboardRun;
