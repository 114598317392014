import { Box, Collapse, Paper, Stack, SxProps } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import CustomCardHeader from './CustomCardHeader';

const CONTAINER: SxProps = {
  p: 2,
};

const CONTENT_CONTAINER: SxProps = {
  height: '100%',
};

interface Props {
  title?: string;
  collapsible?: boolean;
  isOpen?: boolean;
  children?: ReactNode;
  sx?: SxProps;
  action?: ReactNode;
}

export default function CustomCard({
  title,
  collapsible = false,
  isOpen = true,
  action,
  sx,
  children,
}: Props) {
  const [isContentOpened, setIsContentOpened] = useState(isOpen);

  useEffect(() => {
    if (isContentOpened != isOpen) setIsContentOpened(isOpen);
  }, [isOpen]);

  return (
    <Paper
      elevation={0}
      sx={[CONTAINER, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
    >
      <Stack spacing={0} height={'100%'}>
        {title ? (
          <Box sx={{ mb: isContentOpened ? 1 : 0 }}>
            <CustomCardHeader
              title={title}
              collapsible={collapsible}
              isOpen={isContentOpened}
              action={action}
              onClick={
                collapsible
                  ? () => {
                      setIsContentOpened((v) => !v);
                    }
                  : undefined
              }
            />
          </Box>
        ) : (
          <></>
        )}
        {collapsible ? (
          <Collapse in={isContentOpened}>
            <Box sx={CONTENT_CONTAINER}>{children}</Box>
          </Collapse>
        ) : (
          <Box sx={CONTENT_CONTAINER}>{children}</Box>
        )}
      </Stack>
    </Paper>
  );
}
