import { Container } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DashboardAppBar,
  GridWrapper,
  SelectApplication,
} from '../components/Common';
import ScanHistoryGrid from '../components/ScansHistory/ScanHistoryGrid';
import { UserContext, UserContextProps } from '../contexts/User';

const History = () => {
  const [searchParams] = useSearchParams();
  const { selectedApplicationId, setSelectedApplicationId } = useContext(
    UserContext,
  ) as UserContextProps;

  const filter = useMemo(() => {
    const val = searchParams.get('filter');
    if (!val) return null;

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [searchParams]);

  const sorting = useMemo(() => {
    const val = searchParams.get('sorting');
    if (!val) return null;

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [searchParams]);

  return (
    <>
      <DashboardAppBar
        title={'Scans History'}
        startActions={
          <SelectApplication
            selectedApplicationId={selectedApplicationId}
            setSelectedApplicationId={setSelectedApplicationId}
          />
        }
      />
      <Container maxWidth={false}>
        <GridWrapper>
          <ScanHistoryGrid
            id="ScanHistoryGridInHistoryScreen"
            pyntFilter={
              selectedApplicationId === '*'
                ? {}
                : { application: selectedApplicationId }
            }
            autoHeight={false}
            filter={filter}
            sorting={sorting}
            showOpenReportButton
            showDownloadRequestsLogButton
            openDetailsOnSelect
          />
        </GridWrapper>
      </Container>
    </>
  );
};

export default History;
