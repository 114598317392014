import { CloseRounded, Download } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  SxProps,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useContext, useEffect, useState } from 'react';
import { SnackbarContext } from '../../contexts/Snackbar';
import { downloadReport, getReportHTML } from '../../services/ScansService';

const LOADER_WRAPPER: SxProps = {
  width: '100%',
  height: 'calc(100vh - 120px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bgcolor: 'white',
};

const CLOSE_BUTTON: SxProps = {
  position: 'absolute',
  zIndex: 99,
  top: 16,
  right: 16,
  bgcolor: '#05132A',
};

const DOWNLOAD_BUTTON: SxProps = {
  position: 'absolute',
  zIndex: 99,
  top: 16,
  right: 72,
  bgcolor: '#05132A',
};

const CLOSE_ICON: SxProps = {
  color: 'white',
};

const IFRAME: React.CSSProperties = {
  width: '100%',
  height: 'calc(100vh - 120px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
};

interface Props {
  scanId?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScanReportDialog = ({ scanId, open, setOpen }: Props) => {
  const { showDebug } = useContext(SnackbarContext);

  const [scanData, setScanData] = useState(null);
  const [isLoadingScanData, setIsLoadingScanData] = useState(false);

  useEffect(() => {
    if (!open) return;
    if (!scanId) return;
    if (isLoadingScanData) return;

    setIsLoadingScanData(true);

    getReportHTML(scanId)
      .then((scanDataResponse) => {
        setScanData(scanDataResponse);
      })
      .catch((e) => {
        showDebug(e.message, 'error');
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoadingScanData(false);
      });
  }, [scanId, open]);

  useEffect(() => {
    if (open === false) {
      setScanData(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={'xl'}
    >
      <IconButton
        sx={DOWNLOAD_BUTTON}
        onClick={() => downloadReport(scanId || '')}
      >
        <Download sx={CLOSE_ICON} />
      </IconButton>

      <IconButton sx={CLOSE_BUTTON} onClick={() => setOpen(false)}>
        <CloseRounded sx={CLOSE_ICON} />
      </IconButton>
      {isLoadingScanData ? (
        <Box sx={LOADER_WRAPPER}>
          <CircularProgress />
        </Box>
      ) : (
        <></>
      )}
      {scanData != null && !isLoadingScanData ? (
        <iframe
          onLoad={() => setIsLoadingScanData(false)}
          srcDoc={scanData}
          title="Scan Report"
          style={IFRAME}
        />
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default ScanReportDialog;
