import { SvgIconProps } from './SvgIconProps';

export const CheckCircleFilledIcon = ({
  color = '#66BF00',
  size = 22,
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.5" cy="8" r="8" fill={color} />
      <path
        d="M3.92859 7.73998L6.9538 10.8569L12.5 5.14258"
        stroke="white"
        strokeWidth="1.14286"
      />
    </svg>
  );
};
