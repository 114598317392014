import * as Sentry from '@sentry/react';
import { RunningScanHistoryItem, ScanHistoryItem } from '../models/scanHistory';
import { PyntFilter } from './ApplicationsService';
import { get, post } from './BaseService';

export async function getScanById(scanId: string) {
  const res = await get('/scan', {
    params: {
      scan_id: scanId,
    },
  });

  return res.data;
}

export async function getScans(filter: PyntFilter = {}) {
  const res = await get('/scan/summary', {
    params: { filter: JSON.stringify(filter) },
  });

  return res.data;
}

export async function getScansWithInProgress(filter: PyntFilter = {}) {
  const applicationId =
    typeof filter.where?.application === 'string'
      ? filter.where.application
      : undefined;
  const runningScans = await getRunningScans(applicationId).catch((e) => {
    Sentry.captureException(e);
    return [] as ScanHistoryItem[];
  });

  const calculatedOffset = Math.max(
    (filter.offset || 0) - runningScans.length,
    0,
  );
  const res = await get('/scan/summary', {
    params: { filter: JSON.stringify({ ...filter, offset: calculatedOffset }) },
  });

  const offsetedRunningScans = runningScans.slice(filter.offset || 0);

  return [...offsetedRunningScans, ...res.data].slice(0, filter.limit || 100);
}

export async function getScansWithInProgressCount(
  where: PyntFilter['where'] = {},
): Promise<{ count: number }> {
  const runningScans = await getRunningScans().catch((e) => {
    Sentry.captureException(e);
    return [] as ScanHistoryItem[];
  });

  const res = await get('/scan/summary/count', {
    params: { where: JSON.stringify(where) },
  });

  return { count: res.data.count + runningScans.length };
}

export async function getScansCount(where: PyntFilter['where'] = {}) {
  const res = await get('/scan/summary/count', {
    params: { where: JSON.stringify(where) },
  });

  return res.data;
}

export async function getAppScans(appId: string) {
  const res = await get('/scan?app_id=' + appId);

  return res.data;
}

export async function getRunningScans(
  applicationId?: string,
): Promise<RunningScanHistoryItem[]> {
  function processRunningScans(scans: RunningScanHistoryItem[]) {
    return scans
      .filter((scan: any) => scan.scan_progress < 100)
      .filter((scan: any) => {
        const scanTime = new Date(scan.scan_time).getTime();
        const currentTime = new Date().getTime();

        return currentTime - scanTime < 5 * 60 * 60 * 1000;
      })
      .sort((a, b) => {
        const aTime = new Date(a.scan_time).getTime();
        const bTime = new Date(b.scan_time).getTime();

        return bTime - aTime;
      });
  }

  if (applicationId) {
    const res = await get(`/application/${applicationId}/scans/running`);

    return processRunningScans(res.data);
  }
  const res = await get('/scan/running');

  return processRunningScans(res.data);
}

export async function getReportHTML(scanId: string) {
  const res = await get('/report', {
    params: { scan_id: scanId },
  });

  return res.data;
}

export async function connectApplicationToScan(
  scanId: string,
  applicationId: string,
) {
  const res = await post('/group', {
    type: 'scan',
    value: scanId,
    application: applicationId,
  });

  return res.data;
}

export const downloadReport = async (scanId: string) => {
  try {
    // const response = await get('/report', {
    //   params: { scan_id: scanId },
    // });
    // Making a GET request using axios
    const response = await get('/report', {
      responseType: 'blob', // Important to handle the response as a Blob
      params: { scan_id: scanId },
    });

    // Creating a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Creating a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.html'); // Setting the file name for download

    // Appending to the DOM and triggering the download
    document.body.appendChild(link);
    link.click();

    // Cleaning up by removing the link and revoking the blob URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file: ', error);
  }
};

export const downloadRequestsLog = async (scanId: string) => {
  const response = await get(`/scan/${scanId}/requests-log`, {
    responseType: 'blob', // Important to handle the response as a Blob
  });

  // Creating a URL for the blob
  const url = window.URL.createObjectURL(new Blob([response.data]));

  // Creating a temporary anchor element to trigger download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'requests.log.har'); // Setting the file name for download

  // Appending to the DOM and triggering the download
  document.body.appendChild(link);
  link.click();

  // Cleaning up by removing the link and revoking the blob URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export async function runPostmanScanOnCloud(
  appId: string,
  apiKey: string,
  collectionUid: string,
  environmentUid?: string,
) {
  const res = await post('scan/run-remote-scan', {
    api_key: apiKey,
    collection_to_test: collectionUid,
    environment_name: environmentUid,
    application_id: appId,
  });

  return res.data;
}

export async function runHarScanOnCloud(
  appId: string,
  bucketName: string,
  path: string,
  captureDomains: string,
) {
  const res = await post('scan/run-remote-har-scan', {
    application_id: appId,
    har_bucket_name: bucketName,
    har_path: path,
    capture_domains: captureDomains,
  });

  return res.data;
}

export async function runSwaggerScanOnCloud(
  appId: string,
  swaggerFilePath?: string,
  swaggerUrl?: string,
) {
  const res = await post('scan/run-remote-har-scan', {
    application_id: appId,
    // har_bucket_name: bucketName,
    // har_path: path,
  });

  return res.data;
}
