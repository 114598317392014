import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { createContext, useCallback, useEffect, useMemo } from 'react';
// import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import { usePersistedJsonState } from '../hooks/usePersistedState';
import { License } from '../models/license';
import { Organization } from '../models/organization';
import { User } from '../models/user';
import {
  exchangeOAuthToken,
  getAuthUser,
  getUser,
  createUser,
  discoverSSO,
} from '../services/AuthService';
import {
  getStoredHeaders,
  onRefreshError,
  storeAccessToken,
  storeHeaders,
  storeRefreshToken,
} from '../services/BaseService';
import { getOrganizationById } from '../services/OrgService';
import {
  acceptAWSMarketplaceInvite,
  acceptInvite,
} from '../services/OrgUsersService';
import { analyticsIdentify, track, trackOptions } from '../utils/analytics';
import { updateHotjar } from '../utils/hotjar';

interface AuthContextProps {
  user?: User;
  organization?: Organization;
  isAuthenticated: boolean;
  isProd: boolean;
  license?: License['license_type'];
  planExpirationInDays: number;
  refreshUser: () => void;
  setIsProd: (_: boolean) => void;
  logout: () => void;
  loginWithGoogle: () => void;
  loginWithGitHub: () => void;
  loginWithLinkedIn: () => void;
  loginWithAzure: () => void;
  loginWithSSO: (provider: string) => void;
  discoverSSO: (email: string) => Promise<string>;
  confirmOAuthCode: (
    code: string,
    state?: string,
  ) => Promise<{ newOrgCreated: boolean; goto?: string }>;
}

export const AuthContext = createContext<AuthContextProps>({
  isProd: true,
  planExpirationInDays: 21,
  refreshUser: () => {},
  setIsProd: (_) => {},
  logout: () => {},
  loginWithGoogle: () => {},
  loginWithGitHub: () => {},
  loginWithLinkedIn: () => {},
  loginWithAzure: () => {},
  loginWithSSO: (_) => {},
  discoverSSO: (_) => Promise.reject(new Error('not found')),
  confirmOAuthCode: async () => ({ newOrgCreated: false }),
  isAuthenticated: false,
});

function AuthContextProvider(props: any) {
  // const { trackEvent, boot: bootIntercom } = useIntercom();
  const [user, setUser] = usePersistedJsonState<User | undefined>('user');
  const [organization, setOrganization] = usePersistedJsonState<
    Organization | undefined
  >('organization');
  const [isAuthenticated, setIsAuthenticated] =
    usePersistedJsonState<boolean>('isAuthenticated');
  const [isProd, setIsProd] = usePersistedJsonState(
    '#$%#$#$',
    process.env.REACT_APP_NODE_ENV !== 'development',
    sessionStorage,
  );

  const activeLicense: License | undefined | null = useMemo(() => {
    if (!organization) return undefined;
    if (!organization.licenses) return null;

    const licenses = organization.licenses.filter((l) => l.status === 'active');

    const activeLicense = licenses.reduce((acc, l) => {
      if (!acc) return l;
      return acc.expiration_date > l.expiration_date ? acc : l;
    }, licenses[0]);

    return activeLicense || null;
  }, [organization]);

  const license = useMemo(() => {
    return activeLicense?.license_type;
  }, [activeLicense]);

  const planExpirationInDays = useMemo(
    () =>
      activeLicense?.start_date && activeLicense?.expiration_date
        ? dayjs(activeLicense?.expiration_date).diff(new Date(), 'day')
        : 14,
    [activeLicense],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const xOrg = searchParams.get('x-org');
    if (xOrg) {
      storeHeaders({ ...getStoredHeaders(), 'X-Org': xOrg });
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    refreshUser();
  }, [isAuthenticated]);

  useEffect(() => {
    onRefreshError.cb = () => {
      logout();
    };
    return () => {
      onRefreshError.cb = undefined;
    };
  }, [isAuthenticated]);

  // useEffect(() => {
  //   trackOptions.intercomTrack = trackEvent;
  // }, [trackEvent]);

  useEffect(() => {
    trackOptions.globalParamters.organizationId = user?.organization_id;
    if (!isAuthenticated || !user) {
      // bootIntercom();
      return;
    }
    analyticsIdentify(user.uid, user.email, user, organization);
    updateHotjar(user, organization);
    // bootIntercom({
    //   email: user.email,
    //   name: user.name,
    //   userId: user.user_id,
    //   company: user.organization
    //     ? {
    //         companyId: user.organization,
    //         name: user.organization_name,
    //       }
    //     : undefined,
    // });
  }, [
    isAuthenticated,
    user,
    organization,
    // bootIntercom,
  ]);

  const getOAuthRedirectUri = () => {
    const redirectUri = window.location.origin + '/auth';
    return redirectUri;
  };

  const buildOAuthUrl = (provider: string) => {
    const redirectUri = encodeURIComponent(getOAuthRedirectUri());
    const params = new URLSearchParams(window.location.search);
    const state: any = {};
    if (params.has('invite')) state.awsMarketplaceId = params.get('invite');
    if (params.has('awsMarketplaceId')) {
      state.awsMarketplaceId = params.get('awsMarketplaceId');
    }
    if (params.has('goto')) state.goto = params.get('goto');
    state.provider = provider;
    return `${
      process.env.REACT_APP_OAUTH_BASE_URL
    }/oauth2/authorize?identity_provider=${provider}&redirect_uri=${redirectUri}&response_type=CODE&client_id=${
      process.env.REACT_APP_OAUTH_CLIENT_ID
    }&scope=pynt/default&state=${encodeURIComponent(JSON.stringify(state))}`;
  };

  const loginWithGoogle = () => {
    const uri = buildOAuthUrl('Google');
    window.location.replace(uri);
  };

  const loginWithGitHub = () => {
    const uri = buildOAuthUrl('GithubShim');
    window.location.replace(uri);
  };

  const loginWithLinkedIn = () => {
    const uri = buildOAuthUrl('LinkedIn');
    window.location.replace(uri);
  };

  const loginWithAzure = () => {
    const uri = buildOAuthUrl('Azure');
    window.location.replace(uri);
  };

  // eslint-disable-next-line no-unused-vars
  const verifyBusinessEmail = async (accessToken: string) => {
    const authUser = await getAuthUser(accessToken).catch((e) => {
      Sentry.captureException(e);
      return null;
    });

    const blockedEmailsList: string[] = await import(
      '../assets/jsons/blockedEmails.json'
    )
      .then((m) => m.default)
      .catch((e) => {
        Sentry.captureException(e);
        console.error(e);
        return [];
      });

    const emailDomain = authUser?.email?.split('@')[1];
    if (emailDomain && blockedEmailsList.includes(emailDomain)) {
      track('web_app_login_blocked_for_non_business_email', {
        uid: authUser.user_id,
        email: authUser.email,
      });
      throw new Error('Please use a business email to sign up for Pynt');
    }

    return { ok: true };
  };

  const confirmOAuthCode = useMemo(
    () =>
      async (
        code: string,
        state?: string,
      ): Promise<{ newOrgCreated: boolean; goto?: string }> => {
        const redirectUri = getOAuthRedirectUri();
        const json = await exchangeOAuthToken(code, redirectUri);
        storeAccessToken(json.access_token);
        storeRefreshToken(json.refresh_token);
        let inviteId: string | undefined;
        let awsMarketplaceId: string | undefined;
        let goto: string | undefined;
        let provider: string | undefined;
        try {
          const jState = state ? JSON.parse(state) : {};
          inviteId = jState.inviteId;
          awsMarketplaceId = jState.awsMarketplaceId;
          goto = jState.goto;
          // eslint-disable-next-line no-unused-vars
          provider = jState.provider;
        } catch (e) {
          console.error(e);
        }

        let newOrgCreated = false;
        const [newUser, org] = await (
          awsMarketplaceId
            ? acceptAWSMarketplaceInvite(awsMarketplaceId).then(() => {
                return getUser();
              })
            : inviteId
              ? acceptInvite(inviteId).then(() => {
                  return getUser();
                })
              : getUser().catch(async (e) => {
                  if (e?.response?.status === 404) {
                    // Ofer asked to not yet verify private emails
                    // if (provider === 'Google') {
                    //   await verifyBusinessEmail(json.access_token);
                    // }
                    const createUserResponse = await createUser();
                    newOrgCreated = createUserResponse.new_organization_created;
                    const newUser = await getUser();
                    if (newUser) return newUser;
                  }
                  throw e;
                })
        )
          .then(async (user) => {
            const org = await getOrganizationById(user.organization_id);
            return [user, org];
          })
          .catch((e) => {
            storeAccessToken(undefined);
            storeRefreshToken(undefined);

            Sentry.captureException(e);

            throw e;
          });

        setUser(newUser);
        setOrganization(org);
        setIsAuthenticated(true);
        track('web_app_login_successfully');
        return { newOrgCreated, goto };
      },
    [],
  );

  const refreshUser = useCallback(async () => {
    await Promise.all([
      getUser().then((updatedUser) => setUser(updatedUser)),
      user && user.organization_id
        ? getOrganizationById(user.organization_id).then((org) =>
            setOrganization(org),
          )
        : undefined,
    ]);
  }, [user]);

  const logout = () => {
    storeAccessToken(undefined);
    storeRefreshToken(undefined);
    setUser(undefined);
    setIsAuthenticated(false);
    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      mixpanel.reset();
    }
  };

  const loginWithSSO = useCallback(
    (provider: string) => {
      window.location.replace(buildOAuthUrl(provider));
    },
    [buildOAuthUrl],
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        organization,
        isAuthenticated,
        isProd,
        license,
        planExpirationInDays,
        refreshUser,
        setIsProd,
        loginWithGoogle,
        loginWithGitHub,
        loginWithLinkedIn,
        loginWithAzure,
        loginWithSSO,
        logout,
        discoverSSO,
        confirmOAuthCode,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
