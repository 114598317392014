import { ReactNode, useContext } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS } from 'react-joyride';
import { OnboardContext } from '../../contexts/Onboard';

interface Props {
  children: ReactNode;
}

export default function OnboardWrapper({ children }: Props) {
  const {
    state: { run, stepIndex, steps, nonce },
    setState,
  } = useContext(OnboardContext);

  const handleCallback = (data: CallBackProps) => {
    const { action, type, step } = data;

    if (action === 'reset') {
      setState((s) => ({
        ...s,
        run: false,
        stepIndex: 0,
      }));
    } else if ((step as any).handleTargetClick) {
      if (type === EVENTS.STEP_AFTER || action === ACTIONS.CLOSE) {
        // Cleanup when leaving the step or closing the tour
        const targetElement = document.querySelector(step.target as string);
        if (targetElement) {
          targetElement.removeEventListener(
            'click',
            (step as any).handleTargetClick,
          );
        }
      }

      if (type === EVENTS.TOUR_START) {
        // Attach event listener when entering the step
        const targetElement = document.querySelector(step.target as string);
        if (targetElement) {
          targetElement.addEventListener(
            'click',
            (step as any).handleTargetClick,
          );
        }
      }
    }
  };

  return (
    <>
      {children}
      <Joyride
        key={nonce}
        callback={handleCallback}
        continuous
        debug
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        scrollToFirstStep={false}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
    </>
  );
}
