import React, { createContext, useState } from 'react';
import { Step } from 'react-joyride';

interface OnboardState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  nonce?: string;
}

interface OnboardContextProps {
  state: OnboardState;
  setState: React.Dispatch<React.SetStateAction<OnboardState>>;
}

const appState: OnboardState = {
  run: false,
  stepIndex: 0,
  steps: [],
};

export const OnboardContext = createContext<OnboardContextProps>({
  state: appState,
  setState: () => undefined,
});

function OnboardContextProvider(props: any) {
  const [state, setState] = useState<OnboardState>(appState);

  return (
    <OnboardContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {props.children}
    </OnboardContext.Provider>
  );
}

export default OnboardContextProvider;
