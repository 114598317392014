import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  SxProps,
} from '@mui/material';
import { AWSRegios } from '../../../assets/data/aws-regions';
import { LockIcon } from '../../../assets/svgs/icons/LockIcon';
import { UploadIcon } from '../../../assets/svgs/icons/UploadIcon';
import IconTextField from '../../Common/IconTextField';
import TextFieldTitle from '../../Common/TextFieldTitle';
import { IntegrationType } from './EditSourceForm';
import { AwsAPIGatewaySourceData } from './EditSourceFormAPIGateway';

const SELECT_ICON_WRAPPER: SxProps = {
  bgcolor: '#EFF5FF',
  width: 24,
  height: 24,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface Props {
  type: IntegrationType;
  data?: AwsAPIGatewaySourceData;
  onChanged: (data: AwsAPIGatewaySourceData) => void;
  disabled?: boolean;
}

export default function EditSourceFormAPIGatewayAws({
  type,
  data,
  onChanged,
  disabled,
}: Props) {
  return (
    <Stack spacing={2}>
      <Box>
        <TextFieldTitle title="Access Key ID" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Access key from vendor"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['access_key_id']}
          onChange={(v) =>
            onChanged({ ...data, access_key_id: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Secret Access Key" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Secret access key from vendor"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['secret_access_key_id']}
          onChange={(v) =>
            onChanged({ ...data, secret_access_key_id: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Region" />
        <FormControl
          variant="filled"
          fullWidth
          size="small"
          disabled={disabled}
        >
          <Select
            disableUnderline
            value={data?.['region'] ?? 'us-east-1'}
            onChange={(v) => onChanged({ ...data, region: v.target.value })}
          >
            {AWSRegios.map((option) => (
              <MenuItem key={option.regionName} value={option.regionName}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Box sx={SELECT_ICON_WRAPPER}>
                    <UploadIcon size={20} color="#BFCEE5" />
                  </Box>
                  <span>{option.regionName}</span>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <TextFieldTitle title="Gateway ID" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="The AWS Gateway ID"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['gw_id']}
          onChange={(v) =>
            onChanged({ ...data, gw_id: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
      <Box>
        <TextFieldTitle title="Stage" />
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="Stage"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <LockIcon size={20} color="#BFCEE5" />
            </Box>
          }
          value={data?.['stage']}
          onChange={(v) =>
            onChanged({ ...data, stage: v.target.value?.trim() })
          }
          required
          disabled={disabled}
        />
      </Box>
    </Stack>
  );
}
