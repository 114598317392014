import { Tooltip } from '@mui/material';
import { useMemo } from 'react';
import cliIcon from '../../assets/images/cliIcon.png';
import newmanIcon from '../../assets/images/newman.png';
import postmanIcon from '../../assets/images/postman.png';

interface Props {
  type: 'postman' | 'newman' | 'proxy';
  size?: number;
}

export default function HistoryScanIntegrationIcon({ type, size = 24 }: Props) {
  const statusTitle = useMemo(() => {
    switch (type) {
      case 'postman':
        return 'Postman';

      case 'newman':
        return 'Newman';

      case 'proxy':
        return 'Command';

      default:
        return type;
    }
  }, [type]);

  const icon = useMemo(() => {
    switch (type) {
      case 'postman':
        return <img src={postmanIcon} height={size} width={size} />;

      case 'newman':
        return <img src={newmanIcon} height={size} width={size} />;

      case 'proxy':
      default:
        return <img src={cliIcon} height={size - 4} width={size - 4} />;
    }
  }, [type]);

  return <Tooltip title={statusTitle}>{icon}</Tooltip>;
}
