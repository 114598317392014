import { CloseRounded } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  TooltipProps,
  Typography,
} from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import { DARK_GRAY } from '../../constants/colors';
import { WhiteTooltip } from '../Common/WhiteTooltip';

const CHIP: SxProps = {
  bgcolor: '#E8E8E8',
  py: 0.5,
  px: 1,
  borderRadius: 0.5,
  fontSize: 12,
};

interface Props {
  status: string | undefined;
}

export const APIStatusTooltip = ({
  children,
  status,
  onClose,
  ...props
}: Props & { children: ReactElement; onClose: () => void } & Omit<
    TooltipProps,
    'children' | 'title'
  >) => {
  const statusTitle = useMemo(() => {
    switch (status?.toUpperCase()) {
      case 'VALIDATED':
        return 'Validated';

      case 'UNDOCUMENTED':
        return 'Undocumented API';

      case 'NEW_API':
        return 'New API';

      case 'SHADOW':
        return 'Shadow API';

      case undefined:
        return 'No Gaps';

      default:
        return status;
    }
  }, [status]);

  const statusDescription = useMemo(() => {
    switch (status?.toUpperCase()) {
      case 'UNDOCUMENTED':
        return (
          <>
            If an API exists but hasn&apos;t undergone security testing, it
            introduces specific challenges:
            <ol>
              <li>
                <strong>Security Blind Spots:</strong> Untested APIs may harbor
                vulnerabilities or weaknesses that remain undetected, posing a
                security risk.
              </li>
              <li>
                <strong>Inaccurate Risk Assessment:</strong> Without security
                testing, the risk assessment may lack crucial insights,
                potentially leading to misinformed decisions.
              </li>
              <li>
                <strong>Compliance Challenges:</strong> Regulatory compliance
                often requires thorough security assessments. Untested APIs may
                create compliance challenges.
              </li>
            </ol>
          </>
        );

      case 'NEW_API':
        return (
          <>
            When new APIs are on the verge of deployment or are about to arrive
            in the production environment, there are notable considerations:
            <ol>
              <li>
                <strong>Visibility Challenges:</strong> New APIs may not be
                immediately visible in the catalog, particularly if they are yet
                to be officially deployed. This may require prompt integration
                into the API tracking system.
              </li>
              <li>
                <strong>Security Gaps:</strong> Until officially added to the
                security testing regimen, new APIs may contain potential
                security gaps, necessitating swift assessment before they become
                operational.
              </li>
              <li>
                <strong>Operational Impact:</strong> Developers may not be aware
                of newly introduced APIs, especially if they are in the
                pre-production phase. This lack of awareness can lead to
                potential operational challenges without comprehensive
                documentation.
              </li>
              <li>
                <strong>Ensuring Documentation Completeness:</strong> New APIs
                may lack complete documentation until they are officially
                deployed. Proactively addressing documentation gaps ensures that
                developers have the necessary information for successful
                integration.
              </li>
            </ol>
          </>
        );

      case 'SHADOW':
        return (
          <>
            Suppose an API appears in the production environment but is absent
            in the documentation. This &apos;shadow API&apos; scenario poses
            several challenges:
            <ol>
              <li>
                <strong>Incomplete Security Assessments:</strong> The API may
                not undergo necessary security assessments, leading to
                incomplete security coverage and potential vulnerabilities going
                unnoticed.
              </li>
              <li>
                <strong>Limited Visibility:</strong> The absence of
                documentation restricts visibility, making it challenging to
                monitor and manage the complete API landscape.
              </li>
              <li>
                <strong>Risk of Undetected Changes:</strong> Without
                comprehensive coverage, changes made to the API in the
                production environment may go unnoticed, posing a risk of
                undetected alterations.
              </li>
              <li>
                <strong>Operational Challenges:</strong> Incomplete
                documentation hinders efficient operation, as developers may
                lack crucial information required for successful integration.
              </li>
              <li>
                <strong>Compliance Risks:</strong> The presence of &apos;Shadow
                APIs&apos; may impact regulatory compliance efforts, as
                documentation completeness is crucial for assessments.
              </li>
            </ol>
          </>
        );

      case 'UNTESTED':
        return (
          <>
            If an API exists but hasn&apos;t undergone security testing, it
            introduces specific challenges:
            <ol>
              <li>
                <strong>Security Blind Spots:</strong> Untested APIs may harbor
                vulnerabilities or weaknesses that remain undetected, posing a
                security risk.
              </li>
              <li>
                <strong>Inaccurate Risk Assessment:</strong> Without security
                testing, the risk assessment may lack crucial insights,
                potentially leading to misinformed decisions.
              </li>
              <li>
                <strong>Compliance Challenges:</strong> Regulatory compliance
                often requires thorough security assessments. Untested APIs may
                create compliance challenges.
              </li>
            </ol>
          </>
        );

      case undefined:
      default:
        return status;
    }
  }, [status]);

  const statusManagingImplications = useMemo(() => {
    switch (status?.toUpperCase()) {
      case 'UNDOCUMENTED':
        return (
          <>
            Elevate documentation priorities, conduct regular audits, and foster
            collaboration to address the challenges associated with undocumented
            APIs.
          </>
        );

      case 'UNTESTED':
        return (
          <>
            Prioritize security assessments, documentation, and collaboration to
            ensure comprehensive testing and mitigate potential vulnerabilities.
          </>
        );

      case 'NEW_API':
        return (
          <>
            prioritize documentation, and collaboration to smoothly integrate
            new APIs into your ecosystem.
          </>
        );

      case 'SHADOW':
        return (
          <>
            Conduct thorough audits, document shadow APIs, and implement
            governance to align production with intended API landscapes.
          </>
        );

      case undefined:
      default:
        return '';
    }
  }, [status]);

  return (
    <WhiteTooltip
      {...props}
      maxWidth={600}
      title={
        <React.Fragment>
          <Stack direction="column" gap={1}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography sx={CHIP} variant="body1">
                {statusTitle}
              </Typography>
              <Box flex={1} minWidth={16} />
              <IconButton size="small" onClick={onClose}>
                <CloseRounded sx={{ color: DARK_GRAY }} />
              </IconButton>
            </Stack>
            {status ? (
              <>
                <Stack gap={0.5}>
                  <Typography color={'#7B7B88'} fontSize={12}>
                    Description
                  </Typography>
                  <Typography variant="body1" fontSize={14}>
                    {statusDescription}
                  </Typography>
                </Stack>
                <Stack gap={0.5}>
                  <Typography color={'#7B7B88'} fontSize={12}>
                    Managing Implications
                  </Typography>
                  <Typography variant="body1" fontSize={14}>
                    {statusManagingImplications}
                  </Typography>
                </Stack>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </React.Fragment>
      }
      arrow
    >
      {children}
    </WhiteTooltip>
  );
};
