import { useCallback, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import SimulatedDashboardLayout from '../components/Common/SimulatedDashboardLayout';
import { AuthContext } from '../contexts/Auth';
import AlertDialog from '../dialogs/AlertDialog';
import { track } from '../utils/analytics';

const TrialIsOver = () => {
  const navigate = useNavigate();
  const { license, planExpirationInDays, logout } = useContext(AuthContext);

  const onScheduleMeetingClick = useCallback(() => {
    track('web_app_expired_screen_upgrade_button_click');
    const url = 'https://meetings-eu1.hubspot.com/tural-mirzayev/upgrade-pynt';
    window.open(url, '_blank');
  }, []);

  return (
    <>
      {license && planExpirationInDays >= 0 && <Navigate to={'/dashboard'} />}
      <SimulatedDashboardLayout />
      <AlertDialog
        title="Your trial has ended 🙁"
        content="Upgrade your account to scan more API endpoints, discover all
        APIs, and let Pynt attack your APIs, before hackers do."
        open={true}
        onClose={() => {}}
        actions={[
          {
            title: 'Upgrade Now',
            variant: 'contained',
            onClick: onScheduleMeetingClick,
          },
          {
            title: 'Log Out',
            variant: 'outlined',
            onClick: () => {
              track('web_app_expired_screen_logout_button_click');
              logout();
              navigate('/login');
            },
          },
        ]}
      />
    </>
  );
};

export default TrialIsOver;
