interface Props {
  width?: number;
  height?: number;
}

export const SecuringUnderline = ({ height = 8, width = 147 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 147 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6C30.4577 2.98157 103.381 -1.24429 145 5.99972"
        stroke="url(#paint0_linear_1306_5021)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1306_5021"
          x1="73.5"
          y1="2"
          x2="73.5"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0104167" stopColor="#FF8237" />
          <stop offset="0.979167" stopColor="#FF8237" />
          <stop offset="1" stopColor="#FF8237" />
        </linearGradient>
      </defs>
    </svg>
  );
};
