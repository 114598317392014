import { Stack, SxProps, Tooltip, Typography } from '@mui/material';

const CHIP: SxProps = {
  bgcolor: '#F3E8E8',
  borderRadius: 0.5,
  height: 22,
  px: 1,
};

const TEXT: SxProps = {
  fontSize: 11,
  color: 'black',
  letterSpacing: '-0.3 px',
};

interface Props {
  category: string;
}

export default function TestCategoryChip({ category }: Props) {
  return (
    <Tooltip title={category} sx={{ maxWidth: '100%' }}>
      <Stack
        sx={CHIP}
        alignItems={'center'}
        justifyContent={'center'}
        maxWidth={'100%'}
      >
        <Typography
          variant="body1"
          sx={TEXT}
          whiteSpace={'nowrap'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          maxWidth={'100%'}
        >
          {category}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
