import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridColumnsInitialState } from '@mui/x-data-grid';
import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import * as Sentry from '@sentry/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ChevronRightIcon } from '../../assets/svgs/icons/ChevronRightIcon';
import {
  IntegrationsContext,
  IntegrationsContextProps,
} from '../../contexts/Integrations';
import { UserContext, UserContextProps } from '../../contexts/User';
import { usePersistedJsonState } from '../../hooks/usePersistedState';
import { Vulnerability, statusToTitle } from '../../models/vulnerability';
import { PyntFilter } from '../../services/ApplicationsService';
import {
  getVulnerabilities,
  getVulnerabilitiesCount,
} from '../../services/VulnerabilitiesService';
import { reformatFilterModel, reformatSortModel } from '../../utils/datagrid';
import {
  APIMethodChip,
  ApplicationGridCell,
  JiraTicketingBadge,
  PyntDataGrid,
} from '../Common';
import TestCategoryChip from './TestCategoryChip';
import TestStatusChip from './TestStatusChip';
import VulnerabilityDrawer from './VulnerabilityDrawer';

interface Props {
  id?: string;
  applicationId: string | undefined;
  hideFooter?: boolean;
  autoHeight?: boolean;
  autoPageSize?: boolean;
  filter?: GridFilterModel;
  sorting?: GridSortModel;
  columnVisibilityModel?: GridColumnVisibilityModel;
  hideEvidenceInDrawer?: boolean;
  pyntFilter?: PyntFilter['where'] | any;
}

const severties = [
  { value: 'Error', label: 'Error' },
  { value: 'Warning', label: 'Warning' },
];

export default function VulnerabilitiesGrid({
  id = 'VulnerabilitiesGrid',
  hideFooter,
  autoHeight = true,
  autoPageSize = false,
  filter,
  sorting = [],
  columnVisibilityModel,
  hideEvidenceInDrawer,
  pyntFilter,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { applications, tags } = useContext(UserContext) as UserContextProps;
  const { integrations } = useContext(
    IntegrationsContext,
  ) as IntegrationsContextProps;

  const [vulnerabilities, setVulnerabilities] = useState<Vulnerability[]>();
  const [isLoadingVulnerabilities, setIsLoadingVulnerabilities] =
    useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>(sorting);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [count, setCount] = useState<number>();

  const [selectedId, setSelectedId] = useState<string>();
  const [selected, setSelected] = useState<Vulnerability>();
  const [isOpenVulnerabilityDrawer, setIsOpenVulnerabilityDrawer] =
    useState(false);

  useEffect(() => {
    const storedVulnerabilities = localStorage.getItem(`vulnerabilities-${id}`);
    if (storedVulnerabilities) {
      try {
        const parsedApiCatalog = JSON.parse(storedVulnerabilities);
        if (!parsedApiCatalog.length) return;
        setVulnerabilities(parsedApiCatalog);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const fetchVulnerabilities = useCallback(async () => {
    if (isLoadingVulnerabilities) return;

    setIsLoadingVulnerabilities(true);

    const filter: PyntFilter = {
      where: {
        ...reformatFilterModel(filterModel, columns),
        ...pyntFilter,
      },
      sort: reformatSortModel(sortModel, columns),
      offset: page * pageSize,
      limit: pageSize,
    };

    const vulnerabilities = await getVulnerabilities(filter).catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });

    setVulnerabilities(vulnerabilities);
    setIsLoadingVulnerabilities(false);

    if (page === 0) {
      if (vulnerabilities) {
        localStorage.setItem(
          `vulnerabilities-${id}`,
          JSON.stringify(vulnerabilities),
        );
      } else {
        localStorage.removeItem(`vulnerabilities-${id}`);
      }
    }

    const vulnerabilitiesCount = await getVulnerabilitiesCount(
      filter.where,
    ).catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });

    setCount(vulnerabilitiesCount?.count);

    return vulnerabilities;
  }, [
    isLoadingVulnerabilities,
    filterModel,
    sortModel,
    page,
    pageSize,
    pyntFilter,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchVulnerabilities();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterModel, sortModel, page, pageSize, pyntFilter]);

  const hasJiraIntegration = useMemo(() => {
    return integrations?.some(
      (i) => i.integration_type === 'jira' && i.status === 'ACTIVE',
    );
  }, [integrations]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'severity',
        headerName: 'Severity',
        width: 100,
        type: 'singleSelect',
        valueOptions: severties,
        sortComparator: (v1: string, v2: string) =>
          severties.map((s) => s.value).indexOf(v2) -
          severties.map((s) => s.value).indexOf(v1),
        renderCell: ({ value }) => <TestStatusChip status={value || 'error'} />,
      },
      {
        field: 'name',
        headerName: 'Test Name',
        flex: 1.5,
        filterable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 180,
        type: 'singleSelect',
        valueGetter: ({ row }) => row.status ?? 'detected',
        valueOptions: [
          { value: 'detected', label: 'detected' },
          { value: 'waived', label: 'waived' },
          { value: 'false_positive_claimed', label: 'False positive claimed' },
          { value: 'resolved', label: 'Resolved' },
        ],
        renderCell: ({ value }) => (
          <Typography>{statusToTitle(value)}</Typography>
        ),
      },
      {
        field: 'endpoint',
        headerName: 'API',
        flex: 1,
        filterable: false,
        renderCell: ({ value }) => (
          <Tooltip title={value}>
            <Stack direction={'row'} gap={2} width={'100%'}>
              <Box>
                <APIMethodChip
                  method={value?.split(' ')?.[0] ?? 'get'}
                  grayed
                />
              </Box>
              <Typography textOverflow={'ellipsis'} overflow={'hidden'}>
                {value?.split(' ')?.[1]}
              </Typography>
            </Stack>
          </Tooltip>
        ),
      },
      {
        field: 'category',
        headerName: 'Category',
        flex: 1,
        filterable: false,
        renderCell: ({ value }) =>
          value ? <TestCategoryChip category={value} /> : <></>,
      },
      ...(hasJiraIntegration
        ? [
            {
              field: 'tickets',
              headerName: 'Ticket',
              flex: 1,
              renderCell: ({ value }) => {
                const jiraTicket = value?.find(
                  (t: any) => t.integration_type === 'jira',
                );
                if (!jiraTicket) return <></>;
                return <JiraTicketingBadge ticket={jiraTicket} />;
              },
            } as GridColDef,
          ]
        : []),
      {
        field: 'application_id',
        headerName: 'Application',
        minWidth: 120,
        flex: 1,
        type: 'singleSelect',
        valueOptions:
          applications?.map((a) => ({
            label: a.name,
            value: a.app_id,
          })) ?? [],
        renderCell: ({ value }) =>
          value ? <ApplicationGridCell applicationId={value} /> : <></>,
      },
      {
        field: 'tags',
        headerName: 'Labels',
        minWidth: 120,
        flex: 1,
        type: 'singleSelect',
        valueOptions:
          tags?.map((a) => ({
            label: a.name,
            value: a.name,
          })) ?? [],
        renderCell: ({ value }) => (
          <Stack direction={'row'} gap={1}>
            {value?.map((tag: string) => <Chip key={tag} label={tag} />)}
          </Stack>
        ),
      },
      {
        field: 'continue',
        headerName: '',
        renderCell: () => <ChevronRightIcon />,
      },
    ],
    [applications, tags, hasJiraIntegration],
  );

  const [gridColumnsState, setGridColumnsState] =
    usePersistedJsonState<GridColumnsInitialState>(`${id}ColumnsState`, {
      columnVisibilityModel: {
        id: false,
        endpoint_id: false,
        pii: false,
        configuration: false,
        security: false,
        scansCount: false,
        tags: false,
      },
    });

  const gridInitialState = useMemo<GridInitialStateCommunity>(() => {
    return {
      sorting: {
        sortModel: sorting ?? [{ field: 'severity', sort: 'desc' }],
      },
      filter: {
        filterModel: filter,
      },
      columns: {
        ...gridColumnsState,
        columnVisibilityModel: {
          ...gridColumnsState.columnVisibilityModel,
          ...(columnVisibilityModel ?? {}),
        },
      },
    };
  }, [gridColumnsState, filter, columnVisibilityModel]);

  useEffect(() => {
    if (searchParams.get('vulnerabilityId')) {
      setSelectedId(searchParams.get('vulnerabilityId') as string);
      setIsOpenVulnerabilityDrawer(true);
    }
  }, [searchParams]);

  return (
    <>
      <VulnerabilityDrawer
        isOpen={isOpenVulnerabilityDrawer}
        hideEvidenceTab={hideEvidenceInDrawer}
        setIsOpen={() => {
          setIsOpenVulnerabilityDrawer(false);
          setSelectedId(undefined);
          setSearchParams((prev) => {
            prev.delete('vulnerabilityId');
            return new URLSearchParams(prev);
          });
        }}
        vulnerabilityId={selectedId}
        vulnerability={selected}
        onUpdated={(_v) => {
          fetchVulnerabilities();
        }}
      />
      <PyntDataGrid
        paginationMode="server"
        filterMode="server"
        sortingMode={'server'}
        sortModel={sortModel}
        filterModel={filterModel}
        onSortModelChange={(sortModel) => setSortModel([...sortModel])}
        onFilterModelChange={(filterModel) =>
          setFilterModel({ ...filterModel })
        }
        paginationModel={{ page: page || 0, pageSize: pageSize || 50 }}
        onPaginationModelChange={(paginationModel) => {
          setPage(paginationModel.page);
          setPageSize(paginationModel.pageSize);
        }}
        autoHeight={autoHeight}
        autoPageSize={autoPageSize}
        hideFooter={hideFooter}
        pageSizeOptions={[25, 50, 100]}
        pagination
        sx={{ borderRadius: 1 }}
        rows={vulnerabilities ?? []}
        rowCount={count}
        getRowId={(row) => row.id || `${row.endpoint}-${row.category}`}
        loading={!vulnerabilities || isLoadingVulnerabilities}
        columns={columns}
        initialState={gridInitialState}
        onStateChange={(state) => {
          setGridColumnsState(state.columns);
        }}
        onRowClick={({ row }) => {
          // allow to select text without interaption
          if (window.getSelection()?.type === 'Range') return;

          setSelectedId(row.id);
          setSearchParams((prev) => {
            prev.set('vulnerabilityId', row.id);
            return new URLSearchParams(prev);
          });
          setSelected(row);
          setIsOpenVulnerabilityDrawer(true);
        }}
      />
    </>
  );
}
