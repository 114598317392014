import { Stack, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import burpOnboardIcon from '../../assets/images/burpOnboardIcon.png';
import burpOnboardMask from '../../assets/images/burpOnboardMask.png';
import { LightLogo } from '../../assets/svgs/LightLogo';
import { analyticsIdentify } from '../../utils/analytics';
import { BurpOnboardStepData } from './BurpOnboardStep';
import BurpOnboardSteps from './BurpOnboardSteps';

const CONTAINER: SxProps = {
  bgcolor: '#050d21',
  height: '100vh',
  overflow: 'hidden',
};

const APP_BAR: SxProps = {
  bgcolor: '#050d21',
  height: '108px',
  minHeight: '108px',
};

const STEPS_CONTAINER: SxProps = {
  width: '40vw',
  minWidth: '400px',
  backgroundImage: `url(${burpOnboardMask})`,
  backgroundSize: 'cover',
};

const OUTLET_CONTAINER: SxProps = {
  overflow: 'scroll',
};

const steps: BurpOnboardStepData[] = [
  { path: '/login', name: 'Signup' },
  { path: '/setup', name: 'Integrate Pynt\nwith Burp' },
  // eslint-disable-next-line quotes
  { path: '/run', name: "Unlock Pynt's\nFull Potential" },
];

export default function BurpOnboardWrapper() {
  const location = useLocation();
  const navigate = useNavigate();

  const activeMenuItemIndex = useMemo(
    () => steps.findIndex((step) => location.pathname.endsWith(step.path)),
    [location],
  );

  useEffect(() => {
    if (
      !sessionStorage.getItem('burpAuth') &&
      !location.pathname.endsWith('login')
    ) {
      navigate('/onboard/burp/login');
    }
  }, [location]);

  useEffect(() => {
    const burpAuth = sessionStorage.getItem('burpAuth');
    if (!burpAuth) return;

    const token = JSON.parse(burpAuth);
    const userId = token.userId;
    const email = token.email;

    if (userId && typeof userId === 'string') {
      analyticsIdentify(userId, email || undefined);
    }
  }, []);

  return (
    <Stack direction={'column'} sx={CONTAINER}>
      <Stack sx={APP_BAR} justifyContent={'center'} px={10}>
        <LightLogo height={70} />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'stretch'}
        sx={{ height: 'calc(100% - 108px)' }}
      >
        <Stack
          sx={STEPS_CONTAINER}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          pt={5}
        >
          <BurpOnboardSteps currentStep={activeMenuItemIndex} steps={steps} />
          <img src={burpOnboardIcon} width={350} />
        </Stack>
        <Box component="main" sx={OUTLET_CONTAINER} flex={1}>
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
}
