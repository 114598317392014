import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import NumberChipIndicator from '../Common/NumberChipIndicator';
import PyntDataGrid from '../Common/PyntDataGrid';

const columns: GridColDef[] = [
  {
    field: 'tls',
    headerName: 'Number Of Sessions',
    width: 280,
    renderCell: ({ value }) => <NumberChipIndicator value={value} />,
  },
  {
    field: 'verboseError',
    headerName: 'Session Duration',
    width: 280,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'serverResponseTime',
    headerName: 'Server Response Time',
    width: 260,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'sensitiveInformationInGetParams',
    headerName: 'Request Size',
    width: 260,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'enumerableResourcesId',
    headerName: 'Response Size',
    width: 260,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
];

export default function PerformanceGrid() {
  const apiCatalog = [] as any;
  const isLoadingAPICatalog = false;

  return (
    <PyntDataGrid
      autoHeight
      sx={{ width: '100%' }}
      rows={[
        {
          id: '123',
          tls: '2',
          verboseError: '200 200 230',
          serverResponseTime: 'password',
          sensitiveInformationInGetParams: '200k',
          enumerableResourcesId: '23K',
        },
      ]}
      loading={!apiCatalog && isLoadingAPICatalog}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      hideFooterPagination
      initialState={{
        sorting: { sortModel: [{ field: 'timestamp', sort: 'desc' }] },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
