import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SnackbarContext } from '../../contexts/Snackbar';
import { usePersistedState } from '../../hooks/usePersistedState';
import {
  addVariablesToCollection,
  getAllWorkspaces,
  getCollectionsInWorkspace,
  getEnviromentsInWorkspace,
} from '../../services/PostmanService';
import { runPostmanScanOnCloud } from '../../services/ScansService';
import { track } from '../../utils/analytics';
import TextFieldTitle from '../Common/TextFieldTitle';
import {
  CloudPostmanRunScanData,
  PostmanRunScanData,
  RunScanData,
} from './RunScanDrawer';
import RunScanInstructionsWizard from './RunScanInstructionsWizard';

const goatCollectionId = '22821296-23bb40ff-e0a6-40eb-8bf1-13016c206f8b';

interface Props {
  data: PostmanRunScanData | CloudPostmanRunScanData;
  setData:
    | ((newData: RunScanData) => void)
    | ((updateCb: (current: RunScanData) => RunScanData) => void);
  onDone?: () => void;
  setDisabled?: (disabled: boolean) => void;
}

const PostmanRunScanWizard = ({
  data,
  setData,
  onDone,
  setDisabled,
}: Props) => {
  const { show } = useContext(SnackbarContext);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [workspaces, setWorkspaces] = useState<any[]>();
  const [collections, setCollections] = useState<any[] | undefined>();
  const [environments, setEnvironments] = useState<any[] | undefined>();
  const [completed, setCompleted] = useState(false);
  const [defaultPostmanApiKey, setDefaultPostmanApiKey] = usePersistedState(
    'postman_api_key',
    undefined,
    localStorage,
  );

  useEffect(() => {
    if (!data.destinationWorkspaceId || !workspaces) {
      setCollections([]);
      return;
    }

    if (
      data.testCollectionId &&
      collections?.length &&
      collections.some((c) => c.workspaceId === data.destinationWorkspaceId)
    ) {
      return;
    }

    setCollections(undefined);
    getCollectionsInWorkspace(data.destinationWorkspaceId, data.key)
      .then((collections: any[]) => {
        collections.splice(0, 0, {
          id: goatCollectionId,
          uid: goatCollectionId,
          name: 'goat (example collection)',
        });
        setCollections(collections);
        setData((data) => ({ ...data, testCollectionId: collections?.[0].id }));
      })
      .catch((e) => {
        console.error(e);
        Sentry.captureException(e);
      });

    setEnvironments(undefined);
    getEnviromentsInWorkspace(data.destinationWorkspaceId, data.key)
      .then((environments: any[]) => {
        setEnvironments(environments);
        setData((data) => ({ ...data, environmentId: environments?.[0]?.id }));
      })
      .catch((e) => {
        console.error(e);
        Sentry.captureException(e);
      });
  }, [data.key, data.destinationWorkspaceId, workspaces]);

  const handleSubmitClick = useCallback((postmanApiKey: string) => {
    setIsLoadingSubmit(true);

    getAllWorkspaces(postmanApiKey)
      .then((workspaces) => {
        setWorkspaces(workspaces);
        setData((data) => ({
          ...data,
          destinationWorkspaceId: workspaces?.[0]?.id,
        }));
        setDefaultPostmanApiKey(postmanApiKey);
      })
      .catch((e) => {
        show('Invalid Postman api key');
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  }, []);

  const handleNextClick = useCallback(() => {
    if (!data.appId) {
      show('Select an application first');
      return;
    }

    const workspace = workspaces?.find(
      (w) => w.id === data.destinationWorkspaceId,
    );
    const collection = collections?.find((c) => c.id === data.testCollectionId);
    if (
      !data.destinationWorkspaceId ||
      !data.testCollectionId ||
      !workspace ||
      !collection
    ) {
      show('Select a workspace and collection');
      return;
    }

    if (data.type === 'cloud_scan') {
      if (!data.key) {
        show('Please add your postman api key');
        return;
      }
      setIsLoadingNext(true);
      setDisabled?.(true);

      runPostmanScanOnCloud(
        data.appId,
        data.key,
        collection.uid,
        data.environmentId,
      )
        .then(() => {
          show('Scan started, usually takes around 2-3 minutes to complete..');
          onDone?.();
        })
        .catch((e) => {
          show(
            'Error running the scan: ' + e.response?.data?.error?.message ||
              e.message,
          );
          console.error(e);
          Sentry.captureException(e);
        })
        .finally(() => {
          setIsLoadingNext(false);
          setDisabled?.(false);
        });
      return;
    }

    setIsLoadingNext(true);

    addVariablesToCollection(
      collection.uid,
      data.destinationWorkspaceId,
      data.appId,
      data.key,
    )
      .then(() => {
        setCompleted(true);
        setDisabled?.(true);
      })
      .finally(() => {
        setIsLoadingNext(false);
      });
  }, [
    data.type,
    data.appId,
    data.key,
    data.testCollectionId,
    data.destinationWorkspaceId,
    data.environmentId,
    workspaces,
    collections,
  ]);

  useEffect(() => {
    if (!defaultPostmanApiKey) return;

    if (!data.key) {
      setData((d) => ({ ...d, key: defaultPostmanApiKey }));
      handleSubmitClick(defaultPostmanApiKey);
    }
  }, [data, defaultPostmanApiKey, handleSubmitClick]);

  return !completed ? (
    <>
      <Stack alignItems={'start'} gap={0.25}>
        <Stack
          direction={'row'}
          alignItems={'end'}
          gap={1}
          alignSelf={'stretch'}
        >
          <Box flex={1}>
            <TextFieldTitle title="Postman API Key" />
            <TextField
              fullWidth
              size="small"
              type="password"
              placeholder="Postman API Key"
              value={data?.['key']}
              onChange={(v) =>
                setData((data) => ({
                  ...data,
                  key: v.target.value?.trim(),
                }))
              }
              required
            />
          </Box>
          <LoadingButton
            variant="outlined"
            size="large"
            loading={isLoadingSubmit}
            disabled={isLoadingNext}
            onClick={() => {
              track(
                'web_app_run_scan_drawer_postman_submit_api_key_button_click',
              );
              handleSubmitClick(data.key ?? '');
            }}
          >
            Submit
          </LoadingButton>
        </Stack>
        <Button
          variant="text"
          size="small"
          sx={{ fontSize: 11 }}
          onClick={() => {
            window.open(
              'https://learning.postman.com/docs/developer/postman-api/authentication/#generate-a-postman-api-key',
              '_blank',
            );
          }}
        >
          I can&apos;t find my Postman&apos;s API key. How do I generate it?
        </Button>
      </Stack>
      <Box>
        <TextFieldTitle title="Destination Workspace" />
        <FormControl fullWidth size="small">
          <Select
            disableUnderline
            disabled={isLoadingNext || !workspaces}
            value={data.destinationWorkspaceId || ''}
            onChange={(e) => {
              const value = e.target.value;
              if (value !== data.destinationWorkspaceId) {
                setData((data) => ({
                  ...data,
                  destinationWorkspaceId: value,
                }));
              }
            }}
          >
            {workspaces?.length ? (
              <></>
            ) : (
              <MenuItem value={''} disabled>
                {isLoadingSubmit ? 'Loading...' : 'None'}
              </MenuItem>
            )}
            {workspaces?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <TextFieldTitle title="Test Collection" />
        <FormControl fullWidth size="small">
          <Select
            disableUnderline
            disabled={isLoadingNext || !workspaces}
            value={data.testCollectionId || ''}
            onChange={(v) =>
              setData((data) => ({
                ...data,
                testCollectionId: v.target.value,
              }))
            }
          >
            {collections?.length ? (
              <></>
            ) : (
              <MenuItem value={''} disabled>
                {!collections ? 'Loading...' : 'Select...'}
              </MenuItem>
            )}
            {collections?.map((col) => (
              <MenuItem key={col.id} value={col.id}>
                {col.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {data.type === 'cloud_scan' ? (
        <Box>
          <TextFieldTitle title="Environment (Optional)" />
          <FormControl fullWidth size="small">
            <Select
              disableUnderline
              disabled={isLoadingNext || !workspaces}
              value={data.environmentId || 'none'}
              onChange={(v) =>
                setData((data) => ({
                  ...data,
                  environmentId:
                    v.target.value === 'none' ? undefined : v.target.value,
                }))
              }
            >
              {environments ? (
                <></>
              ) : (
                <MenuItem value={''} disabled>
                  {!environments ? 'Loading...' : 'Select...'}
                </MenuItem>
              )}
              <MenuItem value={'none'}>None</MenuItem>
              {environments?.map((env) => (
                <MenuItem key={env.uid} value={env.uid}>
                  {env.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <></>
      )}
      <Stack direction={'row'} justifyContent={'end'}>
        <Box flex={1} />
        <LoadingButton
          variant="contained"
          size="large"
          loading={isLoadingNext}
          disabled={isLoadingSubmit}
          onClick={() => {
            track('web_app_run_scan_drawer_postman_next_button_click');
            handleNextClick();
          }}
        >
          Next
        </LoadingButton>
      </Stack>
    </>
  ) : (
    <>
      <Box height={2} />
      <Typography variant="h3">You’re almost done</Typography>
      <RunScanInstructionsWizard data={data} />
      <Stack direction={'row'} justifyContent={'end'} gap={2} pt={2}>
        <LoadingButton
          variant="outlined"
          size="large"
          onClick={() => {
            track('web_app_run_scan_drawer_postman_prev_button_click');

            setCompleted(false);
          }}
        >
          Previous
        </LoadingButton>
        <Box flex={1} />
        <LoadingButton
          variant="contained"
          size="large"
          onClick={() => {
            track('web_app_run_scan_drawer_postman_finish_button_click');

            onDone?.();
          }}
        >
          Finish
        </LoadingButton>
      </Stack>
    </>
  );
};

export default PostmanRunScanWizard;
