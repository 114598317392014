import { SvgIconProps } from './SvgIconProps';

export const OpenLockIcon = ({
  color = '#ACACBB',
  size = 24,
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 16H3.5C2.67334 16 2 15.3274 2 14.5V7.5C2 6.67261 2.67334 6 3.5 6H12.5C13.3267 6 14 6.67261 14 7.5V14.5C14 15.3274 13.3267 16 12.5 16ZM3.5 7C3.22461 7 3 7.224 3 7.5V14.5C3 14.776 3.22461 15 3.5 15H12.5C12.7754 15 13 14.776 13 14.5V7.5C13 7.224 12.7754 7 12.5 7H3.5Z"
        fill={color}
      />
      <path
        d="M11.5 4.5C11.224 4.5 11 4.276 11 4C11 2.34595 9.65405 1 8 1C6.34595 1 5 2.34595 5 4V6.5C5 6.776 4.776 7 4.5 7C4.224 7 4 6.776 4 6.5V4C4 1.79395 5.79395 0 8 0C10.2061 0 12 1.79395 12 4C12 4.276 11.776 4.5 11.5 4.5Z"
        fill={color}
      />
    </svg>
  );
};
