import { Box, Stack, SxProps } from '@mui/material';

import UsersGrid from '../components/Users/UsersGrid';
import SingleSignOn from '../components/Users/SingleSignOn';

const GRID_CONTAINER: SxProps = {
  width: '100%',
  px: 2,
  my: 2,
  bgcolor: 'white',
  borderRadius: 1,
  border: 'solid 1px #D3D3D3',
  boxShadow: '0px 0px 4px 0px #0000001A',
};

const Users = () => {
  return (
    <Stack>
      <Box sx={GRID_CONTAINER}>
        <UsersGrid />
      </Box>
      <SingleSignOn />
    </Stack>
  );
};

export default Users;
