import { Button, Stack, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { BRAND_DARK_BLUE } from '../../constants/colors';
import Arrow from '../Common/Arrow';

const HEADER: SxProps = {
  bgcolor: '#FAFBFC',
  minHeight: 44,
  borderRadius: '8px',
  paddingInline: 1.5,
  width: '100%',
};

const HEADER_BUTTON: SxProps = {
  p: 0,
  width: '100%',
  color: BRAND_DARK_BLUE,
};

const TITLE: SxProps = {
  flexGrow: 1,
  textAlign: 'start',
};

interface Props {
  collapsible?: boolean;
  isOpen?: boolean;
  title?: string;
  action?: ReactNode;
  onClick?: () => void;
}

function _CustomCardHeader({
  collapsible = false,
  isOpen = true,
  title,
  action,
}: Props) {
  return (
    <Stack direction={'row'} spacing={1.5} sx={HEADER} alignItems={'center'}>
      {collapsible ? <Arrow direction={isOpen ? 'TOP' : 'BOTTOM'} /> : <></>}
      {title ? (
        <Typography variant="h5" sx={TITLE}>
          {title}
        </Typography>
      ) : (
        <></>
      )}
      {action ? action : <></>}
    </Stack>
  );
}

export default function CustomCardHeader({
  collapsible = false,
  isOpen = true,
  title,
  action,
  onClick,
}: Props) {
  if (!collapsible && !onClick) {
    return (
      <_CustomCardHeader
        collapsible={collapsible}
        isOpen={isOpen}
        title={title}
        onClick={onClick}
        action={action}
      />
    );
  }

  return (
    <Button sx={HEADER_BUTTON} onClick={onClick}>
      <_CustomCardHeader
        collapsible={collapsible}
        isOpen={isOpen}
        title={title}
        onClick={onClick}
      />
    </Button>
  );
}
