import CloudOutlined from '@mui/icons-material/CloudOutlined';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '../../assets/svgs/icons/ChevronRightIcon';
import { ExportIcon } from '../../assets/svgs/icons/ExportIcon';
import { BRAND_ORANGE, DARK_GRAY } from '../../constants/colors';
import { ScanHistoryItem } from '../../models/scanHistory';
import { track } from '../../utils/analytics';
import { APIMethodChip, CustomDrawer } from '../Common';

const TABLE_HEADER: SxProps = {
  fontWeight: 500,
  fontSize: 12,
  letterSpacing: '-0.3px',
  color: DARK_GRAY,
};
const BOTTOM_SPACER: SxProps = { height: 14 };

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  historyScan?: ScanHistoryItem;
  onOpenReportClick: () => void;
}

const HistoryScanDrawer = ({
  historyScan,
  isOpen,
  setIsOpen,
  onOpenReportClick,
}: Props) => {
  const navigate = useNavigate();

  const navigateToEnpoint = (
    endpoint: { path: string; method: string },
    appId?: string,
  ) => {
    track('web_app_history_scan_drawer_go_to_enpoint_button_click', {
      endpoint,
    });

    navigate({
      pathname: '/dashboard/api-catalog',
      search: `?filter=${JSON.stringify({
        method: endpoint.method,
        path: endpoint.path,
        application: appId,
      })}`,
    });
  };

  return (
    <>
      <CustomDrawer
        header={{
          caption: 'Scan ID',
          title: historyScan?.scan_id,
        }}
        open={isOpen}
        setOpen={() => setIsOpen?.(false)}
      >
        {historyScan ? (
          <Stack direction={'column'}>
            <Grid
              container
              columnSpacing={0}
              rowSpacing={0.5}
              alignItems={'center'}
            >
              <Grid item xs={1.7}>
                <Typography variant="body2" sx={TABLE_HEADER}>
                  Method
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2" sx={TABLE_HEADER}>
                  Endpoint
                </Typography>
              </Grid>

              {historyScan.endpoints.map((endpoint) => (
                <Grid item xs={12} key={JSON.stringify(endpoint)}>
                  <Grid
                    container
                    columnSpacing={0}
                    rowSpacing={0.5}
                    alignItems={'center'}
                  >
                    <Grid item xs={1.7}>
                      <APIMethodChip method={endpoint.method} />
                    </Grid>
                    <Grid item xs={true}>
                      <Stack
                        spacing={0.5}
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <CloudOutlined
                          sx={{ fontSize: 20, color: DARK_GRAY }}
                        />
                        <Typography>{endpoint.path}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={'auto'}>
                      <IconButton
                        onClick={() =>
                          navigateToEnpoint(endpoint, historyScan.application)
                        }
                      >
                        <ExportIcon size={20} color={BRAND_ORANGE} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Box sx={BOTTOM_SPACER} />
            <Box>
              <Button
                variant="contained"
                endIcon={<ChevronRightIcon color={'white'} />}
                onClick={() => onOpenReportClick()}
              >
                View scan report
              </Button>
            </Box>
          </Stack>
        ) : (
          <Box></Box>
        )}
      </CustomDrawer>
    </>
  );
};

export default HistoryScanDrawer;
