import { Box, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { ProfileIcon } from '../../assets/svgs/icons/ProfileIcon';
import { BRIGHT_BLUE, DARK_GRAY } from '../../constants/colors';
import PyntDataGrid from '../Common/PyntDataGrid';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    renderCell: ({ value }) => (
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <ProfileIcon color={DARK_GRAY} />
        <Box>{value}</Box>
      </Stack>
    ),
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 180,
    renderCell: ({ value }) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          bgcolor: BRIGHT_BLUE,
          pl: 2,
          pr: 2,
          borderRadius: 2,
          fontWeight: 500,
        }}
      >
        {value}
      </Box>
    ),
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 110,
    renderCell: ({ value }) => (
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <ProfileIcon color={DARK_GRAY} />
        <Box>{value}</Box>
      </Stack>
    ),
  },
];

export default function ParametersGrid() {
  const apiCatalog = [] as any;
  const isLoadingAPICatalog = false;

  return (
    <PyntDataGrid
      autoHeight
      sx={{ width: '100%' }}
      rows={[{ id: '123', name: 'UserName', type: 'String', location: 'body' }]}
      loading={!apiCatalog && isLoadingAPICatalog}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      hideFooterPagination
      initialState={{
        sorting: { sortModel: [{ field: 'timestamp', sort: 'desc' }] },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
