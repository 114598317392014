import { Button, SxProps } from '@mui/material';
import { ChevronRightIcon } from '../../assets/svgs/icons/ChevronRightIcon';

interface Props {
  onClick?: () => void;
}

const VIEW_ALL_BUTTON: SxProps = {
  color: 'black',
  fontWeight: 500,
};

const ViewAllButton = ({ onClick }: Props) => {
  return (
    <Button
      sx={VIEW_ALL_BUTTON}
      endIcon={<ChevronRightIcon />}
      onClick={onClick}
    >
      View all
    </Button>
  );
};

export default ViewAllButton;
