interface Props {
  width?: number | string;
  height?: number | string;
}

export const SigninBackground = ({ height = 960, width = 342 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 342 960"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M175.819 906.457C167.075 847.439 181.158 796.545 181.158 796.545C191.927 755.247 213.434 716.187 245.742 683.873L775.332 154.668C785.886 144.122 785.886 126.922 775.332 116.375C764.778 105.828 747.565 105.828 737.011 116.375L440.875 412.296C397.584 455.555 327.385 455.555 284.093 412.296C240.802 369.036 240.802 298.888 284.093 255.628L580.23 -40.2922C677.244 -137.236 835.099 -137.236 932.114 -40.2922C1029.13 56.6515 1029.13 214.392 932.114 311.336L402.554 840.54C392 851.087 392 868.287 402.554 878.833L413.016 889.288C423.571 899.835 440.783 899.835 451.337 889.288L740.509 600.327C783.8 557.067 853.999 557.067 897.29 600.327C940.582 643.587 940.582 713.735 897.29 756.995L685.466 968.664H685.221L604.437 1049.39C506.318 1147.44 346.683 1147.44 248.595 1049.39C222.792 1023.61 203.831 993.529 191.62 961.459C191.62 961.459 181.004 940.948 175.88 906.457H175.819Z"
          fill="url(#paint0_linear_3742_19640)"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M133.031 978.843L132.953 978.692L132.952 978.691L132.95 978.686L132.932 978.651C132.915 978.619 132.889 978.566 132.853 978.494C132.812 978.412 132.759 978.305 132.695 978.173C132.646 978.073 132.59 977.957 132.528 977.827C132.242 977.229 131.822 976.324 131.298 975.125C130.251 972.728 128.792 969.157 127.172 964.514C123.931 955.228 120.046 941.661 117.516 924.627L117.136 922.067H117.089C113.211 893.87 114.52 867.678 116.856 848.339C118.058 838.384 119.53 830.262 120.699 824.64C121.283 821.829 121.791 819.644 122.152 818.169C122.332 817.431 122.475 816.871 122.572 816.498C122.621 816.312 122.658 816.173 122.682 816.082L122.71 815.982L122.716 815.959L122.717 815.955L122.717 815.954L122.723 815.934L122.728 815.912C133.367 775.114 154.613 736.53 186.531 704.604L716.121 175.401C727.848 163.682 727.848 144.582 716.121 132.863C704.395 121.146 685.284 121.146 673.559 132.863L377.422 428.784C335.302 470.873 267.002 470.873 224.882 428.784C182.763 386.696 182.763 318.449 224.882 276.361L521.018 -19.5598C616.861 -115.333 772.818 -115.333 868.661 -19.5598C964.503 76.2122 964.503 232.052 868.661 327.824L339.101 857.029C327.374 868.747 327.374 887.847 339.101 899.566L349.564 910.02C361.289 921.738 380.4 921.738 392.126 910.02L681.297 621.06C723.417 578.97 791.718 578.97 833.838 621.06C875.956 663.148 875.956 731.395 833.838 773.483L622.892 984.274H622.647L621.768 985.152L540.985 1065.88C444.037 1162.75 286.3 1162.75 189.384 1065.88L187.263 1068L189.384 1065.88C163.889 1040.4 145.156 1010.69 133.091 979.002L133.031 978.843Z"
          stroke="url(#paint1_linear_3742_19640)"
          strokeWidth="6"
        />
        <g clipPath="url(#clip0_3742_19640)">
          <path
            d="M34.6891 618.264L-24.1211 677.074L-22.982 678.213L35.8282 619.403L34.6891 618.264Z"
            fill="url(#paint2_linear_3742_19640)"
          />
        </g>
      </g>
      <path
        d="M526.325 238.401L230.945 533.78L232.232 535.067L527.611 239.687L526.325 238.401Z"
        fill="url(#paint3_linear_3742_19640)"
      />
      <path
        d="M337.061 289.733L-12.0781 638.872L-10.7918 640.158L338.348 291.019L337.061 289.733Z"
        fill="url(#paint4_linear_3742_19640)"
      />
      <path
        opacity="0.8"
        d="M507.379 315.999L212 611.379L213.286 612.665L508.666 317.286L507.379 315.999Z"
        fill="url(#paint5_linear_3742_19640)"
      />
      <path
        opacity="0.8"
        d="M246.81 556L188 614.811L189.139 615.95L247.949 557.139L246.81 556Z"
        fill="url(#paint6_linear_3742_19640)"
      />
      <g opacity="0.8" filter="url(#filter0_d_3742_19640)">
        <path
          d="M472.018 329.302C472.018 329.302 271.705 537.735 266.63 542.713C261.554 547.691 256.639 547.438 253.553 544.292C250.467 541.146 250.379 536.298 255.454 531.319C260.529 526.341 472.018 329.302 472.018 329.302Z"
          fill="url(#paint7_linear_3742_19640)"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3742_19640"
          x="241.406"
          y="319.302"
          width="240.613"
          height="237.268"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.5 0 0 0 0 0.2 0 0 0 0.8 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3742_19640"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3742_19640"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3742_19640"
          x1="630.512"
          y1="-181.761"
          x2="1000.63"
          y2="892.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5803E" />
          <stop offset="1" stopColor="#FA813B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3742_19640"
          x1="527.584"
          y1="-94.3896"
          x2="527.584"
          y2="1141.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0059F7" />
          <stop offset="1" stopColor="#0059F7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3742_19640"
          x1="-24.1211"
          y1="648.231"
          x2="35.8362"
          y2="648.231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0059F7" />
          <stop offset="0.99" stopColor="#272F5A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3742_19640"
          x1="230.945"
          y1="386.747"
          x2="527.611"
          y2="386.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0059F7" />
          <stop offset="0.99" stopColor="#272F5A" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3742_19640"
          x1="1.30216"
          y1="478.458"
          x2="320.492"
          y2="505.082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0059F7" />
          <stop offset="0.99" stopColor="#272F5A" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3742_19640"
          x1="212"
          y1="464.345"
          x2="508.666"
          y2="464.345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8237" />
          <stop offset="0.99" stopColor="#FF8237" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3742_19640"
          x1="137.537"
          y1="535.073"
          x2="206.402"
          y2="491.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8237" />
          <stop offset="0.99" stopColor="#FF8237" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3742_19640"
          x1="212.405"
          y1="584.237"
          x2="476.881"
          y2="325.855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAD16A" />
          <stop offset="0.480311" stopColor="#E26E39" />
          <stop offset="1" stopColor="#E26E39" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_3742_19640">
          <rect
            width="296.666"
            height="296.666"
            fill="white"
            transform="translate(-173 381.808)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
