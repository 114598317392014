import CloseRounded from '@mui/icons-material/CloseRounded';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { BRAND_DARK_BLUE } from '../../constants/colors';

const DRAWER: SxProps = {
  borderStartStartRadius: '20px',
  borderEndStartRadius: '20px',
  width: 501,
};
const CONATINER: SxProps = { p: 2, height: '100%' };
const SPACER: SxProps = { height: 4 };
const SPACER_2: SxProps = { height: 12 };

interface CustomDrawerHeader {
  title?: string;
  caption?: string;
  subtitle?: string;
  hideDivider?: boolean;
  icon?: ReactNode;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: ReactNode;
  header?: CustomDrawerHeader;
  sx?: SxProps;
}

const CustomDrawer = ({ children, open, setOpen, header, sx }: Props) => {
  return (
    <Drawer
      elevation={8}
      anchor="right"
      open={open}
      onClose={() => setOpen?.(false)}
      PaperProps={{
        sx: [DRAWER, sx as any],
      }}
    >
      <Stack direction={'column'} sx={CONATINER}>
        {!header ? (
          <></>
        ) : (
          <>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              {header.icon ? header.icon : <></>}
              <Stack>
                {header.caption ? (
                  <Typography variant="body2">{header.caption}</Typography>
                ) : (
                  <></>
                )}
                {header.title ? (
                  <Typography variant="h6" maxWidth={380}>
                    {header.title}
                  </Typography>
                ) : (
                  <></>
                )}
                {header.subtitle ? (
                  <Typography variant="subtitle1">{header.subtitle}</Typography>
                ) : (
                  <></>
                )}
              </Stack>
              <Box flex={1} />
              <IconButton
                onClick={() => {
                  setOpen?.(false);
                }}
              >
                <CloseRounded sx={{ color: BRAND_DARK_BLUE }} />
              </IconButton>
            </Stack>
            {header.hideDivider ? (
              <></>
            ) : (
              <>
                <Box sx={SPACER} />
                <Divider />
              </>
            )}
            <Box sx={SPACER_2} />
          </>
        )}
        {children}
      </Stack>
    </Drawer>
  );
};

export default CustomDrawer;
