import { Box, CircularProgress, Container, Grid, SxProps } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIDetailedViewHeader from '../components/APIDetailedView/APIDetailedViewHeader';
import ConfigurationInfoGrid from '../components/APIDetailedView/ConfigurationInfoGrid';
import LastScanInfoGrid from '../components/APIDetailedView/LastScanInfoGrid';
import ParametersGrid from '../components/APIDetailedView/ParametersGrid';
import PerformanceGrid from '../components/APIDetailedView/PerformanceGrid';
import PllInfoGrid from '../components/APIDetailedView/PllInfoGrid';
import ResponseCodeGrid from '../components/APIDetailedView/ResponseCodeGrid';
import SecurityInfoGrid from '../components/APIDetailedView/SecurityInfoGrid';
import SourceInfoGrid from '../components/APIDetailedView/SourceInfoGrid';
import DashboardAppBar from '../components/Common/DashboardAppBar';
import CustomCard from '../components/CustomCard/CustomCard';
import { AuthContext } from '../contexts/Auth';
import { APICatalogItem } from '../models/apiCatalog';

const CENTER: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  minHeight: '50vh',
};

const APIDetailedView = () => {
  const { id } = useParams();
  const { isProd } = useContext(AuthContext);

  const [endpoint, setEndpoint] = useState<APICatalogItem>();
  const [endpointId, setEndpointId] = useState<string>('');
  const [isLoadingEndpoint, setIsLoadingEndpoint] = useState(false);

  useEffect(() => {
    if (id === endpointId) return;

    setEndpointId(id ?? '');
    setEndpoint(undefined);

    setIsLoadingEndpoint(true);
  }, [id]);

  return (
    <>
      <DashboardAppBar title={'Endpoint'} />
      <Container maxWidth={false}>
        <APIDetailedViewHeader endpointId={endpointId} endpoint={endpoint} />
        <Grid container spacing={3} pt={3}>
          {isLoadingEndpoint ? (
            <Box sx={CENTER}>
              <CircularProgress />
            </Box>
          ) : (
            <></>
          )}
          {!isLoadingEndpoint && endpoint ? (
            <>
              {!isProd && (
                <>
                  <Grid item xs={12} lg={6}>
                    <CustomCard title="Parameters" collapsible>
                      <ParametersGrid />
                    </CustomCard>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CustomCard title="Response Code" collapsible>
                      <ResponseCodeGrid />
                    </CustomCard>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CustomCard title="Source" collapsible>
                      <SourceInfoGrid />
                    </CustomCard>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CustomCard title="PII" collapsible>
                      <PllInfoGrid />
                    </CustomCard>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CustomCard title="Configuration Issues" collapsible>
                      <ConfigurationInfoGrid />
                    </CustomCard>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CustomCard title="Security Findings" collapsible>
                      <SecurityInfoGrid />
                    </CustomCard>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CustomCard title="Performance Insights" collapsible>
                      <PerformanceGrid />
                    </CustomCard>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <CustomCard title="Latest Scan" collapsible>
                  <LastScanInfoGrid endpoint={endpoint} />
                </CustomCard>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default APIDetailedView;
