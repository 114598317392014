import { post } from './BaseService';

export async function uploadFile(file: File, folder: string, prefix?: string) {
  const uploadUrlResponse = await post('/artifacts/upload-url', {
    folder: folder,
    file_name: prefix ? prefix + file.name : file.name,
  });

  const {
    upload_url: uploadUrl,
    path,
    bucket_name: bucketName,
  } = uploadUrlResponse.data;

  const arrayBuffer = await file.arrayBuffer();

  await fetch(uploadUrl, {
    method: 'PUT',
    body: arrayBuffer,
  });

  return {
    path,
    bucketName,
  };
}
