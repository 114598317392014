import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { AddIcon } from '../../assets/svgs/icons/AddIcon';
import { APISource } from '../../contexts/User';
import APISourceIcon from '../APISources/APISourceIcon';
import { WhiteTooltip } from '../Common/WhiteTooltip';

const SOURCE_ICON_IN_STACK = {
  bgcolor: 'white',
  border: 0.55,
  borderColor: '#C1C1C1',
  borderRadius: '50%',
  height: 24,
  width: 24,
  mx: -0.6,
};

type PartialAPISource = Pick<APISource, 'name' | 'type' | 'source_id'> & {
  status?: 'PENDING' | 'FAILED' | 'SUCCEEDED' | string;
};

interface Props {
  sources: PartialAPISource[];
  hideAdd?: boolean;
  onAddClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSourceClick?: (
    source: PartialAPISource,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export default function SourcesGridCell(props: Props) {
  const { sources, hideAdd, onAddClick, onSourceClick } = props;

  if (!sources.length) {
    return hideAdd ? (
      <></>
    ) : (
      <Box px={0.5}>
        <IconButton
          color="secondary"
          onClick={
            onAddClick
              ? (e) => {
                  onAddClick(e);
                  e.stopPropagation();
                }
              : undefined
          }
        >
          <AddIcon size={16} />
        </IconButton>
      </Box>
    );
  }

  return (
    <SourcesTooltip {...props}>
      <Stack direction={'row'} alignItems={'center'}>
        {sources.map((source, i) => {
          return (
            <IconButton
              // eslint-disable-next-line react/no-array-index-key
              key={`${source.source_id}-${i}`}
              onClick={
                onSourceClick
                  ? (e) => {
                      onSourceClick(source, e);
                      e.stopPropagation();
                    }
                  : undefined
              }
              sx={[
                sources.length > 1 ? SOURCE_ICON_IN_STACK : {},
                { zIndex: sources.length - i },
              ]}
            >
              <APISourceIcon
                type={source.type}
                size={sources.length > 1 ? 20 : 24}
              />
            </IconButton>
          );
        })}
      </Stack>
    </SourcesTooltip>
  );
}

const SourcesTooltip = ({
  children,
  sources,
}: Props & { children: ReactElement }) => {
  return (
    <WhiteTooltip
      title={
        <React.Fragment>
          <Stack direction="column" gap={2}>
            <Typography fontWeight={'bold'}>Sources</Typography>
            <Stack direction="column" gap={1.5}>
              {sources.map((source, i) => {
                return (
                  <Stack
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${source.source_id}-${i}`}
                    justifyContent={'space-between'}
                    direction="row"
                    gap={3}
                  >
                    <Stack direction="row" alignItems={'center'} gap={1}>
                      <APISourceIcon type={source.type} size={24} />
                      <Typography>{source.name || source.type}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems={'center'} gap={1}>
                      <Typography color={'#7B7B88'} fontSize={14}>
                        {!source.status || source.status === 'SUCCEEDED' ? (
                          <>Connected</>
                        ) : (
                          <></>
                        )}
                        {source.status === 'FAILED' ? <>Failed</> : <></>}
                        {source.status === 'PENDING' ? <>Pending</> : <></>}
                      </Typography>
                      <Box
                        bgcolor={
                          source.status === 'FAILED'
                            ? '#F53255'
                            : source.status === 'PENDING'
                              ? '#FF8C00'
                              : 'green'
                        }
                        width={6}
                        height={6}
                        borderRadius={3}
                      ></Box>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </React.Fragment>
      }
      arrow
    >
      {children}
    </WhiteTooltip>
  );
};
