import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, SxProps, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../contexts/Application';
import { SnackbarContext } from '../../contexts/Snackbar';
import {
  Application,
  UserContext,
  UserContextProps,
} from '../../contexts/User';
import {
  createApplication,
  updateApplication,
} from '../../services/ApplicationsService';
import { track } from '../../utils/analytics';
import { CustomDrawer, TextFieldTitle } from '../Common';

const BOTTOM_SPACER: SxProps = { minHeight: 14, flex: 1 };

interface Props {
  mode: 'create' | 'edit';
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  application?: Application;
  onSave?: (app: Application, created?: boolean) => void;
}

const EditApplicationDrawer = (props: Props) => {
  const { refetchApplication } = useContext(
    ApplicationContext,
  ) as ApplicationContextProps;
  const { fetchApplications } = useContext(UserContext) as UserContextProps;
  const { show } = useContext(SnackbarContext);
  const { mode, isOpen, setIsOpen } = props;
  const application = mode === 'edit' ? props.application : undefined;

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [applicationName, setApplicationName] = useState(application?.name);

  useEffect(() => {
    if (!isOpen) {
      setApplicationName(undefined);
    } else {
      setApplicationName(application?.name);
    }
  }, [application, isOpen]);

  const validateAppName = (appName: string) => {
    if (appName.trim() !== appName) {
      return 'There should not be any padding spaces';
    }
    if (appName.length < 2) {
      return 'App name should be at least two characters long';
    }

    return null;
  };

  const onSaveClick = () => {
    const appName = applicationName ?? '';
    const issue = validateAppName(appName);

    const id = Date.now();
    track('web_app_save_application_button_click', {
      id,
      appName,
      issue,
      mode,
    });

    if (issue) {
      show(issue);
      return;
    }

    setIsLoadingSave(true);

    const isEdit = mode === 'edit';
    const promise = isEdit
      ? updateApplication(application?.app_id ?? '', appName)
      : createApplication(appName);

    promise
      .then(async (res) => {
        refetchApplication();
        await fetchApplications().catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
        setIsLoadingSave(false);
        setIsOpen(false);
        props.onSave?.(res, !isEdit);

        track('web_app_save_application_action_success', {
          id,
          appName,
          issue,
          mode,
        });
      })
      .catch((e: AxiosError<{ detail?: string }>) => {
        if (e.response?.data?.detail) {
          show(e.response.data.detail, 'error');
        } else {
          show(e.message ?? e.toString(), 'error');
        }
        track('web_app_save_application_action_failed', {
          id,
          appName,
          issue,
          mode,
          error: e.message || e.toString(),
        });
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoadingSave(false);
      });
  };

  return (
    <CustomDrawer
      header={{
        title: mode === 'edit' ? 'Edit Application' : 'Create New Application',
        subtitle:
          mode === 'edit'
            ? `Current application name: ${application?.name}`
            : '',
      }}
      open={isOpen}
      setOpen={() => setIsOpen?.(false)}
    >
      <Stack
        direction={'column'}
        alignItems={'stretch'}
        flex={1}
        height={'100%'}
      >
        <Box id="edit-application-name-input">
          <TextFieldTitle title="Application Name" />
          <TextField
            placeholder="Name"
            fullWidth
            variant="filled"
            size="small"
            value={applicationName}
            onChange={(v) => setApplicationName(v.target.value?.trim())}
            error={
              applicationName != null
                ? !!validateAppName(applicationName)
                : undefined
            }
            helperText={
              applicationName != null ? validateAppName(applicationName) : null
            }
          />
        </Box>
        <Box sx={BOTTOM_SPACER} />
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
          <Button variant="text" onClick={() => setIsOpen?.(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={onSaveClick}
            loading={isLoadingSave}
            disabled={!!validateAppName(applicationName ?? '')}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </CustomDrawer>
  );
};

export default EditApplicationDrawer;
