import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import PyntDataGrid from '../Common/PyntDataGrid';

const CHIP_WRAPPER = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#00A676',
  bgcolor: '#00A67610',
  pl: 2,
  pr: 2,
  borderRadius: 2,
  fontWeight: 600,
};

const columns: GridColDef[] = [
  {
    field: 'number',
    headerName: 'Number',
    width: 180,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 180,
    renderCell: ({ value }) => <Box sx={CHIP_WRAPPER}>{value}</Box>,
  },
];

export default function ResponseCodeGrid() {
  const apiCatalog = [] as any;
  const isLoadingAPICatalog = false;

  return (
    <PyntDataGrid
      autoHeight
      sx={{ width: '100%' }}
      rows={[{ id: '123', number: '200', description: 'OK' }]}
      loading={!apiCatalog && isLoadingAPICatalog}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      hideFooterPagination
      initialState={{
        sorting: { sortModel: [{ field: 'timestamp', sort: 'desc' }] },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
