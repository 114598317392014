import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import PyntDataGrid from '../Common/PyntDataGrid';

const columns: GridColDef[] = [
  {
    field: 'type',
    headerName: 'Type',
    width: 180,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 180,
    renderCell: ({ value }) => <Box>{value}</Box>,
  },
];

export default function PllInfoGrid() {
  const apiCatalog = [] as any;
  const isLoadingAPICatalog = false;

  return (
    <PyntDataGrid
      autoHeight
      sx={{ width: '100%' }}
      rows={[
        { id: '123', type: 'Person Name', value: 'James' },
        { id: '124', type: 'Credit Card', value: '4242 4242 4242 4242' },
      ]}
      loading={!apiCatalog && isLoadingAPICatalog}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      hideFooterPagination
      initialState={{
        sorting: { sortModel: [{ field: 'timestamp', sort: 'desc' }] },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
