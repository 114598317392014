import { PieChart } from '@mui/x-charts/PieChart';
import { Stack, Typography } from '@mui/material';
import { DefaultizedPieValueType, pieArcLabelClasses } from '@mui/x-charts';
import { useCallback, useMemo } from 'react';

export interface PieChartDataItem {
  value: number;
  label: string;
  color: string;
}

export interface PieChartWithCenterLabelProps {
  title: string;
  data: PieChartDataItem[];
}

const size = {
  height: 260,
  legend: { hidden: true },
};

export default function PieChartWithCenterLabel({
  title,
  data,
}: PieChartWithCenterLabelProps) {
  const totalCount = useMemo(
    () => data.map((item) => item.value).reduce((a, b) => a + b, 0),
    [data],
  );

  const getArcLabel = useCallback(
    (params: DefaultizedPieValueType) => {
      const percent = params.value / totalCount;
      return `${(percent * 100).toFixed(0)}%`;
    },
    [totalCount],
  );

  return (
    <Stack direction={'column'} spacing={3}>
      <Typography
        sx={{
          fontSize: 20,
          textAlign: 'start',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          bgcolor: '#F6F6F6',
          p: 1,
          pl: 2,
          pr: 2,
          borderRadius: 1,
          color: 'black',
        }}
      >
        {title}
      </Typography>
      <PieChart
        margin={{ left: 0, right: 0 }}
        series={[
          {
            data: data.filter((d) => d.value),
            innerRadius: 0,
            arcLabel: getArcLabel,
          },
        ]}
        {...size}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: 'white',
            fontSize: 12,
          },
        }}
      />
    </Stack>
  );
}
