import { Container } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DashboardAppBar,
  GridWrapper,
  SelectApplication,
} from '../components/Common';
import SelectVulnerabilityStatus from '../components/Common/SelectVulnerabilityStatus';
import VulnerabilitiesGrid from '../components/Vulnerabilities/VulnerabilitiesGrid';
import { UserContext, UserContextProps } from '../contexts/User';
import { usePersistedState } from '../hooks/usePersistedState';

const Vulnerabilities = () => {
  const [searchParams] = useSearchParams();
  const {
    selectedApplication,
    selectedApplicationId,
    setSelectedApplicationId,
  } = useContext(UserContext) as UserContextProps;

  const [selectedVulnerabilityStatus, setSelectedVulnerabilityStatus] =
    usePersistedState('pynt-selectedVulnerabilityStatus', '*');

  const filter = useMemo(() => {
    const val = searchParams.get('filter');
    if (!val) return null;

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [searchParams]);

  const sorting = useMemo(() => {
    const val = searchParams.get('sorting');
    if (!val) return [];

    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [searchParams]);

  const pyntFilter = useMemo(() => {
    let pyntFilter = { ...filter };
    if (selectedApplicationId !== '*') {
      pyntFilter = {
        ...pyntFilter,
        application_id: selectedApplicationId,
      };
    }
    if (selectedVulnerabilityStatus !== '*') {
      pyntFilter = {
        ...pyntFilter,
        status: selectedVulnerabilityStatus,
      };
    }
    return pyntFilter;
  }, [selectedApplicationId, selectedVulnerabilityStatus, filter]);

  return (
    <>
      <DashboardAppBar
        title={'Vulnerabilities'}
        startActions={
          <SelectApplication
            selectedApplicationId={selectedApplicationId}
            setSelectedApplicationId={setSelectedApplicationId}
          />
        }
        endActions={
          <SelectVulnerabilityStatus
            selectedVulnerabilityStatus={selectedVulnerabilityStatus}
            setSelectedVulnerabilityStatus={setSelectedVulnerabilityStatus}
          />
        }
      />
      <Container maxWidth={false}>
        <GridWrapper>
          <VulnerabilitiesGrid
            id="VulnerabilitiesGridInVulnerabilitiesScreen"
            pyntFilter={pyntFilter}
            autoHeight={false}
            filter={filter}
            sorting={[...sorting, { field: 'severity', sort: 'asc' }]}
            applicationId={selectedApplication?.app_id}
            columnVisibilityModel={{ application: true }}
            hideEvidenceInDrawer
          />
        </GridWrapper>
      </Container>
    </>
  );
};

export default Vulnerabilities;
