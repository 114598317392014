import { SvgIconProps } from './SvgIconProps';

export const ScanIcon = ({
  color = 'black',
  size = 24,
  style = {},
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M1.66663 8.12508C1.32496 8.12508 1.04163 7.84175 1.04163 7.50008V5.41675C1.04163 3.00008 3.00829 1.04175 5.41663 1.04175H7.49996C7.84163 1.04175 8.12496 1.32508 8.12496 1.66675C8.12496 2.00841 7.84163 2.29175 7.49996 2.29175H5.41663C3.69163 2.29175 2.29163 3.69175 2.29163 5.41675V7.50008C2.29163 7.84175 2.00829 8.12508 1.66663 8.12508Z"
        fill={color}
      />
      <path
        d="M18.3333 8.12508C17.9917 8.12508 17.7083 7.84175 17.7083 7.50008V5.41675C17.7083 3.69175 16.3083 2.29175 14.5833 2.29175H12.5C12.1583 2.29175 11.875 2.00841 11.875 1.66675C11.875 1.32508 12.1583 1.04175 12.5 1.04175H14.5833C16.9917 1.04175 18.9583 3.00008 18.9583 5.41675V7.50008C18.9583 7.84175 18.675 8.12508 18.3333 8.12508Z"
        fill={color}
      />
      <path
        d="M14.5834 18.9583H13.3334C12.9917 18.9583 12.7084 18.6749 12.7084 18.3333C12.7084 17.9916 12.9917 17.7083 13.3334 17.7083H14.5834C16.3084 17.7083 17.7084 16.3083 17.7084 14.5833V13.3333C17.7084 12.9916 17.9917 12.7083 18.3334 12.7083C18.675 12.7083 18.9584 12.9916 18.9584 13.3333V14.5833C18.9584 16.9999 16.9917 18.9583 14.5834 18.9583Z"
        fill={color}
      />
      <path
        d="M7.49996 18.9583H5.41663C3.00829 18.9583 1.04163 17 1.04163 14.5833V12.5C1.04163 12.1583 1.32496 11.875 1.66663 11.875C2.00829 11.875 2.29163 12.1583 2.29163 12.5V14.5833C2.29163 16.3083 3.69163 17.7083 5.41663 17.7083H7.49996C7.84163 17.7083 8.12496 17.9917 8.12496 18.3333C8.12496 18.675 7.84163 18.9583 7.49996 18.9583Z"
        fill={color}
      />
      <path
        d="M11.6667 15.2084H8.33337C6.31671 15.2084 5.20837 14.1001 5.20837 12.0834V7.91675C5.20837 5.90008 6.31671 4.79175 8.33337 4.79175H11.6667C13.6834 4.79175 14.7917 5.90008 14.7917 7.91675V12.0834C14.7917 14.1001 13.6834 15.2084 11.6667 15.2084ZM8.33337 6.04175C7.01671 6.04175 6.45837 6.60008 6.45837 7.91675V12.0834C6.45837 13.4001 7.01671 13.9584 8.33337 13.9584H11.6667C12.9834 13.9584 13.5417 13.4001 13.5417 12.0834V7.91675C13.5417 6.60008 12.9834 6.04175 11.6667 6.04175H8.33337Z"
        fill={color}
      />
      <path
        d="M15.8333 10.625H4.16663C3.82496 10.625 3.54163 10.3417 3.54163 10C3.54163 9.65833 3.82496 9.375 4.16663 9.375H15.8333C16.175 9.375 16.4583 9.65833 16.4583 10C16.4583 10.3417 16.175 10.625 15.8333 10.625Z"
        fill={color}
      />
    </svg>
  );
};
