import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import CopyField from '../components/Common/CopyTextField';
import TextFieldTitle from '../components/Common/TextFieldTitle';
import { AuthContext } from '../contexts/Auth';
import {
  getStoredAccessToken,
  getStoredRefreshToken,
} from '../services/BaseService';
import { updateOrgConfig, updateOrgName } from '../services/OrgService';

const GRID_CONTAINER: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  pt: 4,
};

const TEXT_FIELD: SxProps = {
  '.MuiFilledInput-root': {
    backgroundColor: 'white',
    border: 'solid 1px #D3D3D3',
    minWidth: 320,
  },
};

const OrgSettings = () => {
  const { organization, refreshUser } = useContext(AuthContext);
  const [organizationName, setOrganizationName] = useState(organization?.name);
  const [loadingSaveName, setLoadingSaveName] = useState(false);
  const [loadingSaveConfig, setLoadingSaveConfig] = useState(false);

  const onNameUpdateClick = useCallback(() => {
    setLoadingSaveName(true);
    updateOrgName(organizationName as string)
      .then(() => refreshUser())
      .finally(() => setLoadingSaveName(false));
  }, [organizationName]);

  const onCollectDataToggleClick = useCallback(
    (checked: boolean) => {
      if (!organization) return;

      setLoadingSaveConfig(true);
      updateOrgConfig(organization.organization_id, {
        allow_collect_data: checked,
      })
        .then(() => refreshUser())
        .finally(() => setLoadingSaveConfig(false));
    },
    [organization],
  );

  return (
    <Box sx={GRID_CONTAINER}>
      <Stack direction={'column'} spacing={2}>
        <Stack
          direction={'column'}
          spacing={0}
          justifyContent={'start'}
          alignItems={'start'}
        >
          <TextFieldTitle title="Organization name" />
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <TextField
              size="small"
              variant="filled"
              sx={TEXT_FIELD}
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
            <LoadingButton
              loading={loadingSaveName}
              onClick={onNameUpdateClick}
              disabled={organizationName === organization?.name}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
        <Stack
          direction={'column'}
          spacing={0}
          justifyContent={'start'}
          alignItems={'start'}
        >
          <TextFieldTitle title="Pynt ID" />
          <CopyField
            title="﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡﹡"
            sx={TEXT_FIELD}
            text={JSON.stringify({
              token: {
                access_token: getStoredAccessToken(),
                refresh_token: getStoredRefreshToken(),
                token_type: 'Bearer',
              },
            })}
          />
        </Stack>
        <Stack
          direction={'row'}
          spacing={0}
          justifyContent={'start'}
          alignItems={'center'}
        >
          <Checkbox
            checked={organization?.config.allow_collect_data}
            onChange={(_, checked) => onCollectDataToggleClick(checked)}
            disabled={loadingSaveConfig}
          />
          <Typography>Allow to collect data</Typography>
          {loadingSaveConfig && (
            <CircularProgress size={20} sx={{ marginInlineStart: 1 }} />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default OrgSettings;
