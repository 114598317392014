import { ContentCopy, KeyboardArrowRightRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid-pro';
import {
  CSSProperties,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { SecuringUnderline } from '../../assets/svgs/SecuringUnderline';
import { BRAND_ORANGE } from '../../constants/colors';
import { SnackbarContext } from '../../contexts/Snackbar';
import { track } from '../../utils/analytics';
// import BurpOnboardExplainModal from './BurpOnboardExplainModal';

const TITLE: SxProps = {
  color: 'white',
  fontSize: 36,
  fontWeight: 700,
  letterSpacing: '-1.2px',
};

const TEXT: SxProps = {
  color: 'white',
};

const OPTION_BUTTON: SxProps = {
  minHeight: 60,
  color: 'white',
  fontFamily: 'Raleway',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '40px',
  whiteSpace: 'nowrap',
};

const LINK: CSSProperties = {
  color: '#FF8237',
  fontFamily: 'Raleway',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '-0.187px',
  textDecorationLine: 'underline',
  flex: 1,
  textAlign: 'end',
};

const SUBMIT_BUTTON: CSSProperties = {
  width: '204px',
  height: '60px',
  fontFamily: 'Raleway',
  fontSize: '18px',
};

const COPY_BOX: SxProps = {
  border: '1px solid #40475c',
  p: 2,
  borderRadius: 1,
};

const BurpOnboardSetup = () => {
  const navigate = useNavigate();
  const { show } = useContext(SnackbarContext);
  // const [isExplainerModalOpen, setIsExplainerModalOpen] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const handleNextClick = useCallback(() => {
    track('burp-onboarding-2-next');

    setIsLoadingNext(true);
    navigate('../run');
    setIsLoadingNext(false);
  }, []);

  return (
    <>
      {/* <BurpOnboardExplainModal
        open={isExplainerModalOpen}
        onClose={() => {
          setIsExplainerModalOpen(false);
        }}
      /> */}
      <Stack alignItems={'center'} p={2} height={'100%'}>
        <Stack
          direction={'column'}
          spacing={1}
          pt={5}
          maxWidth={'700px'}
          height={'100%'}
        >
          <Box sx={{ display: 'inline', textAlign: 'start' }}>
            <Box sx={{ display: 'inline-block', position: 'relative' }}>
              <Typography
                sx={[TITLE, { whiteSpace: 'nowrap' }]}
                zIndex={1000}
                position={'relative'}
              >
                Use Pynt
              </Typography>
              <Box position="absolute" bottom={-4}>
                <SecuringUnderline />
              </Box>
            </Box>
            <Typography sx={[TITLE]} display={'inline'}>
              {' '}
              to start testing your APIs for security
            </Typography>
          </Box>
          <Box height={24} />
          <Stack direction={'row'} flexWrap={'wrap'} rowGap={2} gap={2}>
            {/* <OptionButtonBlurWrapper>
              <Button
                variant="outlined"
                sx={OPTION_BUTTON}
                onClick={() => {
                  track('burp-onboarding-2-about');
                  setIsExplainerModalOpen(true);
                }}
              >
                About Burp & Pynt integration
              </Button>
            </OptionButtonBlurWrapper> */}
            <OptionButtonBlurWrapper>
              <Button
                variant="outlined"
                sx={OPTION_BUTTON}
                onClick={() => {
                  track('burp-onboarding-2-prerequisites');
                  window.open(
                    'https://docs.pynt.io/documentation/onboarding/prerequisites',
                    '_blank',
                    'noreferrer',
                  );
                }}
              >
                Prerequisites
              </Button>
            </OptionButtonBlurWrapper>
            {/* <OptionButtonBlurWrapper>
              <Button
                variant="outlined"
                endIcon={
                  <img
                    src={youtubeIcon}
                    width={34}
                    style={{ marginInlineStart: 8 }}
                  />
                }
                sx={OPTION_BUTTON}
                onClick={() => {
                  track('burp-onboarding-2-tutorial');
                  window.open(
                    'https://www.youtube.com/watch?v=lkQam7zeZD0',
                    '_blank',
                    'noreferrer',
                  );
                }}
              >
                Tutorial video
              </Button>
            </OptionButtonBlurWrapper> */}
          </Stack>
          <Box height={12} />

          <Stack alignItems={'start'} flex={1} spacing={2}>
            {/* <Typography sx={TEXT} textAlign={'start'}>
              Burp Suite is a comprehensive platform for performing security
              testing of web applications. Its various tools work seamlessly
              together to support the entire testing process, from initial
              mapping and analysis of an application&apos;s attack surface,
              through to finding and exploiting security vulnerabilities.
            </Typography> */}

            <Typography sx={TEXT} fontWeight={600}>
              Two ways of using Pynt with Burp Suite for Efficient API Security
              Testing:
            </Typography>

            <Box>
              <Accordion
                sx={{
                  backgroundColor: 'transparent',
                  color: 'white',
                  px: 3,
                  py: 2,
                  paddingInlineEnd: 1,
                }}
                onChange={(e, expanded) => {
                  if (!expanded) return;
                  track('burp-onboarding-burp-2-pynt-xml-expand');
                }}
              >
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography fontWeight={600}>
                    Run Pynt on Burp suite XML traffic output
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2}>
                    <Typography>
                      One of the most straightforward ways to leverage Pynt for
                      efficient API security testing with Burp Suite is by
                      saving the web application&apos;s traffic as an XML file.
                      This process involves capturing the traffic using Burp
                      Suite and then exporting it to an XML format. Once you
                      have the XML file, you can then run Pynt against this file
                      to analyze the captured traffic for potential security
                      issues.
                    </Typography>

                    <Box>
                      <Typography>
                        To do this, follow these steps within Burp Suite:
                      </Typography>

                      <ol>
                        <li>
                          Go to the <strong>Proxy</strong> tab and then to the{' '}
                          <strong>HTTP history</strong> tab.
                        </li>
                        <li>
                          Select the traffic you are interested in analyzing.
                        </li>
                        <li>
                          Right-click the selected traffic and choose{' '}
                          <strong>Save items</strong>.
                        </li>
                        <li>
                          In the Save dialog, select <strong>XML</strong> as the
                          file format and choose a location to save your file.
                        </li>
                        <li>
                          Run Pynt by specifying the saved XML file as input:
                          <Box sx={COPY_BOX}>
                            <Stack
                              direction={'row'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                            >
                              <Typography sx={{ color: '#6da6ea' }}>
                                {'pynt burp --xml <your-xml-file>'}
                              </Typography>
                              <IconButton
                                onClick={() => {
                                  track('burp-onboarding-3-copy');
                                  navigator.clipboard.writeText(
                                    'pip install pyntcli',
                                  );
                                  show('Copied to clipboard');
                                }}
                              >
                                <ContentCopy sx={{ color: BRAND_ORANGE }} />
                              </IconButton>
                            </Stack>
                          </Box>
                        </li>
                      </ol>
                    </Box>
                    <img
                      width={'100%'}
                      src="https://docs.pynt.io/~gitbook/image?url=https:%2F%2F3462681674-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FZKwBF6q0tAGXlIih38HL%252Fuploads%252FMG4kAB9wk3t0x8evzYUq%252Fimage.png%3Falt=media%26token=fdbe989a-4d5d-48df-b797-061baf2c7951&width=768&dpr=2&quality=100&sign=cda2b053f1ac406d9950bfd4ca67cb381b8cd1730aa630aa6f1485caa637eaf9"
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  backgroundColor: 'transparent',
                  color: 'white',
                  px: 3,
                  py: 2,
                  paddingInlineEnd: 1,
                }}
                onChange={(e, expanded) => {
                  if (!expanded) return;
                  track('burp-onboarding-burp-2-pynt-listen-expand');
                }}
              >
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography fontWeight={600}>
                    Use{' '}
                    <strong style={{ color: BRAND_ORANGE }}>
                      Pynt Listen Mode
                    </strong>{' '}
                    as an upstream proxy of Burp
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2}>
                    <Typography>
                      Run pynt listen and set it to capture the domains of the
                      traffic that you want Pynt to scan:
                    </Typography>
                    <Box sx={COPY_BOX}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Typography sx={{ color: '#6da6ea' }}>
                          {'pynt listen --captured-domains <domains>'}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            track('burp-onboarding-3-copy');
                            navigator.clipboard.writeText(
                              'pip install pyntcli',
                            );
                            show('Copied to clipboard');
                          }}
                        >
                          <ContentCopy sx={{ color: BRAND_ORANGE }} />
                        </IconButton>
                      </Stack>
                    </Box>
                    <Typography fontWeight={600}>
                      Setting Upstream Proxy in Burp Suite
                    </Typography>
                    <Typography>
                      To configure Burp Suite to use an upstream proxy, follow
                      these steps:
                    </Typography>
                    <ol>
                      <li>Open Burp Suite and navigate to the Proxy tab.</li>

                      <li>Click on the Options sub-tab.</li>

                      <li>
                        Scroll down to the Upstream Proxy Servers section.
                      </li>
                      <li>Click on the Add button.</li>

                      <li>
                        In the dialog that appears, enter the details of the
                        upstream proxy:
                        <ul>
                          <li>
                            <strong>Destination host:</strong> Leave this as *
                            to apply to all destinations, or specify specific
                            hosts.
                          </li>
                          <li>
                            <strong>Proxy host:</strong> Enter the IP address of
                            Pynt listen 127.0.0.1
                          </li>
                          <li>
                            <strong>Proxy port:</strong> Enter the port number
                            of Pynt listen 6666
                          </li>
                        </ul>
                      </li>

                      <li>
                        Click OK to save your upstream proxy configuration.
                      </li>
                    </ol>
                    <Typography>
                      Now, Burp Suite will route all external traffic through
                      Pynt proxy. Hit enter to trigger Pynt scan.
                    </Typography>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>

            <a
              style={LINK}
              target="_blank"
              href="https://docs.pynt.io/documentation/pynt-examples/pynt-with-burp-suite"
              rel="noreferrer"
              onClick={() => {
                track('burp-onboarding-2-cant');
              }}
            >
              See our full integration docs
            </a>
          </Stack>

          <Box height={48} />
          <Stack direction={'row'} justifyContent={'end'} pb={5}>
            <LoadingButton
              variant="contained"
              loading={isLoadingNext}
              sx={SUBMIT_BUTTON}
              endIcon={<KeyboardArrowRightRounded fontSize="large" />}
              onClick={handleNextClick}
            >
              Next
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const OptionButtonBlurWrapper = ({ children }: { children: ReactElement }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={(theme) => ({
          borderWidth: 1.5,
          filter: 'blur(7px)',
          borderStyle: 'solid',
          borderColor: theme.palette.primary.main,
          borderRadius: '14px',
          height: '100%',
          width: '100%',
          position: 'absolute',
        })}
      ></Box>
      {children}
    </Box>
  );
};

export default BurpOnboardSetup;
