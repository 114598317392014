import { SvgIconProps } from './SvgIconProps';

export const DocumentIcon = ({
  color = '#ACACBB',
  size = 20,
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5562 3.32087L10.543 0.470187C10.2225 0.166969 9.80297 0 9.36178 0H3.625C2.67728 0 1.90625 0.771031 1.90625 1.71875V14.2812C1.90625 15.229 2.67728 16 3.625 16H12.375C13.3227 16 14.0938 15.229 14.0938 14.2812V4.56944C14.0938 4.09916 13.8978 3.64406 13.5562 3.32087ZM12.6457 3.75H10.3125C10.2263 3.75 10.1562 3.67991 10.1562 3.59375V1.39488L12.6457 3.75ZM12.375 15.0625H3.625C3.19422 15.0625 2.84375 14.712 2.84375 14.2812V1.71875C2.84375 1.28797 3.19422 0.9375 3.625 0.9375H9.21875V3.59375C9.21875 4.19684 9.70941 4.6875 10.3125 4.6875H13.1562V14.2812C13.1562 14.712 12.8058 15.0625 12.375 15.0625Z"
        fill={color}
      />
      <path
        d="M11.3438 6.25H4.46875C4.20987 6.25 4 6.45987 4 6.71875C4 6.97763 4.20987 7.1875 4.46875 7.1875H11.3438C11.6026 7.1875 11.8125 6.97763 11.8125 6.71875C11.8125 6.45987 11.6026 6.25 11.3438 6.25Z"
        fill={color}
      />
      <path
        d="M11.3438 8.75H4.46875C4.20987 8.75 4 8.95987 4 9.21875C4 9.47763 4.20987 9.6875 4.46875 9.6875H11.3438C11.6026 9.6875 11.8125 9.47763 11.8125 9.21875C11.8125 8.95987 11.6026 8.75 11.3438 8.75Z"
        fill={color}
      />
      <path
        d="M6.74125 11.25H4.46875C4.20988 11.25 4 11.4599 4 11.7188C4 11.9776 4.20988 12.1875 4.46875 12.1875H6.74125C7.00013 12.1875 7.21 11.9776 7.21 11.7188C7.21 11.4599 7.00013 11.25 6.74125 11.25Z"
        fill={color}
      />
    </svg>
  );
};
