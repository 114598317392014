import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { RunFirstScan } from '../assets/svgs/RunFirstScan';
import { track } from '../utils/analytics';
import RunScanDrawer from './RunScanDrawer/RunScanDrawer';

interface Props {}

const RerunScanInstructions = ({}: Props) => {
  const [isRunScanDrawerOpen, setIsRunScanDrawerOpen] = useState(false);

  return (
    <>
      <RunScanDrawer
        isOpen={isRunScanDrawerOpen}
        setIsOpen={() => setIsRunScanDrawerOpen(false)}
      />
      <Stack
        direction="row"
        gap={8}
        justifyContent="space-evenly"
        alignItems="center"
        minHeight={'90vh'}
      >
        <Stack alignItems="center" mt={4} spacing={3} maxWidth={450}>
          <Stack height={270} justifyContent={'center'}>
            <RunFirstScan />
          </Stack>
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h3">
              Congratulations on your upgrade!
            </Typography>
            <Typography sx={{ color: '#424242' }}>
              Gain a comprehensive understanding of your API security with a
              full testing report. Rerun the scan now to unlock the complete
              insights.
            </Typography>
          </Stack>
          <Button
            variant="contained"
            onClick={() => {
              track('web_app_onboard_sceen_run_first_scan_button_click');
              setIsRunScanDrawerOpen(true);
            }}
          >
            Run Scan
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default RerunScanInstructions;
