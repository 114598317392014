import { Container, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardAppBar from '../components/Common/DashboardAppBar';
import APIRiskScore from '../components/Overview/APIRiskScore';
import ApplicationsRiskScore from '../components/Overview/ApplicationsRiskScore';
import LastWeekAPIs from '../components/Overview/LastWeekAPIs';
import LastWeekScans from '../components/Overview/LastWeekScans';
import OverviewTotalCard from '../components/Overview/OverviewTotalCard';
import EditApplicationDrawer from '../components/Setup/EditApplicationDrawer';
import NoApplicationsView from '../components/Setup/NoApplicationsView';
import { AuthContext } from '../contexts/Auth';
import { OverviewContext, OverviewContextProps } from '../contexts/Overview';
import { UserContext, UserContextProps } from '../contexts/User';
import { track } from '../utils/analytics';

const Overview = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { totalApisCount, totalScansCount } = useContext(
    OverviewContext,
  ) as OverviewContextProps;
  const { applications, setSelectedApplicationId } = useContext(
    UserContext,
  ) as UserContextProps;
  const [isCreateApplicationDrawerOpen, setIsCreateApplicationDrawerOpen] =
    useState(false);
  const onNewApplicationButtonClick = useMemo(() => {
    return () => {
      track('web_app_add_new_application_button_click');

      setIsCreateApplicationDrawerOpen(true);
    };
  }, []);

  // throw the user to integrations screen upon first signup
  // (remove after an onboard process has been created)
  useEffect(() => {
    const firstTime = localStorage.getItem('firstTimeNewUser');
    if (firstTime === 'false' || !user) return;

    localStorage.setItem('firstTimeNewUser', 'false');

    if (Date.now() - new Date(user.created_at).getTime() < 60 * 60 * 1000) {
      navigate('/dashboard/integrations');
    }
  }, [user, navigate]);

  return (
    <>
      <EditApplicationDrawer
        mode={'create'}
        isOpen={isCreateApplicationDrawerOpen}
        setIsOpen={setIsCreateApplicationDrawerOpen}
      />
      <DashboardAppBar title={'Overview'} />
      <Container maxWidth={false}>
        {!applications || applications.length ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <OverviewTotalCard
                type="api"
                title="Total APIs"
                value={totalApisCount}
                onViewMoreClick={() => {
                  track(
                    'web_app_overview_total_apis_count_view_all_button_click',
                  );

                  setSelectedApplicationId('*');
                  navigate('/dashboard/api-catalog');
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OverviewTotalCard
                type="scans"
                title="Total Scans"
                value={totalScansCount === 50 ? '50+' : totalScansCount}
                onViewMoreClick={() => {
                  track(
                    'web_app_overview_total_scans_count_view_all_button_click',
                  );

                  setSelectedApplicationId('*');
                  navigate('/dashboard/scans-history');
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <APIRiskScore />
            </Grid>
            <Grid item xs={12} md={6}>
              <ApplicationsRiskScore />
            </Grid>
            {/* <Grid item xs={12} md={4}>
          <TopVulnerabilities />
        </Grid> */}
            <Grid item xs={12} md={12}>
              <LastWeekScans />
            </Grid>
            <Grid item xs={12} md={12}>
              <LastWeekAPIs />
            </Grid>
          </Grid>
        ) : (
          <Stack justifyContent="center" minHeight={'calc(100vh - 100px)'}>
            <NoApplicationsView onClick={() => onNewApplicationButtonClick()} />
          </Stack>
        )}
      </Container>
    </>
  );
};

export default Overview;
