interface Props {
  width?: number;
  height?: number;
}

export const LightLogo = ({ height = 64, width = 111 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 111 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2657 35.7518C24.2657 37.3932 23.779 38.9978 22.8671 40.3626C21.9552 41.7273 20.659 42.791 19.1426 43.4192C17.6261 44.0473 15.9575 44.2117 14.3476 43.8914C12.7377 43.5712 11.259 42.7808 10.0984 41.6202C8.93771 40.4595 8.1473 38.9808 7.82708 37.3709C7.50686 35.761 7.67121 34.0924 8.29935 32.5759C8.92748 31.0595 9.99119 29.7633 11.356 28.8514C12.7207 27.9395 14.3253 27.4528 15.9667 27.4528C17.0573 27.4499 18.1378 27.6626 19.146 28.0787C20.1542 28.4947 21.0702 29.1059 21.8414 29.8771C22.6126 30.6484 23.2238 31.5644 23.6398 32.5725C24.0559 33.5807 24.2686 34.6612 24.2657 35.7518ZM31.9334 35.7518C31.9334 31.5172 30.2512 27.456 27.2568 24.4617C24.2625 21.4673 20.2013 19.7852 15.9667 19.7852C11.7321 19.7852 7.67087 21.4673 4.67653 24.4617C1.6822 27.456 0 31.5172 0 35.7518V63.4004H7.66761V49.3731C10.0858 50.8534 12.855 51.662 15.6898 51.7156C18.5245 51.7692 21.3223 51.0658 23.7947 49.678C26.2671 48.2901 28.3246 46.268 29.7552 43.8201C31.1858 41.3722 31.9377 38.5871 31.9334 35.7518Z"
        fill="white"
      />
      <path
        d="M61.8371 20.417H54.1695L50.4259 34.4442L46.7725 48.0655C46.7725 48.1106 46.7274 48.1557 46.7274 48.2008L37.7067 20.417H30.0391L40.007 51.0875H45.4194C43.3897 54.1996 39.7814 56.4097 36.2634 56.4097C36.0829 56.4097 35.9476 56.3646 35.8123 56.3646V63.9871C44.1114 63.2203 52.2751 56.3646 54.4852 48.0655L61.8371 20.417Z"
        fill="white"
      />
      <path
        d="M92.4607 51.0871V35.7518C92.4607 31.5172 90.7785 27.456 87.7842 24.4617C84.7898 21.4673 80.7286 19.7852 76.494 19.7852C72.2594 19.7852 68.1982 21.4673 65.2039 24.4617C62.2095 27.456 60.5273 31.5172 60.5273 35.7518V51.0871H68.195V35.7518C68.195 33.5508 69.0693 31.4399 70.6257 29.8835C72.1821 28.3271 74.293 27.4528 76.494 27.4528C78.6951 27.4528 80.806 28.3271 82.3624 29.8835C83.9187 31.4399 84.7931 33.5508 84.7931 35.7518V51.0871H92.4607Z"
        fill="white"
      />
      <path
        d="M102.16 35.7522V28.0846H111V20.417H102.16V8.05859H94.4922V35.7522C94.4922 39.9868 96.1744 44.048 99.1687 47.0424C102.163 50.0367 106.224 51.7189 110.459 51.7189C110.626 51.7284 110.793 51.7132 110.955 51.6738V43.9611C110.775 43.9611 110.639 44.0062 110.459 44.0062C109.372 44.0103 108.294 43.8 107.288 43.3872C106.283 42.9744 105.368 42.3673 104.597 41.6006C103.826 40.834 103.214 39.9228 102.796 38.9192C102.378 37.9156 102.162 36.8394 102.16 35.7522Z"
        fill="white"
      />
      <path
        d="M1.75781 6.7051L80.5502 0C84.4354 0 87.5864 3.0021 87.5864 6.7051C87.5864 10.4081 84.4368 13.4102 80.5502 13.4102L1.75781 8.04648V6.7051Z"
        fill="#FF8C00"
      />
    </svg>
  );
};
