import { Button, Container, Tab, Tabs } from '@mui/material';

import { useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AddIcon } from '../assets/svgs/icons/AddIcon';
import DashboardAppBar from '../components/Common/DashboardAppBar';
import InviteUserDrawer from '../components/Users/InviteUserDrawer';
import { last } from '../utils/array';

const SettingsWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isInviteUserDrawerOpen, setIsInviteUserDrawerOpen] = useState(false);

  const tabs = useMemo(() => {
    return [
      { id: 'general', title: 'General' },
      { id: 'users-management', title: 'User management' },
      { id: 'integrations', title: 'Integrations' },
    ];
  }, []);

  const currentTab = useMemo(
    () =>
      tabs.find((t) => t.id === last(location.pathname.split('/'))) || tabs[0],
    [location, tabs],
  );

  return (
    <>
      <InviteUserDrawer
        isOpen={isInviteUserDrawerOpen}
        onClose={() => setIsInviteUserDrawerOpen(false)}
      />
      <DashboardAppBar
        title="Settings"
        endActions={
          <>
            {window.location.pathname.includes('user') && (
              <Button
                variant="contained"
                sx={{ bgcolor: 'black', color: 'white' }}
                size="large"
                startIcon={<AddIcon color="white" />}
                onClick={() => setIsInviteUserDrawerOpen(true)}
              >
                Invite User
              </Button>
            )}
          </>
        }
      />
      <Container maxWidth={false}>
        <Tabs
          value={currentTab.id}
          onChange={(_, t) => {
            if (currentTab.id === t) return;
            navigate(`/dashboard/settings/${t}`);
          }}
        >
          {tabs.map((t, i) => (
            <Tab
              sx={{ pl: 0, pr: 0, ml: i !== 0 ? 2 : 0 }}
              key={t.id}
              value={t.id}
              label={t.title}
            />
          ))}
        </Tabs>
        <Outlet />
      </Container>
    </>
  );
};

export default SettingsWrapper;
