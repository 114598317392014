import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext, UserContextProps } from '../../contexts/User';
import { APICatalogItem } from '../../models/apiCatalog';
import ScanHistoryGrid from '../ScansHistory/ScanHistoryGrid';

interface Props {
  endpoint: APICatalogItem;
}

export default function LastScanInfoGrid({ endpoint }: Props) {
  const navigate = useNavigate();
  const { setSelectedApplicationId } = useContext(
    UserContext,
  ) as UserContextProps;

  return (
    <ScanHistoryGrid
      id="LastScanInfoGrid"
      hideFooter
      applicationFilter={false}
      filter={{
        items: [
          {
            field: 'scan_id',
            value: endpoint.properties?.last_scan,
            operator: 'equals',
          },
        ],
      }}
      onRowClick={(row) => {
        setSelectedApplicationId('*');

        navigate({
          pathname: '/dashboard/scans-history',
          search: `?filter=${JSON.stringify({
            items: [
              { field: 'scan_id', value: row.scan_id, operator: 'equals' },
            ],
          })}`,
        });
      }}
    />
  );
}
