import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateAPIInventory } from '../assets/svgs/CreateAPIInventory';
import { RunFirstScan } from '../assets/svgs/RunFirstScan';
import { track } from '../utils/analytics';
import RunScanDrawer from './RunScanDrawer/RunScanDrawer';

interface Props {}

const Instructions = ({}: Props) => {
  const navigate = useNavigate();
  const [isRunScanDrawerOpen, setIsRunScanDrawerOpen] = useState(false);

  return (
    <>
      <RunScanDrawer
        isOpen={isRunScanDrawerOpen}
        setIsOpen={() => setIsRunScanDrawerOpen(false)}
      />
      <Stack
        direction="row"
        gap={8}
        justifyContent="space-evenly"
        alignItems="center"
        minHeight={'90vh'}
      >
        <Stack alignItems="center" mt={4} spacing={3} maxWidth={450}>
          <Stack height={270} justifyContent={'center'}>
            <RunFirstScan />
          </Stack>
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h3">Run your 1st scan</Typography>
            <Typography sx={{ color: '#424242' }}>
              Get started with Pynt by running your first security scan. Detect
              vulnerabilities, assess risks, and strengthen your APIs with just
              a few clicks.
            </Typography>
          </Stack>
          <Button
            variant="contained"
            onClick={() => {
              track('web_app_onboard_sceen_run_first_scan_button_click');
              setIsRunScanDrawerOpen(true);
            }}
          >
            Run Scan
          </Button>
        </Stack>
        <Stack alignItems="center" mt={4} spacing={3} maxWidth={450}>
          <Stack height={270} justifyContent={'center'}>
            <CreateAPIInventory />
          </Stack>
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h3">Create API inventory</Typography>
            <Typography sx={{ color: '#424242' }}>
              Start building your API inventory with Pynt. Identify all APIs in
              use, assess their associated risks, and gain comprehensive
              visibility into your API landscape.
            </Typography>
          </Stack>
          <Button
            variant="contained"
            onClick={() => {
              track('web_app_onboard_sceen_create_inventory_button_click');
              navigate('../applications?tour=true');
            }}
          >
            Create Inventory
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default Instructions;
