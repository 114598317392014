import { ContentCopy, KeyboardArrowRightRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import {
  CSSProperties,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { SecuringUnderline } from '../../assets/svgs/SecuringUnderline';
import { BRAND_ORANGE } from '../../constants/colors';
import { SnackbarContext } from '../../contexts/Snackbar';
import { track } from '../../utils/analytics';

const TITLE: SxProps = {
  color: 'white',
  fontSize: 36,
  fontWeight: 700,
  letterSpacing: '-1.2px',
};

const COPY_BOX: SxProps = {
  border: '1px solid #40475c',
  p: 2,
  borderRadius: 1,
};

const TEXT: SxProps = {
  color: 'white',
  textAlign: 'start',
};

const OPTION_BUTTON: SxProps = {
  minHeight: 60,
  color: 'white',
  fontFamily: 'Raleway',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '40px',
  whiteSpace: 'nowrap',
};

// const LINK: CSSProperties = {
//   color: '#FF8237',
//   fontFamily: 'Raleway',
//   fontSize: '14px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: '20px',
//   letterSpacing: '-0.187px',
//   textDecorationLine: 'underline',
//   flex: 1,
//   textAlign: 'end',
// };

const SUBMIT_BUTTON: CSSProperties = {
  width: '204px',
  height: '60px',
  fontFamily: 'Raleway',
  fontSize: '18px',
};

const SeleniumOnboardSetup = () => {
  const navigate = useNavigate();
  const { show } = useContext(SnackbarContext);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const handleNextClick = useCallback(() => {
    track('onboarding-2-next');

    setIsLoadingNext(true);

    navigate('../run');
  }, []);

  return (
    <Stack alignItems={'center'} p={2} height={'100%'}>
      <Stack
        direction={'column'}
        spacing={1}
        pt={5}
        maxWidth={'700px'}
        height={'100%'}
      >
        <Box sx={{ display: 'inline', textAlign: 'start' }}>
          <Box sx={{ display: 'inline-block', position: 'relative' }}>
            <Typography
              sx={[TITLE, { whiteSpace: 'nowrap' }]}
              zIndex={1000}
              position={'relative'}
            >
              Use Pynt
            </Typography>
            <Box position="absolute" bottom={-4}>
              <SecuringUnderline />
            </Box>
          </Box>
          <Typography sx={[TITLE]} display={'inline'}>
            {' '}
            to start testing your APIs for security
          </Typography>
        </Box>
        <Box height={24} />
        <Stack direction={'row'} flexWrap={'wrap'} rowGap={2} gap={2}>
          <OptionButtonBlurWrapper>
            <Button
              variant="outlined"
              sx={OPTION_BUTTON}
              onClick={() => {
                track('onboarding-2-prerequisites');
                window.open(
                  'https://docs.pynt.io/documentation/onboarding/prerequisites',
                  '_blank',
                  'noreferrer',
                );
              }}
            >
              Prerequisites
            </Button>
          </OptionButtonBlurWrapper>
          <OptionButtonBlurWrapper>
            <Button
              variant="outlined"
              // endIcon={
              //   <img
              //     src={youtubeIcon}
              //     width={34}
              //     style={{ marginInlineStart: 8 }}
              //   />
              // }
              sx={OPTION_BUTTON}
              onClick={() => {
                track('onboarding-2-tutorial');
                window.open(
                  'https://d1efigcr4c19qn.cloudfront.net/image/pynt_selenium.gif',
                  '_blank',
                  'noreferrer',
                );
              }}
            >
              Tutorial
            </Button>
          </OptionButtonBlurWrapper>
        </Stack>
        <Box height={24} />
        <Stack direction={'row'}>
          <Stack direction={'column'} spacing={2} alignItems={'stretch'}>
            <Typography sx={TEXT}>
              Using Selenium for UI testing in combination with Pynt for
              automated API security testing is a powerful approach to enhance
              the security of your web applications. Here’s a step-by-step guide
              on how you can integrate Selenium with Pynt to create automated
              API security tests:
            </Typography>

            <Typography sx={TEXT}>
              To configure Selenium chrome web driver to go through a Proxy, add
              the following lines to your webdriver setup:
            </Typography>

            <Box sx={COPY_BOX}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography sx={{ color: '#6da6ea', textAlign: 'start' }}>
                  {`chrome_options.add_argument('--proxy-server=http://127.0.0.1:6666')
chrome_options.add_argument('--proxy-bypass-list=<-loopback>')
chrome_options.add_argument("--ignore-certificate-errors")`}
                </Typography>
                <IconButton
                  onClick={() => {
                    track('burp-onboarding-3-copy');
                    navigator.clipboard.writeText(
                      `chrome_options.add_argument('--proxy-server=http://127.0.0.1:6666')
chrome_options.add_argument('--proxy-bypass-list=<-loopback>')
chrome_options.add_argument("--ignore-certificate-errors")`,
                    );
                    show('Copied to clipboard');
                  }}
                >
                  <ContentCopy sx={{ color: BRAND_ORANGE }} />
                </IconButton>
              </Stack>
            </Box>

            <Typography sx={TEXT}>
              Need more information? Check out our{' '}
              <a
                href="https://docs.pynt.io/documentation/pynt-examples/pynt-with-testing-frameworks/selenium"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#FF8237' }}
              >
                documentation
              </a>
              .
            </Typography>
          </Stack>
        </Stack>
        <Box height={48} />
        <Stack direction={'row'} justifyContent={'end'}>
          <LoadingButton
            variant="contained"
            loading={isLoadingNext}
            sx={SUBMIT_BUTTON}
            endIcon={<KeyboardArrowRightRounded fontSize="large" />}
            onClick={handleNextClick}
          >
            Next
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

const OptionButtonBlurWrapper = ({ children }: { children: ReactElement }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={(theme) => ({
          borderWidth: 1.5,
          filter: 'blur(7px)',
          borderStyle: 'solid',
          borderColor: theme.palette.primary.main,
          borderRadius: '14px',
          height: '100%',
          width: '100%',
          position: 'absolute',
        })}
      ></Box>
      {children}
    </Box>
  );
};

export default SeleniumOnboardSetup;
