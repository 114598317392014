export const CreateAPIInventory = () => {
  return (
    <svg
      width="285"
      height="261"
      viewBox="0 0 285 261"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.118 157.243C109.809 143.227 101.296 119.078 104.098 97.1995C106.974 74.7507 122.463 56.1435 141.552 44.5698C159.533 33.6673 181.876 31.0993 202.192 37.724C220.449 43.6775 230.203 61.6512 241.73 77.2367C253.808 93.5673 272.08 108.889 269.554 128.806C266.994 148.994 248.752 164.236 229.835 171.039C214.043 176.718 198.807 162.543 182.01 160.287C163.092 157.746 142.132 169.4 127.118 157.243Z"
        fill="#E6E7EA"
      />
      <path
        d="M108.421 117.001V211.115C108.421 213.252 106.688 214.985 104.551 214.985H91.7821"
        stroke="#BCC1D1"
        strokeWidth="3.69752"
      />
      <ellipse
        cx="7.85716"
        cy="7.85723"
        rx="7.85716"
        ry="7.85723"
        transform="matrix(-1 0 0 1 91.0479 206.989)"
        stroke="#BCC1D1"
        strokeWidth="3.69752"
      />
      <path
        d="M165.806 133.974V221.058C165.806 223.195 167.538 224.928 169.676 224.928H185.932"
        stroke="#BCC1D1"
        strokeWidth="3.69752"
      />
      <ellipse
        cx="194.911"
        cy="225.003"
        rx="7.85716"
        ry="7.85723"
        stroke="#BCC1D1"
        strokeWidth="3.69752"
      />
      <path
        d="M135.229 129.019L135.229 242.717"
        stroke="#FF8C00"
        strokeWidth="3.69752"
      />
      <ellipse
        cx="7.85716"
        cy="7.85723"
        rx="7.85716"
        ry="7.85723"
        transform="matrix(-1 0 0 1 143.086 243.373)"
        stroke="#FF8C00"
        strokeWidth="3.69752"
      />
      <path
        d="M150.712 101.356V188.053C150.712 190.191 152.445 191.924 154.582 191.924H200.253"
        stroke="#BCC1D1"
        strokeWidth="3.69752"
      />
      <ellipse
        cx="207.92"
        cy="191.876"
        rx="7.85716"
        ry="7.85723"
        stroke="#BCC1D1"
        strokeWidth="3.69752"
      />
      <path
        d="M122.287 87.4199V174.139C122.287 176.276 120.554 178.009 118.417 178.009H64.0512"
        stroke="#FF8C00"
        strokeWidth="3.69752"
      />
      <ellipse
        cx="7.85716"
        cy="7.85723"
        rx="7.85716"
        ry="7.85723"
        transform="matrix(-1 0 0 1 63.917 170.131)"
        stroke="#FF8C00"
        strokeWidth="3.69752"
      />
      <g filter="url(#filter0_d_837_24700)">
        <path
          d="M207.981 83.7234C204.386 82.4286 201.486 79.5242 200.182 75.9323C195.513 63.0622 183.166 53.8439 168.701 53.8439C165.475 53.8439 162.313 54.3024 159.291 55.1877C154.575 56.5695 149.141 55.2815 145.84 51.6412C137.571 42.5242 125.759 37.1074 112.914 37.1074C88.3048 37.1074 68.2833 57.1291 68.2833 81.738C68.2833 82.0029 68.0926 82.2336 67.8319 82.2813C52.2338 85.1315 40.0176 98.8139 40.0176 115.211C40.0176 133.669 55.4042 148.684 73.8621 148.684H196.595C215.053 148.684 230.44 133.669 230.44 115.211C230.44 100.74 220.934 88.3888 207.981 83.7234Z"
          fill="white"
        />
      </g>
      <path
        d="M80.6904 71.7067C82.5392 58.3037 94.0939 45.8248 115.817 46.7485"
        stroke="#F5F7F9"
        strokeWidth="5.54628"
        strokeLinecap="round"
      />
      <path
        d="M91.7832 76.3285C92.7076 67.5473 100.891 57.7899 115.817 58.8271"
        stroke="#F5F7F9"
        strokeWidth="4.6219"
        strokeLinecap="round"
      />
      <path
        d="M71.4473 141.498H112.12"
        stroke="#F5F7F9"
        strokeWidth="4.6219"
        strokeLinecap="round"
      />
      <path
        d="M78.8418 132.717L100.103 132.717"
        stroke="#F5F7F9"
        strokeWidth="4.6219"
        strokeLinecap="round"
      />
      <path
        d="M146.355 98.7808H137.266V89.6921C137.266 88.4372 136.249 87.4199 134.994 87.4199C133.739 87.4199 132.722 88.4372 132.722 89.6921V98.7808H123.634C122.379 98.7808 121.361 99.7981 121.361 101.053C121.361 102.308 122.379 103.325 123.634 103.325H132.722V112.414C132.722 113.669 133.739 114.686 134.994 114.686C136.249 114.686 137.266 113.669 137.266 112.414V103.325H146.355C147.61 103.325 148.627 102.308 148.627 101.053C148.627 99.7979 147.61 98.7808 146.355 98.7808Z"
        fill="#FF8C00"
      />
      <defs>
        <filter
          id="filter0_d_837_24700"
          x="0.468697"
          y="0.488087"
          width="269.52"
          height="190.674"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.92955" />
          <feGaussianBlur stdDeviation="19.7744" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.351111 0 0 0 0 0.381833 0 0 0 0 0.658333 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_837_24700"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_837_24700"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
