import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface GridWrapperProps {
  children: ReactElement;
  paddingOffset?: number;
}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'paddingOffset',
})<GridWrapperProps>(({ paddingOffset = 128 }) => ({
  height: `calc(100vh - ${paddingOffset}px)`,
  width: '100%',
  borderRadius: 8,
  padding: '0 16px',
  backgroundColor: 'white',
  border: 'solid 1px #D3D3D3',
  boxShadow: '0px 0px 4px 0px #0000001A',
}));

const GridWrapper = ({ children, paddingOffset }: GridWrapperProps) => (
  <StyledBox paddingOffset={paddingOffset}>{children}</StyledBox>
);

export default GridWrapper;
