import { Email } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, MenuItem, Select, Stack, SxProps } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CopyIcon2 } from '../../assets/svgs/icons/CopyIcon2';
import {
  OrgUserInvite,
  OrganizationContext,
  OrganizationContextProps,
} from '../../contexts/Organization';
import { SnackbarContext } from '../../contexts/Snackbar';
import { createInvite } from '../../services/OrgUsersService';
import { track } from '../../utils/analytics';
import CustomDrawer from '../Common/CustomDrawer';
import IconTextField from '../Common/IconTextField';

const SELECT_ICON_WRAPPER: SxProps = {
  bgcolor: '#EFF5FF',
  width: 24,
  height: 24,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface InviteUserData {
  email?: string;
  role?: 'Admin' | 'User';
}

interface Props {
  invite?: OrgUserInvite;
  isOpen: boolean;
  onClose: () => void;
}

const InviteUserDrawer = ({ invite: propInvite, isOpen, onClose }: Props) => {
  const { show } = useContext(SnackbarContext);
  const { fetchInvites, fetchUsers } = useContext(
    OrganizationContext,
  ) as OrganizationContextProps;

  const [data, setData] = useState<InviteUserData>({});

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [invite, setInvite] = useState<any>();

  useEffect(() => {
    if (propInvite) {
      setInvite(propInvite);
    }
  }, [propInvite]);

  // Clean data upon drawer close
  useEffect(() => {
    if (isOpen) return;

    setData({});
    setInvite(undefined);
    setIsLoadingSave(false);
  }, [isOpen]);

  const onSaveClick = () => {
    track('web_app_create_invite_button_click', {});

    const email = data.email;
    if (!email) {
      show('Please provide an email address');
      return;
    }

    setIsLoadingSave(true);

    createInvite(email, data.role)
      .then(async (res) => {
        setInvite(res);
        await fetchInvites().catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
        await fetchUsers().catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
      })
      .catch((e) => {
        console.error(e);
        show(e?.response?.data?.error?.message ?? e.toString(), 'error');
        Sentry.captureException(e);
      })
      .finally(() => {
        setIsLoadingSave(false);
      });
  };

  const onCopyInviteClick = useCallback(() => {
    if (!invite) return;

    track('web_app_copy_invite_link_button_click');

    navigator.clipboard.writeText(
      'https://app.pynt.io/login?invite=' + invite.invite_id,
    );
    show('Invite link copied to clipboard');
  }, [invite]);

  return (
    <CustomDrawer
      header={{
        title: 'Invite User',
      }}
      open={isOpen}
      setOpen={() => onClose?.()}
    >
      <Stack
        direction={'column'}
        alignItems={'stretch'}
        flex={1}
        height={'100%'}
        gap={2}
      >
        <IconTextField
          fullWidth
          variant="filled"
          size="small"
          placeholder="User email"
          iconStart={
            <Box sx={SELECT_ICON_WRAPPER}>
              <Email sx={{ fontSize: 20, color: '#BFCEE5' }} />
            </Box>
          }
          value={invite?.email || data?.['email']}
          onChange={(v) =>
            setData((data) => ({ ...data, email: v.target.value?.trim() }))
          }
          required
          disabled={isLoadingSave || invite}
        />

        <Select<'Admin' | 'User'>
          fullWidth
          size="small"
          value={data.role || 'User'}
          onChange={(v) =>
            setData((data) => ({ ...data, role: v.target.value as any }))
          }
          disabled={isLoadingSave || invite}
        >
          <MenuItem value={'User'}>User</MenuItem>
          <MenuItem value={'Admin'}>Admin</MenuItem>
        </Select>
        {invite ? (
          <IconTextField
            fullWidth
            variant="filled"
            size="small"
            iconEnd={
              <Box sx={SELECT_ICON_WRAPPER}>
                <CopyIcon2 size={20} color="#BFCEE5" />
              </Box>
            }
            value={'https://app.pynt.io/login?invite=' + invite?.['invite_id']}
            required
            disabled={isLoadingSave}
            onClick={onCopyInviteClick}
            helperText="Please copy the link and share it with the person you would like
              to invite."
          />
        ) : (
          <Stack
            direction={'row-reverse'}
            justifyContent={'flex-end'}
            spacing={1}
          >
            <Button variant="outlined" onClick={() => onClose?.()}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              onClick={onSaveClick}
              loading={isLoadingSave}
              disabled={!data.email}
            >
              Generate a Link
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </CustomDrawer>
  );
};

export default InviteUserDrawer;
