import { SvgIconProps } from './SvgIconProps';

export const SafetyCheckIcon = ({
  color = 'black',
  size = 24,
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9987 1.66663L3.33203 4.16663V9.24163C3.33203 13.45 6.1737 17.375 9.9987 18.3333C13.8237 17.375 16.6654 13.45 16.6654 9.24163V4.16663L9.9987 1.66663ZM14.9987 9.24163C14.9987 12.575 12.8737 15.6583 9.9987 16.6C7.1237 15.6583 4.9987 12.5833 4.9987 9.24163V5.32496L9.9987 3.44996L14.9987 5.32496V9.24163ZM9.9987 5.83329C7.6987 5.83329 5.83203 7.69996 5.83203 9.99996C5.83203 12.3 7.6987 14.1666 9.9987 14.1666C12.2987 14.1666 14.1654 12.3 14.1654 9.99996C14.1654 7.69996 12.2987 5.83329 9.9987 5.83329ZM11.3737 11.9583L9.58203 10.1666V7.49996H10.4154V9.82496L11.957 11.3666L11.3737 11.9583Z"
        fill={color}
      />
    </svg>
  );
};
