import { Components, Theme } from '@mui/material';

export default function themedMuiTab(
  theme: Theme,
): Pick<Components<Theme>, 'MuiTab'> {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 'fit-content',
        },
      },
    },
  };
}
