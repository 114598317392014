import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AuthContextProvider from './contexts/Auth';
import OnboardContextProvider from './contexts/Onboard';
import SnackbarContextProvider from './contexts/Snackbar';
import UserContextProvider from './contexts/User';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme';

import { LicenseInfo } from '@mui/x-license-pro';
import OrganizationContextProvider from './contexts/Organization';
import OverviewContextProvider from './contexts/Overview';

LicenseInfo.setLicenseKey(
  '8beb7d89012cc08d059f9d21163f9b2cTz05NDQ1MixFPTE3NTI4NDcxMDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

import Hotjar from '@hotjar/browser';
// import { IntercomProvider } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import OnboardWrapper from './components/Onboard/OnboardWrapper';
import ApplicationContextProvider from './contexts/Application';
import IntegrationsContextProvider from './contexts/Integrations';
import PentestReportDownloaderContextProvider from './contexts/PentestReportDownloaderContext';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_NODE_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /\.pynt\.io$/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Initiate Hotjar only in prod
if (process.env.REACT_APP_NODE_ENV !== 'development') {
  const siteId = 3603373;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion, {
    debug: process.env.NODE_ENV === 'development',
  });
}

// Initiate Mixpanel only in prod
if (process.env.REACT_APP_NODE_ENV !== 'development') {
  mixpanel.init(process.env.REACT_APP_MIXPANEL ?? '', {
    debug: process.env.NODE_ENV === 'development',
  });
}

// const INTERCOM_APP_ID = 'b4kz852g';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <SnackbarContextProvider>
        <AuthContextProvider>
          <UserContextProvider>
            <OrganizationContextProvider>
              <IntegrationsContextProvider>
                <OnboardContextProvider>
                  <PentestReportDownloaderContextProvider>
                    <OverviewContextProvider>
                      <ApplicationContextProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <OnboardWrapper>
                            <BrowserRouter>
                              <App />
                            </BrowserRouter>
                          </OnboardWrapper>
                        </LocalizationProvider>
                      </ApplicationContextProvider>
                    </OverviewContextProvider>
                  </PentestReportDownloaderContextProvider>
                </OnboardContextProvider>
              </IntegrationsContextProvider>
            </OrganizationContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </SnackbarContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
