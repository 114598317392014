import { Stack, SxProps, Typography, capitalize } from '@mui/material';
import { useMemo } from 'react';
import { APICatalogItemProperties } from '../../models/apiCatalog';

export const normalizeRiskScore = (score?: string | null | number) => {
  if (!score) return undefined;
  if (typeof score === 'string') return score;

  switch (score) {
    case 1:
      return 'LOW';
    case 2:
      return 'MEDIUM';
    case 3:
      return 'HIGH';
    case 4:
      return 'CRITICAL';
    default:
      return undefined;
  }
};

export const getEnpointRisk = (properties?: APICatalogItemProperties) => {
  if (!properties) return undefined;

  const score =
    normalizeRiskScore(properties.scan_risk) ||
    normalizeRiskScore(properties.swagger_risk);
  if (!score) return undefined;
  return score.trim()?.toUpperCase();
};

const CHIP: (bgColor: string, textColor: string) => SxProps = (
  bgColor,
  textColor,
) => ({
  bgcolor: bgColor ?? '#969BA933',
  color: textColor,
  borderRadius: 0.5,
  py: 0.5,
  px: 1,
  minWidth: '24px',
});

const TEXT: SxProps = {
  fontSize: 11,
  fontWeight: 700,
  letterSpacing: '0.2 px',
};

interface Props {
  score: string;
  grayed?: boolean;
}

export default function RiskScoreChip({ score, grayed }: Props) {
  const textColor = useMemo(() => {
    switch (score?.toUpperCase()) {
      default:
        return '#fff';
    }
  }, [score]);

  const bgColor = useMemo(() => {
    switch (score?.toUpperCase()) {
      case 'LOW':
        return '#FFD300';

      case 'MEDIUM':
        return '#FF8C00';

      case 'HIGH':
        return '#F53255';

      case 'CRITICAL':
        return '#A90220';

      default:
        return '#D3D3D3';
    }
  }, [score]);

  if (!score) return <></>;

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={0.5}
      sx={CHIP(bgColor, textColor)}
    >
      <Typography variant="body1" sx={TEXT}>
        {score === 'N/A' ? score : capitalize(score.toLowerCase())}
      </Typography>
    </Stack>
  );
}
