import { Components, Theme } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';
import themedMuiButton from './MuiButton';
import themedMuiDataGrid from './MuiDataGrid';
import themedMuiFilledInput from './MuiFilledInput';
import themedMuiFormLabel from './MuiFormLabel';
import themedMuiPaper from './MuiPaper';
import themedMuiSelect from './MuiSelect';
import themedMuiTab from './MuiTab';
import themedMuiTooltip from './MuiTooltip';

export default function ComponentsOverrides(theme: Theme): Components {
  return Object.assign(
    themedMuiButton(theme),
    themedMuiDataGrid(theme),
    themedMuiFilledInput(theme),
    themedMuiSelect(theme),
    themedMuiFormLabel(theme),
    themedMuiPaper(theme),
    themedMuiTooltip(theme),
    themedMuiTab(theme),
    themedMuiFilledInput(theme),
  ) as Components;
}
