import { SvgIconProps } from './SvgIconProps';

export const LockIcon2 = ({ color = '#ACACBB', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.60327 4.46153C5.60327 2.54978 7.15744 1 9.07458 1H10.926C12.8431 1 14.3973 2.54978 14.3973 4.46153V6.75384C14.8503 6.78975 15.2723 6.82615 15.6417 6.85952C16.836 6.96744 17.7839 7.90379 17.8924 9.09955C17.9892 10.1665 18.1001 11.6325 18.1001 12.7692C18.1001 13.9059 17.9892 15.372 17.8924 16.439C17.7839 17.6347 16.836 18.5711 15.6417 18.679C14.1413 18.8145 11.7699 19 10.0003 19C8.23067 19 5.85922 18.8145 4.35889 18.679C3.16465 18.5711 2.21669 17.6347 2.10821 16.439C2.01141 15.372 1.90051 13.9059 1.90051 12.7692C1.90051 11.6325 2.01141 10.1665 2.10821 9.09955C2.21669 7.90379 3.16465 6.96744 4.35889 6.85952C4.72823 6.82615 5.15034 6.78975 5.60327 6.75384V4.46153ZM6.3433 8.08615C6.34699 8.08592 6.35069 8.08565 6.35438 8.08535C7.57138 7.99678 8.91186 7.92308 10.0003 7.92308C11.0887 7.92308 12.4292 7.99678 13.6462 8.08535C13.6499 8.08565 13.6536 8.08592 13.6573 8.08615C14.338 8.13577 14.9796 8.18998 15.5163 8.23852C16.0476 8.28649 16.4618 8.6995 16.5095 9.22429C16.6058 10.2861 16.7115 11.6994 16.7115 12.7692C16.7115 13.8391 16.6058 15.2523 16.5095 16.3141C16.4618 16.839 16.0476 17.2519 15.5163 17.3C14.0175 17.4354 11.7012 17.6153 10.0003 17.6153C8.29943 17.6153 5.98306 17.4354 4.48421 17.3C3.95302 17.2519 3.53871 16.839 3.49109 16.3141C3.39476 15.2523 3.28905 13.8391 3.28905 12.7692C3.28905 11.6994 3.39476 10.2861 3.49109 9.22429C3.53871 8.69941 3.95302 8.28649 4.48421 8.23852C5.021 8.18998 5.66263 8.13577 6.3433 8.08615ZM13.0088 6.65369C11.9803 6.58756 10.9107 6.53846 10.0003 6.53846C9.08988 6.53846 8.02022 6.58756 6.9918 6.65369V4.46153C6.9918 3.31448 7.9243 2.38461 9.07458 2.38461H10.926C12.0763 2.38461 13.0088 3.31448 13.0088 4.46153V6.65369ZM10.0003 11.1539C10.3837 11.1539 10.6945 11.4638 10.6945 11.8462V13.6923C10.6945 14.0747 10.3837 14.3846 10.0003 14.3846C9.6169 14.3846 9.30605 14.0747 9.30605 13.6923V11.8462C9.30605 11.4638 9.6169 11.1539 10.0003 11.1539Z"
        fill={color}
      />
    </svg>
  );
};
