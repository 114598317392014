import { SvgIconProps } from './SvgIconProps';

export const ProfileIcon2 = ({
  color = '#ACACBB',
  size = 20,
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_8513)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.81779 3.63636C5.81779 2.43138 6.7946 1.45455 7.99963 1.45455C9.20466 1.45455 10.1815 2.43138 10.1815 3.63636C10.1815 4.84135 9.20466 5.81818 7.99963 5.81818C6.7946 5.81818 5.81779 4.84135 5.81779 3.63636ZM7.99963 0C5.9913 0 4.36322 1.62806 4.36322 3.63636C4.36322 5.64467 5.9913 7.27273 7.99963 7.27273C10.0079 7.27273 11.636 5.64467 11.636 3.63636C11.636 1.62806 10.0079 0 7.99963 0ZM6.54507 8.72727C3.73341 8.72727 1.4541 11.0065 1.4541 13.8182C1.4541 15.0232 2.43095 16 3.63594 16H12.3633C13.5683 16 14.5452 15.0232 14.5452 13.8182C14.5452 11.0065 12.2659 8.72727 9.45419 8.72727H6.54507ZM2.90866 13.8182C2.90866 11.8099 4.53674 10.1818 6.54507 10.1818H9.45419C11.4625 10.1818 13.0906 11.8099 13.0906 13.8182C13.0906 14.2199 12.765 14.5455 12.3633 14.5455H3.63594C3.23428 14.5455 2.90866 14.2199 2.90866 13.8182Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_8513">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
