import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  IntegrationsContext,
  IntegrationsContextProps,
} from '../../contexts/Integrations';
import { Vulnerability } from '../../models/vulnerability';
import { getTicketMetadata } from '../../services/VulnerabilitiesService';
import TextFieldTitle from '../Common/TextFieldTitle';

const LOADER_WRAPPER: SxProps = {
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const createVulnerabilityTicketTitle = (vulnerability: Vulnerability) => {
  return vulnerability.name;
};

const createVulnerabilityTicketDescription = (vulnerability: Vulnerability) => {
  // Scan ID: ${vulnerability.scanId}
  // Found Date: ${vulnerability.}
  return [
    `Endpoint: ${vulnerability.endpoint}`,
    `Category: ${vulnerability.category}`,
    '',
    `Parameters: ${vulnerability.parameter}`,
    '',
    'Description:',
    vulnerability.description,
    '',
    'Instructions on how to fix:',
    vulnerability.remediation,
    '',
    ...(vulnerability.curl_cmd
      ? ['How to reproduce:', vulnerability.curl_cmd]
      : []),
    '',
    'Link to the related vulnerability item in Pynt:',
    vulnerability.application_id
      ? `https://app.pynt.io/dashboard/application/${vulnerability.application_id}?tab=vulnerabilities&vulnerabilityId=${vulnerability.id}`
      : `https://app.pynt.io/dashboard/vulnerabilities?vulnerabilityId=${vulnerability.id}`,
  ].join('\n');
};

interface JiraTicketMetadata {
  title?: string;
  description?: string;
  projects: any[];
}

interface Props {
  vulnerabilityId: string;
  vulnerability: Vulnerability;
  disabled?: boolean;
  data: Record<string, any>;
  onChange: (data: Record<string, any>) => void;
}

export const JiraTicketForm = ({
  vulnerabilityId,
  vulnerability,
  disabled,
  data,
  onChange,
}: Props) => {
  const { integrations } = useContext(
    IntegrationsContext,
  ) as IntegrationsContextProps;
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState<JiraTicketMetadata>();

  const issueTypes = useMemo(
    () =>
      config?.projects?.find((c: any) => c.id === data.projectId)?.issueTypes,
    [config, data.projectId],
  );

  const jiraIntegration = useMemo(
    () => integrations?.find((i) => i.integration_type === 'jira'),
    [integrations],
  );

  useEffect(() => {
    if (!jiraIntegration) return;

    setLoading(true);
    getTicketMetadata(vulnerabilityId, jiraIntegration.integration_id)
      .then((res) => {
        setConfig(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vulnerabilityId, jiraIntegration]);

  useEffect(() => {
    if (config && !data.projectId && config.projects[0].id) {
      onChange({ ...data, projectId: config.projects[0].id });
    }
    if (
      issueTypes &&
      data.projectId &&
      issueTypes[0].id &&
      (!data.issueType || !issueTypes.find((t: any) => t.id === data.issueType))
    ) {
      onChange({
        ...data,
        issueType:
          issueTypes.find((t: any) => t.name?.toLowerCase() === 'bug')?.id ??
          issueTypes[0].id,
      });
    }
    if (config && (!data.name || !data.description)) {
      onChange({
        ...data,
        name:
          data.title ||
          config.title ||
          createVulnerabilityTicketTitle(vulnerability),
        description:
          data.description ||
          config.description ||
          createVulnerabilityTicketDescription(vulnerability),
      });
    }
  }, [data, config, onChange, issueTypes, vulnerability]);

  return (
    <Stack gap={2} sx={{ minHeight: 460 }}>
      <Typography variant="h5" fontWeight={500}>
        Open Jira ticket
      </Typography>
      {loading ? (
        <Box sx={LOADER_WRAPPER}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box>
            <TextFieldTitle title="Project" />
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <Select
                  variant="filled"
                  size="small"
                  disabled={loading || disabled}
                  value={data.projectId || ''}
                  onChange={(v) => {
                    onChange({ ...data, projectId: v.target.value });
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: 4,
                        borderRadius: 6,
                        textAlign: 'start',
                        backgroundColor: 'white',
                        border: 'solid 1px #D3D3D3',
                        boxShadow: 'none',
                      },
                    },
                  }}
                >
                  {config?.projects?.map((type: any) => (
                    <MenuItem key={type.id} value={type.id}>
                      <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <img
                          src={type.avatarUrls?.['16x16']}
                          width={16}
                          height={16}
                        />
                        <Typography>{type.name}</Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <TextFieldTitle title="Issue type" />
            <Box sx={{ minWidth: 220 }}>
              <FormControl fullWidth>
                <Select
                  variant="filled"
                  size="small"
                  disabled={loading || disabled}
                  value={data.issueType || ''}
                  onChange={(v) => {
                    onChange({ ...data, issueType: v.target.value });
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: 4,
                        borderRadius: 6,
                        textAlign: 'start',
                        backgroundColor: 'white',
                        border: 'solid 1px #D3D3D3',
                        boxShadow: 'none',
                      },
                    },
                  }}
                >
                  {issueTypes?.map((type: any) => (
                    <MenuItem key={type.id} value={type.id}>
                      <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <img src={type.iconUrl} width={16} height={16} />
                        <Typography>{type.name}</Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <TextFieldTitle title="Ticket name" />
            <TextField
              fullWidth
              variant="filled"
              size="small"
              disabled={loading || disabled}
              value={data.name ?? ''}
              onChange={(v) => {
                onChange({ ...data, name: v.target.value });
              }}
            />
          </Box>
          <Box>
            <TextFieldTitle title="Description" />
            <TextField
              fullWidth
              variant="filled"
              size="small"
              rows={5}
              multiline
              disabled={loading || disabled}
              value={data.description ?? ''}
              onChange={(v) => {
                onChange({ ...data, description: v.target.value });
              }}
            />
          </Box>
        </>
      )}
    </Stack>
  );
};
