import { useContext } from 'react';
import { UserContext, UserContextProps } from '../../contexts/User';

interface Props {
  applicationId: string;
}

export default function ApplicationGridCell({ applicationId }: Props) {
  const { getAppByIdSync } = useContext(UserContext) as UserContextProps;

  return <>{getAppByIdSync(applicationId)?.name}</>;
}
