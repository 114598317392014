import { get, httpDelete, post, put } from './BaseService';

export type PyntFilter = {
  where?: {
    [key: string]: string | number | { [key: string]: string | number };
  };
  sort?: { [key: string]: number };
  limit?: number;
  offset?: number;
};

export async function getApplications(filter: PyntFilter = {}) {
  const res = await get('/application/metadata', {
    params: { filter: JSON.stringify(filter) },
  });

  return res.data;
}

export async function getApplicationsCount(where: PyntFilter['where'] = {}) {
  const res = await get('/application/metadata/count', {
    params: { where: JSON.stringify(where) },
  });

  return res.data;
}

export async function getApplicationById(appId: string) {
  const res = await get('/applicationsMetadata?application_id=' + appId);
  const app = Array.isArray(res.data) ? res.data[0] : res.data;

  if (!app) throw new Error('App does not exist');

  return app;
}

export async function createApplication(name: string) {
  const res = await put('/application', { name });

  return res.data;
}

export async function updateApplication(applicationId: string, name: string) {
  const res = await post('/application', {
    application_id: applicationId,
    name,
  });

  return res.data;
}

export async function updateUserAssignee(
  applicationId: string,
  applicationName: string,
  assigneeId: string,
) {
  const res = await post('/application', {
    application_id: applicationId,
    name: applicationName,
    assignee_id: assigneeId,
  });

  return res.data;
}

export async function deleteApplication(applicationId: string) {
  const res = await httpDelete(`/application?app_id=${applicationId}`, {});

  return res.data;
}

export async function getApplicationSources(applicationId: string) {
  const res = await get(`/applicationSources?app_id=${applicationId}`, {});

  return res.data;
}

export async function createPentestReport(
  applicationId: string,
  scanId: string,
) {
  const res = await post(
    `/pentestReport?app_id=${applicationId}&scan_id=${scanId}`,
    {},
  );

  return res.data;
}

export async function getPentestReportStatus(reqId: string) {
  const res = await get(
    `/pentestReport?pentest_id=${reqId}&time=${Date.now()}`,
    {},
  );

  return res.data;
}
