import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function usePersistedState(
  key: string,
  initialValue?: string,
  storage?: Storage,
): [string | undefined, SetValue<string | undefined>] {
  const _localStorage = storage || localStorage;

  const [state, setState] = useState<string | undefined>(() => {
    const storedValue = _localStorage.getItem(key);
    return storedValue ? storedValue : initialValue;
  });

  useEffect(() => {
    if (state == null) {
      _localStorage.removeItem(key);
    } else {
      _localStorage.setItem(key, state);
    }
  }, [key, state]);

  return [state, setState];
}

export function usePersistedJsonState<T>(
  key: string,
  initialValue?: T,
  storage?: Storage,
): [T, SetValue<T>] {
  const _localStorage = storage || localStorage;

  const [state, setState] = useState<T>(() => {
    const storedValue = _localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    if (state == null) {
      _localStorage.removeItem(key);
    } else {
      _localStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, setState];
}
