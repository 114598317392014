import { SvgIconProps } from './SvgIconProps';

export const LockIcon = ({ color = 'black', size = 24 }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9987 7.08333H14.1654V5.41667C14.1654 3.11667 12.2987 1.25 9.9987 1.25C7.6987 1.25 5.83203 3.11667 5.83203 5.41667V7.08333H4.9987C4.08203 7.08333 3.33203 7.83333 3.33203 8.75V17.0833C3.33203 18 4.08203 18.75 4.9987 18.75H14.9987C15.9154 18.75 16.6654 18 16.6654 17.0833V8.75C16.6654 7.83333 15.9154 7.08333 14.9987 7.08333ZM7.4987 5.41667C7.4987 4.03333 8.61536 2.91667 9.9987 2.91667C11.382 2.91667 12.4987 4.03333 12.4987 5.41667V7.08333H7.4987V5.41667ZM14.9987 17.0833H4.9987V8.75H14.9987V17.0833ZM9.9987 14.5833C10.9154 14.5833 11.6654 13.8333 11.6654 12.9167C11.6654 12 10.9154 11.25 9.9987 11.25C9.08203 11.25 8.33203 12 8.33203 12.9167C8.33203 13.8333 9.08203 14.5833 9.9987 14.5833Z"
        fill={color}
      />
    </svg>
  );
};
