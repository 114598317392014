import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext, UserContextProps } from '../../contexts/User';
import { track } from '../../utils/analytics';
import APICatalogGrid from '../APICatalog/APICatalogGrid';
import ViewAllButton from '../Common/ViewAllButton';
import CustomCard from '../CustomCard/CustomCard';

const APIRiskScore = () => {
  const navigate = useNavigate();
  const { setSelectedApplicationId } = useContext(
    UserContext,
  ) as UserContextProps;

  return (
    <CustomCard
      title="API Risk Score"
      action={
        <ViewAllButton
          onClick={() => {
            track('web_app_overview_api_risk_score_view_all_button_click');

            setSelectedApplicationId('*');
            navigate('/dashboard/api-catalog');
          }}
        />
      }
      sx={{ height: 300 }}
    >
      <APICatalogGrid
        id={'APIRiskScoreGrid'}
        autoHeight={false}
        autoPageSize={true}
        hideFooter
        sorting={[{ field: 'properties.scan_risk', sort: 'desc' }]}
        columnVisibilityModel={{
          'properties.authenticated': false,
          'properties.endpoint_type': false,
          'source': false,
          'properties.endpoint_status': false,
          'last_seen': false,
          'actions': false,
        }}
        pagination={false}
        limit={3}
      />
    </CustomCard>
  );
};

export default APIRiskScore;
